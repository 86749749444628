import { minutesToT24, t24ToMinutes } from '../services/date';
import { Form } from '@crochik/pi-react/ui/Form';
import * as API from '../services/API';
import { Session } from '../Session';
import { DataView } from '@crochik/pi-react/ui/DataView';
import Dialog from '@crochik/pi-react/services/Dialog';

interface IWorkingHours {
    start: string;
    end: string;
}

function workingHours(str: string, onClose: () => any) {
    var form = initWorkingHours(str);
    form.bindAction('save', () => {
        if (saveWorkingHours(form.dataContext)) {
            onClose();
        }
    });

    return form;
}

export function initWorkingHours(settingValue: string | undefined): Form {
    var wh: IWorkingHours = {
        start: '08:00',
        end: '20:00'
    };

    if (settingValue) {
        var curr = JSON.parse(settingValue);
        if ('startMinutes' in curr) {
            wh.start = minutesToT24(curr['startMinutes']);
        }
        if ('endMinutes' in curr) {
            wh.end = minutesToT24(curr['endMinutes']);
        }
    }

    var form = Form.get('WorkingHours');
    form.assignValues(wh);

    return form;
}

export async function saveWorkingHours(context: string): Promise<boolean> {
    var wh = Form.get('WorkingHours').values as IWorkingHours;
    var start = t24ToMinutes(wh.start);
    var end = t24ToMinutes(wh.end);
    if (start <= 0 || end <= 0 || end <= start) {
        Dialog.critical({
            title: 'Invalid Range',
            message: `From '${wh.start}' to '${wh.end}' is not valid`
        });
        return false;
    }

    try {
        Session.get().workingHours = await API.setWorkingHours(start, end);
        DataView.get('Settings').reload();
        Dialog.inform({ title: 'Success', message: 'Working Hours Saved successfully.' });
        
    } catch (ex) {
        console.error(ex);
        Dialog.critical({
            title: 'Error',
            message: 'Failed to update Working Hours'
        });
    }

    return true;
}

export function edit(context: string, args: any[]) {
    let { id, row, onClose } = args[0];

    console.log(`Edit ${context}: ${id} - ${row['value']}`);
    switch (id) {
        case 'WorkingHours':
            return workingHours(row.value, onClose);

        case 'TimeZone':
        default:
            break;
    }

    return undefined;
}