import * as React from 'react';

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import * as api from '@crochik/schedulerapi.ts';
import * as API from '../services/API';

import { CustomPage } from '@crochik/pi-react/application/Page';
import { Calendar, CalendarProps, DaySlots, Slot } from '@crochik/pi-react/ui/material/Calendar';
import DataService from '@crochik/pi-react/services/DataService';
import { Form } from '@crochik/pi-react/ui/Form';
import { FormDialog } from '@crochik/pi-react/ui/material/FormDialog';

import { minutesToString } from '../services/date';
import { IForm } from '@crochik/pi-react/context';
import { Session } from '../Session';
import DialogService from '@crochik/pi-react/services/Dialog';

interface Props {

}
interface State {
}

interface BoundState {
    selectedSlots: { [key: string]: boolean };
    days: DaySlots[];
    selectedSlot?: Slot;
    form?: Form;
    appointmentTypes?: api.AppointmentType[];
}

interface EmptySlot {
    dayId: number;
    start: number;
}

@observer
class Availabiliy extends React.Component<Props, State> {
    calendarProps: CalendarProps;
    boundState: BoundState;

    constructor(props: Props) {
        super(props);

        this.state = {
        };

        var wh = Session.get().workingHours;

        this.calendarProps = {
            // calendar1 only
            start: wh && wh.startMinutes ? wh.startMinutes : 8 * 60,
            end: wh && wh.endMinutes ? wh.endMinutes : 19 * 60,
            interval: 30,
            offY: 30,
            height: 30,
            onClick: this.onClick,

            // common
            showSlotLabels: true,
            selectedSlots: {},
            // onClick?: (slot: Slot) => any;
            days: [],
            collapseDays: false
        };

        this.boundState = observable({
            selectedSlots: {},
            days: [],
            selectedSlot: undefined,
            form: undefined,
        });
    }

    componentDidMount() {
        console.debug('ready');
        this.loadAsync();
    }

    async loadAsync() {
        DataService().select('AppointmentTypes')
            .then(result => this.boundState.appointmentTypes = result);

        var records = await DataService().select('Availability');
        this.init(records as api.UserAvailability[]);
    }

    @action
    onClick = async (slot: Slot | EmptySlot) => {
        if (!this.boundState.appointmentTypes) throw new Error('Invalid State');

        if ('id' in slot) {
            this.boundState.selectedSlots[slot.id] = true;
        } else {
            this.boundState.selectedSlots = {};
        }

        var form = Form.get('Slot');
        var clone = JSON.parse(JSON.stringify(form.form)) as IForm;
        clone.name = 'AvailabilitySlot';

        // add appointment types
        var duration = 0;
        for (var type of this.boundState.appointmentTypes) {
            clone.fields.push({
                name: type.id as string,
                label: type.name as string,
                defaultValue: false,
                type: 'boolean'
            });

            if (!type.settings || !type.settings.duration) continue;
            if (duration === 0 || type.settings.duration < duration) duration = type.settings.duration;
        }

        form = new Form(clone.name, clone);
        form.bindAction('add', this.onAdd);
        form.bindAction('update', this.onEditDone);
        form.bindAction('delete', this.onDelete);
        form.bindAction('cancel', this.onCancelEdit);

        if ('id' in slot) {
            // edit
            var record = slot.tag['record'] as api.UserAvailability;
            this.boundState.selectedSlot = slot;
            form.title = slot.date;
            form.assignValues({
                dayId: record.dayId,
                start: this.minutesToString(slot.start),
                end: this.minutesToString(slot.start + slot.duration),
                isEditing: true,
            });
            if (record.appointmentTypeIds) for (var selType of record.appointmentTypeIds) {
                form.assignValues({ [selType]: true });
            }

        } else {
            // add 
            form.title = this.dayOfWeek(slot.dayId);
            form.assignValues({
                start: this.minutesToString(slot.start),
                end: this.minutesToString(slot.start + duration),
                isEditing: false,
                dayId: slot.dayId,
            });

            for (var defType of this.boundState.appointmentTypes) {
                if (!defType.id) continue;
                form.assignValues({ [defType.id]: true });
            }
        }

        this.boundState.form = form;
    }

    @action
    onAdd = async () => {
        if (!this.boundState.form || !this.boundState.appointmentTypes) throw new Error('Invalid state');

        var form = this.boundState.form;
        var values = form.values as any;

        let { start, end, dayId } = values;
        let mStart = this.stringToMinutes(start);
        let mEnd = this.stringToMinutes(end);
        if (mEnd <= mStart) {
            DialogService.critical({
                title: 'Invalid Slot',
                message: 'The End time has to come after the Start time.'
            });
            return;
        }

        const day = this.boundState.days.find((d) => {
            return d.id === dayId;
        });

        if (!day) throw new Error('failed to find weekday');

        var appointmentTypeIds: string[] = [];
        for (var type of this.boundState.appointmentTypes) {
            if (!type.id) continue;
            if (values[type.id]) appointmentTypeIds.push(type.id);
        }

        if (appointmentTypeIds.length < 1) {
            DialogService.critical({
                title: 'Missing Appointment Type',
                message: 'The availability has to be assigned to at least one Appointment Type.'
            });
            return;
        }

        var slot: Slot;
        try {
            var availability = await API.updateAvailability(dayId, mStart, mEnd - mStart, appointmentTypeIds);
            slot = this.createSlot(availability);

        } catch (e) {
            if ('status' in e) {
                if (e.status === 400) {
                    alert('Slots should not overlap');
                    return;
                }
            }

            alert('Failed to set availability');
            return;
        }

        day.slots.push(slot);
        day.slots = day.slots.sort((a, b) => a.start - b.start);
        this.onCancelEdit();
    }

    @action
    onDelete = async () => {
        if (!this.boundState.selectedSlot) return;
        const slot = this.boundState.selectedSlot;

        try {
            await API.deleteAvailability(slot.id);

        } catch (e) {
            alert('Failed to delete');
            return;
        }

        const day = this.boundState.days.find((d) => {
            return d.title === slot.date;
        });
        if (!day) throw new Error('failed to find weekday');
        var index = day.slots.findIndex((s) => s.id === slot.id);
        if (index < 0) throw new Error('failed to find the slot');

        day.slots.splice(index, 1);
        this.onCancelEdit();
    }

    @action
    onCancelEdit = () => {
        this.boundState.selectedSlot = undefined;
        this.boundState.form = undefined;
    }

    @action
    onEditDone = async () => {
        if (!this.boundState.form || !this.boundState.appointmentTypes) throw new Error('Invalid State');

        Object.keys(this.boundState.selectedSlots).forEach((key) => delete this.boundState.selectedSlots[key]);

        if (!this.boundState.selectedSlot) return;
        const slot = this.boundState.selectedSlot;

        var form = this.boundState.form;
        var values = form.values as any;
        let { start, end, dayId } = values;
        var mStart = this.stringToMinutes(start);
        var mEnd = this.stringToMinutes(end);
        if (mStart < 0 || mEnd < 0 || mEnd <= mStart) {
            DialogService.critical({
                title: 'Invalid Slot',
                message: 'The End time has to come after the Start time.'
            });
            return;
        }

        var appointmentTypeIds: string[] = [];
        for (var type of this.boundState.appointmentTypes) {
            if (!type.id) continue;
            if (values[type.id]) appointmentTypeIds.push(type.id);
        }

        if (appointmentTypeIds.length < 1) {
            DialogService.critical({
                title: 'Missing Appointment Type',
                message: 'The availability has to be assigned to at least one Appointment Type.'
            });
            return;
        }

        var availability: api.UserAvailability;
        try {
            availability = await API.updateAvailability(dayId, mStart, mEnd - mStart, appointmentTypeIds, slot.id);

        } catch {
            DialogService.critical({
                title: 'Internal Error',
                message: 'Failed to update availability.'
            });
            return;
        }

        slot.id = availability.id as string;
        slot.start = availability.startMinutes as number;
        slot.duration = availability.durationMinutes as number;
        slot.title = minutesToString(slot.start) + ' - ' + minutesToString(slot.start + slot.duration);
        this.onCancelEdit();
    }

    @action
    init(data: api.UserAvailability[]) {
        this.boundState.days = this.convertToDays(data);
    }

    minutesToString(minutes: number): string {
        var min = minutes % 60;
        var hour = (minutes - min) / 60;
        return `${hour < 10 ? '0' + hour : hour}:${min < 10 ? '0' + min : min}`;
    }

    stringToMinutes(str: string): number {
        if (!str || str.length < 1) {
            return -1;
        }

        var hours = parseInt(str.substr(0, 2), 10);
        var min = parseInt(str.substr(3, 2), 10);
        return hours * 60 + min;
    }

    dayOfWeek(index: number): string {
        var dayOfWeek: string[] = [
            'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
        ];
        return dayOfWeek[index];
    }

    // createSlot(dayOfWeek: string, id: string, start: number, duration: number) {
    createSlot(record: api.UserAvailability) {
        let dayId = record.dayId as number;
        let dayOfWeek = this.dayOfWeek(dayId);
        let duration = record.durationMinutes as number;
        let start = record.startMinutes as number;
        let id = record.id as string;

        var sl: Slot = {
            date: dayOfWeek,
            start,
            duration,
            id,
            title: minutesToString(start) + ' - ' + minutesToString(start + duration),
            className: 'pi-calendar_slot_green pi-calendar_open pi-calendar_hover',
            tag: {
                type: 'slot',
                record
            }
        };

        return sl;
    }

    convertToDays(records: api.UserAvailability[]): DaySlots[] {
        const days: DaySlots[] = [];

        var dict: { [id: number]: DaySlots } = {};
        for (var c = 0; c < 7; c++) {
            var daySlots = {
                title: this.dayOfWeek(c),
                id: c.toString(),
                slots: []
            };
            days.push(daySlots);
            dict[c] = daySlots;
        }

        records.forEach(record => {
            let dayId = record.dayId as number;
            var daySlots: DaySlots = dict[dayId];

            // const duration = record.durationMinutes as number;
            // const start = record.startMinutes as number;
            // const id = record.id as string;
            // var sl = this.createSlot(this.dayOfWeek(dayId), id, start, duration);

            var sl = this.createSlot(record);
            daySlots.slots.push(sl);
        });

        return days;
    }

    render() {
        if (this.boundState.days.length < 1) return <div>Loading</div>;

        return (
            <>
                <Calendar {...this.calendarProps} selectedSlots={this.boundState.selectedSlots} days={this.boundState.days} />
                {
                    this.boundState.form ? (
                        <FormDialog
                            open={true}
                            form={this.boundState.form}
                            onClose={this.onCancelEdit}
                            contentClassName="FormDialog_content"
                        />
                    ) : undefined
                }

            </>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <Availabiliy />);