import * as React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import * as moment from 'moment';

import * as api from '@crochik/schedulerapi.ts';

import { CustomPage } from '@crochik/pi-react/application/Page';
import { Calendar, CalendarProps, DaySlots, Slot } from '@crochik/pi-react/ui/material/Calendar';
import DataService from '@crochik/pi-react/services/DataService';

import './style.css';
import { Session } from '../../Session';

interface State {
    calendarProps?: CalendarProps;
}

@observer
export class MyCalendar extends React.Component {
    boundState: State;
    start: number;
    end: number;

    constructor(props: any) {
        super(props);

        var wh = Session.get().workingHours;

        this.start = wh && wh.startMinutes ? wh.startMinutes : 8 * 60;
        this.end = wh && wh.endMinutes ? wh.endMinutes : 19 * 60;
    
        this.boundState = observable({
            calendarProps: undefined
        });
    }

    componentDidMount() {
        this.loadAsync();
    }

    @action
    async loadAsync() {
        var records = await DataService().select('Event');
        this.init(records);
    }

    convertToDays(records: api.CalendarEvent[]): DaySlots[] {
        const days: DaySlots[] = [];
        const dict: { [id: string]: DaySlots } = {};

        var min = moment().startOf('day');
        var max = moment().startOf('day').add('days', 14);
        for (var day = min.clone(); day.isBefore(max); day.add(1, 'day')) {
            const id = day.format('YYYY-MM-DD');
            const slot = {
                title: day.format('ddd, MM-DD'),
                id,
                slots: []
            };

            dict[id] = slot;
            days.push(slot);
        }

        records.forEach((record) => {
            var start, end, startDate, endDate;
            if (record.isAllDay) {
                // naked date (in localtime but marked as utc): convert from localtime to utc
                start = moment(record.start).utc();
                end = moment(record.end).utc();

            } else {
                start = moment(record.start);
                end = moment(record.end);
            }

            startDate = start.clone().startOf('day');
            endDate = end.clone().startOf('day');

            for (var date = startDate.clone(); date.diff(end) < 0; date = date.add(1, 'day')) {
                const id = date.format('YYYY-MM-DD');
                let slotStart = start.diff(startDate, 'minutes');
                let slotDuration = end.diff(start, 'minutes');

                if (record.isAllDay) {
                    // TODO: show some other way?
                    console.log(`All Day Event: ${date}`);
                    slotStart = this.start;
                    slotDuration = this.end - this.start;

                } else {
                    if (!date.isSame(startDate, 'day')) {
                        slotStart = this.start;
                    }
                    if (!date.isSame(endDate, 'day')) {
                        slotDuration = this.end - this.start;
                    } else {
                        slotDuration = end.diff(date, 'minutes') - slotStart;
                    }
                }

                let day = dict[id];
                if (!day) {
                    console.log(`date out of range: ${id}`);
                    return;
                }

                var className;
                if ( record.isAllDay ) {
                    className = 'CalendarPage_allDay'; 
                } else if (record.showAs !== 2) {
                    className = 'pi-calendar_slot_gray pi-calendar_open';
                }

                var slot: Slot = {
                    date: id,
                    start: slotStart,
                    duration: slotDuration,
                    id: record.id ? record.id.toString() : 'error_no_id',
                    title: record.subject,
                    tag: record,
                    className
                };

                day.slots.push(slot);
            }
        });

        return days;
    }

    onClick = (evt: Slot) => {
        if (evt.tag) {
            if ('webLink' in evt.tag) {
                // event
                window.open(evt.tag['webLink'], '_blank');
                return;
            }
        }

        console.log(evt);
    }

    init(records: api.CalendarEvent[]) {
        var days = this.convertToDays(records);

        var props: CalendarProps = {
            // calendar1 only
            start: this.start,
            end: this.end,
            interval: 30,
            offY: 30,
            height: 30,

            // common
            showSlotLabels: true,
            selectedSlots: {},
            onClick: this.onClick,
            days,
            collapseDays: true
        };

        this.boundState.calendarProps = props;
    }

    render() {
        if (!this.boundState.calendarProps) return <div>Loading</div>;
        return <Calendar {...this.boundState.calendarProps} />;
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <MyCalendar />);