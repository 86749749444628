import { Default } from '@crochik/pi-react/context';
import Dialog from '@crochik/pi-react/services/Dialog';

import * as API from '../services/API';

export function saveSendGrid(context: string) {
    var record = Default.state.get(context) as API.ISendGridAppointmentIntegration;
    console.log(record);
    
    API.enableSendGridOnAppointmentType(record)
        .then( ok => {
            Dialog.inform({title: 'Success', message: 'SendGrid integration successfully configured.'});
        })
        .catch( ex => {
            Dialog.critical({title: 'Enabling Integration', message: 'Error trying to configure SendGrid integration for Appointment Type.'});
        });
}

export function saveZoom(context: string) {
    var record = Default.state.get(context); 
    console.log(record);
    
    API.enableZoomOnAppointmentType(record)
        .then( ok => {
            Dialog.inform({title: 'Success', message: 'Zoom integration successfully configured.'});
        })
        .catch( ex => {
            Dialog.critical({title: 'Enabling Integration', message: 'Error trying to configure Zoom integration for Appointment Type.'});
        });
}

export function saveGoToMeeting(context: string) {
    var record = Default.state.get(context); 
    console.log(record);
    
    API.enableGoToMeetingOnAppointmentType(record)
        .then( ok => {
            Dialog.inform({title: 'Success', message: 'GoToMeeting integration successfully configured.'});
        })
        .catch( ex => {
            Dialog.critical({title: 'Enabling Integration', message: 'Error trying to configure GoToMeeting integration for Appointment Type.'});
        });
}

function disableIntegration(context: string, serviceName: string ) {
    var record = Default.state.get(context); 
    API.disableAppointmentTypeIntegration(record.id, serviceName)
    .then( ok => {
        Dialog.inform({title: 'Success', message: `${serviceName} integration disabled.`});
    })
    .catch( ex => {
        Dialog.critical({title: 'Disable Integration', message: `Error trying to disable ${serviceName} for Appointment Type.`});
    });
}

export function disableZoom(context: string) {
    disableIntegration(context, 'Zoom');
}
export function disableSendGrid(context: string) {
    disableIntegration(context, 'SendGrid');
}
export function disableGoToMeeting(context: string) {
    disableIntegration(context, 'GoToMeeting');
}