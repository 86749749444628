import * as React from 'react';
import * as ReactDOM from 'react-dom';
// import DevTools from 'mobx-react-devtools';

import App from '@crochik/pi-react/ui/material/Chrome';

import Theme from './Theme';
import init from './app';
import './index.css';

// import { ErrorBoundary } from './bugsnag';

// export default class Main extends React.Component {
//   render() {
//     return (
//       <>
//         <App />
//       </>
//     );
//   }
// }

class App2 extends React.Component {
  render() {
    return (
      <div className="body">
        <App />
      </div>
    );
  }
}

init();

const AppWithTheme = Theme(App2);

ReactDOM.render(
  // <ErrorBoundary>
  <AppWithTheme />
  // </ErrorBoundary>
  ,
  document.getElementById('root') as HTMLElement
);