import Dialog from '@crochik/pi-react/services/Dialog';
// import App from '@crochik/pi-react/application/App';

import * as API from '../services/API';
import { Default } from '@crochik/pi-react/context';
import { ISession } from '../Session';
import App from '@crochik/pi-react/application/App';

function home() {
    var session: ISession = Default.state.get('Session');
    session.isSyncEnabled = true;

    App().selectPage('FullCalendar');
    App().setMenu('app');
}

export default async function action() {
    console.log('enable sync');

    var canEnable = await API.hasMicrosoftAccount();

    if (!canEnable) {
        Dialog.inform({
            message: 'Can\'t enable sync for this account',
            title: 'Failed to enableCalendar Sync'
        });
        return;
    }

    var success = await API.enbleCalendarAsync();
    if (success) {
        // TODO: figure out some cleaner way
        Dialog.inform({
            message: 'Initial Calendar may take a few minutes',
            title: 'Calendar Sync Enabled',
            onPositive: home
        });

    } else {
        Dialog.inform({
            message: 'Error enabling sync for this account',
            title: 'Failed to enableCalendar Sync'
        });
    }
}
