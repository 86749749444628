import * as React from 'react';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, IconButton } from '@material-ui/core';
import { Default, Action } from '@crochik/pi-react';

import { VARIANT, COLOR } from '@crochik/pi-react/ui/material/FormActions';
import ActionButton from '@crochik/pi-react/ui/material/ActionButton';
import CloseIcon from '@material-ui/icons/CloseRounded';

import './PopupDialog.css';
import { observer } from 'mobx-react';

interface IProps {
    open: boolean;
    title: string;
    fullScreen?: boolean | 'auto';
    fullWidth?: boolean;
    actions?: Action[];
    variant?: VARIANT;
    color?: COLOR;
    maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
    onClose(): void;
}

interface IState {
}

@observer
export class PopupDialog extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
        };
    }

    render() {
        const {
            title, children, open, fullWidth, onClose, actions, color, variant,
            maxWidth
        } = this.props;

        var { fullScreen } = this.props;

        if (fullScreen === 'auto') {
            // TODO: use fullWidth setting to determine threshold
            const width = Default.state.get('innerWidth', 'app');
            const height = Default.state.get('innerHeight', 'app');
            fullScreen = width < 600 || height < 600;
        }

        return (
            <Dialog
                className="PopupDialog"
                title={title}
                open={open}
                onClose={onClose}
                TransitionComponent={(props) => (<Slide direction="up" {...props} />)}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
            >
                <DialogTitle style={{ paddingTop: 8, paddingBottom: 8 }}>
                    <div className="PopupDialog_title">
                        <span>{title}</span>
                        <IconButton onClick={this.props.onClose}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="PopupDialog_content">
                    {children}
                </DialogContent>

                {actions &&
                    <DialogActions style={{ padding: 24 }}>
                        {actions.map(action =>
                            action.action &&
                            <ActionButton
                                color={color}
                                variant={variant}
                                key={action.name}
                                title={action.name}
                                action={action.action}
                            />
                        )}
                    </DialogActions>
                }
            </Dialog>
        );
    }
}