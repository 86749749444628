import DataService from '@crochik/pi-react/services/DataService';
import { IForm } from '@crochik/pi-react/context';
import { Form } from '@crochik/pi-react/ui/Form';
import DialogService from '@crochik/pi-react/services/Dialog';
import { Leads } from '@crochik/schedulerapi.ts';
import * as API from '../services/API';

export async function loadLead(id: string): Promise<Leads|undefined> {
    try {
        return await DataService().one('Lead', { args: [null, { id }] });

    } catch (ex) {
        // TODO: move this to DataService? 
        var message;

        if ('status' in ex) {
            var status = ex.status;
            switch (status) {
                case 404:
                    message = {
                        title: 'Not Found',
                        message: 'Lead not found'
                    };
                    break;

                case 403:
                    message = {
                        title: 'Access Denied',
                        message: 'You don\' have permissions to access this lead'
                    };
                    break;

                default:
                    message = {
                        title: 'Unexpected Error',
                        message: `Server returned code ${status}`
                    };
                    break;
            }
        } else {
            message = { title: 'Unexpected Error', message: ex.toString() };
        }

        DialogService.critical(message);
        return undefined;
    }
}

export function createLeadForm(lead: Leads): Form|undefined {
    if (!lead || !lead.fields || !lead.list || lead.list.length !== 1) {
        console.error('Invalid Lead');
        console.log(lead);
        return undefined;
    }

    let fields = lead.fields.map(API.mapField);
    fields.push({
        name: 'id',
        label: 'ID',
        type: 'hidden'
    });
    fields.push({
        name: 'leadType',
        label: 'Lead Type',
        type: 'string'
    });

    let formName = `Lead`;
    var data: IForm = {
        name: formName,
        fields,
    };

    let values = {
        ...lead.list[0].values,
        id: lead.list[0].id,
        leadType: lead['leadType'],
        leadTypeId: lead.leadTypeId,
        createdOn: lead.list[0].createdOn
    };

    data.actions = [
        {
            name: 'History'
        }
    ];

    var form = new Form(formName, data);
    form.assignValues(values);
    form.title = values['name'];

    return form;
}