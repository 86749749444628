import * as React from 'react';
import { Card, CardContent, Typography, Dialog, Slide, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { AppConfig, Page, Form, FormField } from '@crochik/schedulerapi.ts';
import DataService from '@crochik/pi-react/services/DataService';
import { Fields } from './Fields';

import './AppConfig.css';
import { observable, isObservableArray } from 'mobx';
import { Default } from '@crochik/pi-react/context';
import { observer } from 'mobx-react';
import ActionButton from '@crochik/pi-react/ui/material/ActionButton';
import { Form as UIForm } from '@crochik/pi-react/ui/Form';
import { FormActions } from '@crochik/pi-react/ui/material/FormActions';
import * as API from '../../services/API';

interface Props {
    appointmentTypeId?: string;
    context: string;
}

interface BoundState {
    config?: AppConfig;
    fields?: FormField[];
    form?: UIForm;
    json?: string;
}

@observer
export class AppConfiguration extends React.Component<Props> {
    boundState: BoundState;

    constructor(props: Props) {
        super(props);

        var state: BoundState = {
            config: undefined,
            fields: undefined,
            form: undefined
        };

        this.boundState = observable(state);
        Default.state.set('AppConfiguration', this.boundState, this.props.context);
    }

    async componentWillMount() {
        var config = await DataService().one('AppConfig', {
            args: ['AppointmentType', this.props.appointmentTypeId]
        });

        this.boundState.config = config;
    }

    renderConfig() {
        if (!this.boundState.config) return undefined;
        var app = this.boundState.config;

        return (
            <div className="AppConfig">
                {this.renderPages(app.pages)}
                {this.renderForms(app.forms)}
            </div>
        );
    }
    renderPages(pages: Page[] | undefined): React.ReactNode {
        if (!pages) return undefined;

        return (
            <div>
                Pages<br />
                {
                    pages.map((page) => (
                        <p key={page.name}>{page.name}</p>
                    ))
                }
            </div>
        );
    }

    renderForms(forms: Form[] | undefined): React.ReactNode {
        if (!forms) return undefined;

        var list = forms.sort((a, b) => (a.name as string).localeCompare(b.name as string));

        return (
            <div>
                <Typography color="primary" variant="h6">Forms</Typography>
                {list.map((form) => this.renderForm(form))}
            </div>
        );
    }

    renderForm(form: Form): any {
        var fields: FormField[] = [];
        if (form.fields) for (var field of form.fields) {
            if (Array.isArray(field) || isObservableArray(field)) {
                for (var subfield of field) {
                    fields.push(subfield);
                }
            } else {
                fields.push(field);
            }
        }

        return (
            <div key={form.name} className="AppConfig_form">
                <Typography color="primary" variant="subtitle1">{form.name}</Typography>
                <Fields editable={false} fields={fields} hideSource={true} prefix={form.name + '.'} />
            </div>
        );
    }

    json = () => {
        this.boundState.form = UIForm.get('RawEditor');
    }

    onClose = async (action) => {
        if (action && action['name'] === 'save') {
            console.log('save');
            if ( !this.boundState.json || !this.props.appointmentTypeId) throw new Error('Invalid value');
            var appConfig = JSON.parse(this.boundState.json);

            await API.setAppConfig(this.props.appointmentTypeId, appConfig);
        }

        this.boundState.form = undefined;
    }

    changeJson = (e) => {
        this.boundState.json = e.target.value;
    }

    renderDialog() {
        if (!this.boundState.form) return;
        var form = this.boundState.form;
        var json = JSON.stringify(this.boundState.config, null, 2);

        return (
            <Dialog
                className="FormDialog"
                title={form.title}
                open={form != null}
                onClose={this.onClose}
                TransitionComponent={(props) => (<Slide direction="up" {...props} />)}
                fullWidth={true}
                maxWidth="sm"
                fullScreen={true}
            >
                <DialogTitle>{form.title}</DialogTitle>
                <DialogContent className="DialogContent">
                    <textarea className="AppConfig_editor" onChange={this.changeJson}>{json}</textarea>
                </DialogContent>

                <DialogActions style={{ padding: 24 }}>
                    <FormActions form={form} variant="text" onActionClick={this.onClose} />
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        return (
            <>
                <Card>
                    <CardContent>
                        {this.renderConfig()}
                        <ActionButton
                            title="Raw"
                            action={this.json}
                            variant="contained"
                            color="primary"
                        />
                    </CardContent>
                </Card>

                {this.renderDialog()}
            </>
        );
    }
}