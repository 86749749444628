import * as React from 'react';

import { CustomPage } from '@crochik/pi-react/application/Page';
import { DataView } from '@crochik/pi-react/ui/DataView';
import { Form } from '@crochik/pi-react/ui/Form';
import App from '@crochik/pi-react/application/App';

import { AppointmentType } from '@crochik/schedulerapi.ts';
import { DataGridComponent } from '@crochik/pi-react/ui/material/DataGridComponent';
import { VerticalForm } from '@crochik/pi-react/ui/material/VerticalForm';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Paper } from '@material-ui/core';

import '../TabPage.css';
import { AppConfiguration } from '../Comps/AppConfig';
import Mapping from '../Comps/Mapping';
import Integrations from '../Comps/Integrations';
import DataService from '@crochik/pi-react/services/DataService';

import { Session } from '../../Session';

interface State {
    appointmentsView?: DataView;
    selectedTab: number;
    appointmentType?: AppointmentType;
}

class PageComponent extends React.Component<{}, State> {
    boundState: State;

    constructor(props: any) {
        super(props);

        this.state = {
            appointmentsView: undefined,
            selectedTab: 0,
            appointmentType: undefined
        };
    }

    async componentWillMount() {
        let args = App().args;
        var id: string = '';
        var appointmentType: AppointmentType | undefined;

        if (args && args.length === 2) {
            id = args[1]['id'];
            console.log(`Load Appointment Type: ${id}`);

            appointmentType = await DataService().one('AppointmentType', { args: [null, id] });
        } else {
            var types = await DataService().select('AppointmentTypes') as AppointmentType[];
            for (var type of types) {
                if (type.leadTypeId && type.id) {
                    // find first with scheduler enabled
                    id = type.id;
                    // read again individually to get the setting
                    appointmentType = await DataService().one('AppointmentType', { args: [null, id] });
                    break;
                }
            }
        }

        if (!appointmentType) {
            console.error(`Failed to load Appointment Type ${id}`);
            return;
        }

        var appointmentsView = this.createGridView(appointmentType);

        var schedulerUrl = `${process.env.REACT_APP_scheduler_url}/nav.html?apptType=${id}`;

        Form.get('AppointmentTypeUrl').assignValues({
            id: id,
            schedulerUrl
        });

        var form = Form.get('AppointmentTypeSettings');
        form.assignValues(appointmentType);
        form.assignValues({ schedulerUrl });
        if (appointmentType.settings) {
            form.assignValues(appointmentType.settings);
        }

        this.setState({
            appointmentType,
            appointmentsView
        });
    }

    createGridView(appointmentType: AppointmentType): DataView {
        let state = DataView.getState('AppointmentsByType');
        state.query = appointmentType;
        state.title = appointmentType.name;

        let view = DataView.get('AppointmentsByType');
        view.reset();

        return view;
    }

    onSelectTab = (event, value) => {
        this.setState({ selectedTab: value });
    }

    render() {
        if (!this.state.appointmentType || !this.state.appointmentType.id) {
            // loading...
            return <div className="TabPage" />;
        }

        var simpleUI = Session.get().flavor === 'Basic';

        var tabs: { name: string, content: any }[] = [];

        if (!simpleUI && this.state.appointmentType.leadTypeId) {
            tabs.push({
                name: 'Scheduler',
                content: (
                    <div key="Scheduler" className="TabPage_scrollable">
                        <VerticalForm form="AppointmentTypeUrl" actionProps={{ color: 'primary' }} />
                        <br />
                        <AppConfiguration appointmentTypeId={this.state.appointmentType.id} context="page.AppointmentType" />
                    </div>
                )
            });
        }

        tabs.push({
            name: 'Settings',
            content: (
                <div key="Settings" className="TabPage_scrollable">
                    <VerticalForm form="AppointmentTypeSettings" actionProps={{ color: 'primary', align: 'right' }} />
                </div>
            )
        });

        if (!simpleUI) {
            tabs.push({
                name: 'Integrations',
                content: (
                    <div key="Integrations" className="TabPage_scrollable">
                        <Integrations type="AppointmentType" id={this.state.appointmentType.id} />
                    </div>
                )
            });
        }

        if (!simpleUI && this.state.appointmentsView) {
            tabs.push({
                name: 'Appointments',
                content: (
                    <div key="Appointments" className="TabPage_grid">
                        <DataGridComponent view={this.state.appointmentsView} />
                    </div>
                )
            });
        }

        // if (!simpleUI && Login().isManager) {
        //     tabs.push({
        //         name: 'Agenda',
        //         content: (
        //             <div key="Agenda" className="TabPage_scrollable">
        //                 <AppointmentTypeAgenda appointmentTypeId={this.state.appointmentType.id} />
        //             </div>
        //         )
        //     });
        // }

        if (!simpleUI && this.state.appointmentType.leadTypeId) {
            tabs.push({
                name: 'Lead Mapping',
                content: (
                    <div key="LeadMapping" className="TabPage_scrollable">
                        <Mapping leadTypeId={this.state.appointmentType.leadTypeId} context="page.AppointmentType" />
                    </div>
                )
            });
        }

        return (
            <div className="TabPage">
                <div className="TabPage_tabs">
                    <Paper>
                        <Tabs
                            value={this.state.selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.onSelectTab}
                            scrollable={true}
                            scrollButtons="auto"
                        >
                            {tabs.map(i => <Tab key={i.name} label={i.name} />)}
                        </Tabs>
                    </Paper>
                </div>
                {tabs[this.state.selectedTab] && tabs[this.state.selectedTab].content}
            </div>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <PageComponent />);