import { App } from '@crochik/pi-react/application/App';
import { DataView } from '@crochik/pi-react/ui/DataView';
import * as User from './User';
import DisableO365Sync from './DisableO365Sync';
import EnableO365Sync from './EnableO365Sync';
import * as Appointment from './Appointment';
import * as AppointmentType from './AppointmentType';
import * as LeadType from './LeadType';
import * as Account from './Account';
import * as Settings from './Settings';
import * as LeadIntegration from './LeadIntegration';
import * as AppointmentIntegration from './AppointmentIntegration';
import * as OrgIntegration from './OrgIntegration';
import * as Report from './Report';
import * as Inspirenet from './Inspirenet';
import * as Flow from './Flow';
import * as OrgMetaData from './OrgMetaData';

import { Form } from '@crochik/pi-react/ui/Form';

import { Default } from '@crochik/pi-react/context';

export default function register() {
    App.bindAction('appointmentsByToolReport', Report.appointmentsByTool);
    App.bindAction('leadSourcesReport', Report.leadSources);
    App.bindAction('leadSourcesByDateReport', Report.leadSourcesByDate);

    App.bindAction('linkMicrosoftAccount', Account.addMicrosoft);
    App.bindAction('linkGTMAccount', Account.addGoToMeeting);
    App.bindAction('linkZoomAccount', Account.addZoom);
    App.bindAction('linkRealMagic', Account.addRealMagic);
    App.bindAction('linkSalesforce', Account.addSalesforce);

    App.bindAction('loginOffice365', User.signInWithMicrosoft );
    App.bindAction('loginInspireNet', () => User.signIn('InspireNet') );
    App.bindAction('loginRealMagic', () => User.signIn('RealMagic') );
    App.bindAction('.User.impersonate', User.impersonate);
    App.bindAction('.User.delete', User.deleteOtherUser);

    App.bindAction('logout', User.logout);
    App.bindAction('deleteUser', User.deleteUser);
    DataView.bindAction('MergeUsers', 'edit', User.mergeUsers);
    DataView.bindAction('MergeUsers', 'importO365', User.importO365);
    DataView.bindAction('MergeUsers', 'importInspireNet', User.importInspireNet);

    App.bindAction('disableO365Sync', DisableO365Sync);
    App.bindAction('enableO365Sync', EnableO365Sync);

    App.bindAction('AddAppointment', Appointment.add);

    DataView.bindAction('AppointmentTypes', 'add', AppointmentType.add);
    DataView.bindAction('LeadTypes', 'add', LeadType.add);

    Default.actions.set('save', LeadType.saveMapping, 'page.LeadType.Mapping');
    Default.actions.set('save', LeadType.saveMapping, 'page.AppointmentType.Mapping');
    Default.actions.set('save', LeadType.saveLeadTypeSettings, 'page.LeadType.Settings');

    DataView.bindAction('AppointmentIntegrations', 'openAppointment', Appointment.openAppointment);
    
    Form.bindAction('Appointment', 'cancel', Appointment.cancel);
    Form.bindAction('Appointment', 'openLead', Appointment.openLead);
    Form.bindAction('Appointment', 'open', Appointment.open);

    Form.bindAction('AppointmentType', 'Save', AppointmentType.save);
    Form.bindAction('AppointmentTypeSettings', 'Save', AppointmentType.saveAll);
    Form.bindAction('AppointmentTypeUrl', 'DisableScheduler', AppointmentType.disableScheduler);
    
    Form.bindAction('SendGridAppointmentTypeIntegration', 'Save', AppointmentIntegration.saveSendGrid);
    Form.bindAction('SendGridAppointmentTypeIntegration', 'Delete', AppointmentIntegration.disableSendGrid);
    
    Form.bindAction('ZoomAppointmentTypeIntegration', 'Save', AppointmentIntegration.saveZoom);
    Form.bindAction('ZoomAppointmentTypeIntegration', 'Delete', AppointmentIntegration.disableZoom);

    Form.bindAction('GoToMeetingAppointmentTypeIntegration', 'Save', AppointmentIntegration.saveGoToMeeting);
    Form.bindAction('GoToMeetingAppointmentTypeIntegration', 'Delete', AppointmentIntegration.disableGoToMeeting);

    Form.bindAction('SendGridOrgIntegration', 'Save', OrgIntegration.saveSendGrid);
    Form.bindAction('SendGridOrgIntegration', 'Delete', OrgIntegration.disableSendGrid);

    Form.bindAction('ZoomOrgIntegration', 'Save', OrgIntegration.saveZoom);
    Form.bindAction('ZoomOrgIntegration', 'Delete', OrgIntegration.disableZoom);

    Form.bindAction('GoToMeetingOrgIntegration', 'Save', OrgIntegration.saveGoToMeeting);
    Form.bindAction('GoToMeetingOrgIntegration', 'Delete', OrgIntegration.disableGoToMeeting);

    Form.bindAction('AutoScheduleOrgIntegration', 'Save', OrgIntegration.saveAutoSchedule);
    Form.bindAction('AutoScheduleOrgIntegration', 'Delete', OrgIntegration.disableAutoSchedule);

    Form.bindAction('LuminOrgIntegration', 'Save', OrgIntegration.saveLumin);
    Form.bindAction('LuminOrgIntegration', 'Delete', OrgIntegration.disableLumin);

    Form.bindAction('LeadType', 'Save', LeadType.save);
    Form.bindAction('LeadType', 'Delete', LeadType.deleteLeadType);
    
    Form.bindAction('SendGridLeadTypeIntegration', 'Save', LeadIntegration.saveSendGrid);
    Form.bindAction('SendGridLeadTypeIntegration', 'Delete', LeadIntegration.disableSendGrid);
        
    Form.bindAction('AutoScheduleLeadTypeIntegration', 'Save', LeadIntegration.saveAutoSchedule);
    Form.bindAction('AutoScheduleLeadTypeIntegration', 'Delete', LeadIntegration.disableAutoSchedule);

    Form.bindAction('LuminLeadTypeIntegration', 'Save', LeadIntegration.saveLumin);
    Form.bindAction('LuminLeadTypeIntegration', 'Delete', LeadIntegration.disableLumin);

    Form.bindAction('WorkingHours', 'save', Settings.saveWorkingHours);
    Form.bindAction('BasicAppointmentTypeSettings', 'Save', AppointmentType.saveSettings);

    Form.bindAction('InspirenetDialog', 'save', Inspirenet.addEvent);

    DataView.bindAction('Settings', 'edit', Settings.edit);

    DataView.bindAction('UserIdentities', 'AddMicrosoft', Account.addMicrosoft);
    DataView.bindAction('UserIdentities', 'AddZoom', Account.addZoom);
    DataView.bindAction('UserIdentities', 'AddGoToMeeting', Account.addGoToMeeting);
    DataView.bindAction('UserIdentities', 'AddRealMagic', Account.addRealMagic);
    DataView.bindAction('UserIdentities', 'AddSalesforce', Account.addSalesforce);

    DataView.bindAction('Flows', 'add', Flow.add);

    DataView.bindAction('OrgMetaData', 'save', OrgMetaData.save);
    DataView.bindAction('OrgMetaData', 'remove', OrgMetaData.remove);
}