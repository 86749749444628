import { observable } from 'mobx';

import * as Oidc from 'oidc-client';
import { Default } from '@crochik/pi-react/context';
import { Session } from '../Session';

interface ISessionState {
    user?: Oidc.User;
}

class Login {
    private manager: Oidc.UserManager;
    private state: ISessionState;
    private sessionManager: Session;

    // private get userName(): string | undefined {
    //     return this.getFromProfile('preferred_username') ||
    //         this.getFromProfile('name') ||
    //         this.getFromProfile('http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name');
    // }
    // private get userRole(): string | undefined {
    //     return this.getFromProfile('role') ||
    //         this.getFromProfile('http://schemas.microsoft.com/ws/2008/06/identity/claims/role');
    // }
    // private get userId(): string | undefined {
    //     return this.getFromProfile('sub');
    // }
    // private get idp(): string | undefined {
    //     return this.getFromProfile('idp');
    // }

    // private get isManager(): boolean {
    //     return this.userRole === 'Manager';
    // }

    constructor(settings: Oidc.UserManagerSettings, sessionManager: Session) {
        // Oidc.Log.logger = console;
        this.sessionManager = sessionManager;
        this.manager = new Oidc.UserManager(settings);
        this.manager.events.addUserLoaded(async (settings) => {
            console.log('user loaded!?!?');
        });

        // this.manager.events.addUserSignedOut(() => {
        //     console.log('user signed out');
        //     console.log(arguments);
        // });

        this.manager.events.addAccessTokenExpiring(() => {
            console.log('token expiring');
            console.log(arguments);
        });

        this.manager.events.addAccessTokenExpired(() => {
            console.log('token expired');
            // remove user from local database and reset ui
            this.signOut();
        });

        this.manager.events.addUserSessionChanged(() => {
            console.log('session changed');
            console.log(arguments);
        });

        this.state = observable({
            user: undefined
        });

        Default.state.set('login', this.state);
    }

    signIn(provider?: string, tenant?: string): Promise<any> {
        var args = {};
        if (provider) {
            args['acr_values'] = `idp:${provider}`;
        }
        if (tenant && args['acr_values'] ) {
            args['acr_values'] += ` tenant:${tenant}`;
        }

        return this.manager.signinRedirect(args);
    }

    async signOut(): Promise<any> {
        console.log('signout');
        localStorage.removeItem('autoLogin');
        sessionStorage.removeItem('request');

        await this.manager.signoutRedirect();
    }

    async init(): Promise<Oidc.User | undefined> {
        try {
            console.log('try to load user from storage');
            var user = await this.manager.getUser();
            if (user) {
                this.state.user = user;
                this.sessionManager.updateStatus(user.access_token);
                return user;
            }

        } catch (ex) {
            console.log(ex);
        }

        return undefined;
    }

    async redirectLogin(): Promise<boolean> {
        var user = await this.manager.getUser();
        if (user) return false;

        this.manager.signinRedirect();
        return true;
    }

    // private getUser(): Oidc.User | undefined {
    //     return this.state.user;
    // }

    // private getFromProfile(name: string): any {
    //     return this.state.user ?
    //         this.state.user.profile[name] : undefined;
    // }
}

var instance: Login | undefined = undefined;
export function register(settings: Oidc.UserManagerSettings, sessionManager: Session) {
    instance = new Login(settings, sessionManager);
}

export default function get(): Login {
    if (!instance) throw new Error('Login Service has not been initialized');
    return instance;
}