import * as React from 'react';
import * as moment from 'moment';
import { CustomPage } from '@crochik/pi-react/application/Page';
import * as Availability from './Availability';
import * as Appointments from './Appointments';
import DataService from '@crochik/pi-react/services/DataService';
import { User, AvailabilityStats, AppointmentAggregation, LeadAggregation } from '@crochik/schedulerapi.ts';
import { Grid } from '@material-ui/core';
import App from '@crochik/pi-react/application/App';
import * as api from '@crochik/schedulerapi.ts';
import { Leads } from './Leads';

// import { DoughnutChart } from './DoughnutChart';

interface IProps {
    className?: string;
    spacing?: 0 | 8 | 16 | 24 | 32 | 40;
    organization?: api.Organization;
}

interface LabelValue {
    labels: string[];
    values: number[];
}

interface IState {
    users?: { [id: string]: User };
    availability?: AvailabilityStats;
    appointmentsByStart?: AppointmentAggregation;
    appointmentsByCreation?: AppointmentAggregation;
    appointments30?: LabelValue;
    expandAvailability: boolean;
    expandAppointments: boolean;
    leadsAggregation?: LeadAggregation;
    leadsPerHourAggregation?: LeadAggregation;
}

export class Dashboard extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            expandAvailability: false,
            expandAppointments: false
        };
    }

    loadUsers() {
        const { organization } = this.props;
        var query = organization ? { args: ['', organization] } : undefined;

        DataService().select('OrgUsers', query).then(
            users => {
                var dict: { [id: string]: User } = {};
                users.forEach(user => {
                    dict[user.id] = user;
                });

                this.setState({
                    users: dict
                });
            }
        ).catch(reason => {
            console.error('failed to load users');
        });
    }

    loadAvailability() {
        const { organization } = this.props;
        var query = organization ? { args: ['', organization] } : undefined;

        DataService().select('AvailabilityStats', query).then(
            availability => {
                this.setState({
                    availability
                });
            }
        ).catch(reason => {
            console.log('failed to get availability stats');
        });
    }

    loadAppointmentAggregation(start: moment.Moment, end: moment.Moment) {
        const { organization } = this.props;
        const query = {
            args: organization ? [{ start, end }, organization] : [{ start, end }]
        };

        DataService().select('AppointmentAggregationByCreation', query)
            .then(
                appointments => {
                    this.setState({
                        appointmentsByCreation: appointments
                    });
                }
            ).catch(reason => {
                console.log('failed to get appointments by creation');
            });
    }

    loadAppointmentAggregationByStart(start: moment.Moment, end: moment.Moment) {
        const { organization } = this.props;
        const query = {
            args: organization ? [{ start, end }, organization] : [{ start, end }]
        };

        DataService().select('AppointmentAggregationByStart', query)
            .then(
                appointments => {
                    this.setState({
                        appointmentsByStart: appointments
                    });

                    // this.sum(appointments);
                }
            ).catch(reason => {
                console.log('failed to get appointments by start');
            });
    }

    loadLeadAggregation() {
        const { organization } = this.props;
        var query = organization ? { args: ['', organization] } : undefined;

        DataService().select('LeadAggregation', query)
            .then(
                leads => {
                    this.setState({ leadsAggregation: leads });
                }
            ).catch(reason => {
                console.log('failed to aggregate leads');
            });
    }

    loadLeadsPerHour() {
        const { organization } = this.props;
        var query = organization ? { args: ['', organization] } : undefined;

        DataService().select('LeadsPerHourAggregation', query)
            .then(
                leads => {
                    this.setState({ leadsPerHourAggregation: leads });
                }
            ).catch(reason => {
                console.log('failed to aggregate leads');
            });
    }

    async componentWillMount() {
        var end = moment().startOf('day');
        var start = end.clone().subtract(30, 'days');

        this.loadUsers();
        this.loadAvailability();
        this.loadAppointmentAggregation(start, end);
        this.loadAppointmentAggregationByStart(start, end);
        this.loadLeadAggregation();
        this.loadLeadsPerHour();
    }

    async sum(data: AppointmentAggregation) {
        const buckets: { [tool: string]: number } = {};

        const max = moment().startOf('day').add(15, 'days');
        const min = moment().startOf('day').subtract(15, 'days');
        if (data.data) {
            for (var row of data.data) {
                const date = moment(row.localDate, 'MM-DD-YYYY');
                if (date.isAfter(max) || date.isBefore(min)) continue;

                var key = row.tool || 'PI';
                var bucket = buckets[key];
                if (!bucket) {
                    buckets[key] = 0;
                }
                buckets[key] += (row.active as number) + (row.cancelled as number);
            }
        }

        const labels = Object.keys(buckets);
        const values: number[] = [];
        for (var c = 0; c < labels.length; c++) {
            values.push(buckets[labels[c]]);
        }

        this.setState({
            appointments30: {
                labels,
                values
            }
        });
    }

    showAvailability() {
        const { organization } = this.props;
        if (!organization) {
            App().selectPage('OrgUsers');
        }
    }

    showCalendar() {
        const { organization } = this.props;
        if (!organization) {
            App().selectPage('BizCalendar');
        }
    }

    toggleAvailability = () => {
        this.setState({
            expandAvailability: !this.state.expandAvailability,
            expandAppointments: this.state.expandAvailability ? this.state.expandAppointments : false
        });
    }

    toggleAppointments = () => {
        this.setState({
            expandAppointments: !this.state.expandAppointments,
            expandAvailability: this.state.expandAppointments ? this.state.expandAvailability : false
        });
    }

    render() {
        var { className, spacing } = this.props;

        var {
            users,
            availability,
            expandAvailability,
            appointmentsByStart,
            appointmentsByCreation,
            // expandAppointments,
            // appointments30,
            leadsAggregation,
            leadsPerHourAggregation
        } = this.state;

        return (
            <div className={className || 'ContentPage_scrollable'}>
                <Grid container={true} spacing={spacing}>
                    <Grid item={true} md={12} xs={12}>
                        <Leads
                            title="Leads Per Hour"
                            leads={leadsPerHourAggregation}
                            perHour={true}
                        />
                    </Grid>

                    <Grid item={true} md={12} xs={12}>
                        <Leads
                            title="Leads Per Day"
                            leads={leadsAggregation}
                        />
                    </Grid>

                    <Grid item={true} md={12} xs={12}>
                        <Availability.Availability
                            title="Organization Forecast"
                            isSelected={expandAvailability}
                            report={Availability.Report.Overall}
                            onClick={this.toggleAvailability}
                            availability={availability}
                        />
                    </Grid>
                    {expandAvailability &&
                        <Grid item={true} md={6} xs={12}>
                            <Availability.Availability
                                title="Availability by User"
                                report={Availability.Report.OpenSlotsByUser}
                                // onClick={this.showAvailability}
                                availability={availability}
                                users={users}
                            />
                        </Grid>
                    }
                    {expandAvailability &&
                        <Grid item={true} md={6} xs={12}>
                            <Availability.Availability
                                title="Appointments by User"
                                report={Availability.Report.AppointmentsByUser}
                                // onClick={this.showCalendar}
                                availability={availability}
                                users={users}
                            />
                        </Grid>
                    }
                    <Grid item={true} md={12} xs={12}>
                        <Appointments.Appointments
                            title="First Appointments Scheduled"
                            isSelected={false}
                            report={Appointments.Report.ByTool}
                            appointments={appointmentsByCreation}
                        />
                    </Grid>
                    <Grid item={true} md={12} xs={12}>
                        <Appointments.Appointments
                            title="Past Appointments"
                            isSelected={false}
                            report={Appointments.Report.ActiveByTool}
                            appointments={appointmentsByStart}
                        />
                    </Grid>
                    {/* {expandAppointments &&
                        <Grid item={true} md={6} xs={12}>
                            <Appointments.Appointments
                                title="Cancellations"
                                isSelected={false}
                                report={Appointments.Report.Active}
                                appointments={appointments}
                            />
                        </Grid>
                    }
                    {expandAppointments && appointments30 &&
                        <Grid item={true} sm={6} xs={12}>
                            <DoughnutChart
                                title="Appointments - ±15 days"
                                labels={appointments30.labels}
                                values={appointments30.values}
                            />
                        </Grid>
                    } */}
                </Grid>
            </div>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <Dashboard spacing={16} />);