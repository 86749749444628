import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import { FullCalendar } from './FullCalendar';
import { AppointmentType, LeadType, Lead, User } from '@crochik/schedulerapi.ts';
import DataService from '@crochik/pi-react/services/DataService';
import { action } from 'mobx';
import { Slot } from '@crochik/pi-react/ui/material/Calendar';
import { DropDown } from './DropDown';
import { observer } from 'mobx-react';
import DialogService from '@crochik/pi-react/services/Dialog';

import { FormDialog } from '@crochik/pi-react/ui/material/FormDialog';
import { Form } from '@crochik/pi-react/ui/Form';
import moment = require('moment');
import { Default } from '@crochik/pi-react/context';
import { Session } from '../../Session';

import CloseIcon from '@material-ui/icons/CloseRounded';
import { AddAppointmentResult } from '../../actions/Appointment';

interface Props {
    lead: Lead;
    onClose: () => any;
}

interface State {
    leadType?: LeadType;
    appointmentTypes?: AppointmentType[];
    users?: User[];
    selectedAppointmentType?: AppointmentType;
    selectedUserId: string;

    confirmDialog: boolean;
}

@observer
export class CalendarDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedUserId: '',
            confirmDialog: false
        };
    }

    componentWillMount() {
        this.findAppointmentType();
    }

    async getUsers(state: State) {
        if (!state.selectedAppointmentType) {
            // error
            return;
        }

        // load type to get settings
        state.selectedAppointmentType = await DataService().one(
            'AppointmentType',
            { args: [null, state.selectedAppointmentType.id] }
        );

        if (!state.selectedAppointmentType) {
            // error
            return;
        }

        var users: User[] = await DataService().select('PossibleUsers', {
            args:
                [
                    null,
                    {
                        leadId: this.props.lead.id as string,
                        appointmentTypeId: state.selectedAppointmentType.id
                    }
                ]
        });

        var found = false;
        var currUser = Session.get().user;
        if (!currUser || !currUser.id || !currUser.role) {
            console.error('invalid state');
            return;
        }

        for (var user of users) {
            if (user.id === currUser.id) {
                console.log('current user is in the list');
                found = true;
                break;
            }
        }

        if (currUser.role === User.RoleEnum.Manager) {
            var selectedUser: string = (users.length === 0 || !users[0].id) ? '' : users[0].id as string;
            state.users = users;
            state.selectedUserId = found ? currUser.id : selectedUser;

        } else {
            state.users = undefined;
            state.selectedUserId = found ? currUser.id : '';
        }

        this.setState(state);
    }

    async findAppointmentType() {
        var appts: AppointmentType[] = await DataService().select('AppointmentTypes');
        if (!appts || appts.length < 1) {
            Promise.reject('No Appointment Types found');
            return;
        }

        var selectedAppointmentType = appts[0];

        var newState: State = {
            ...this.state,
            appointmentTypes: appts,
            selectedAppointmentType
        };

        await this.getUsers(newState);
    }

    onAdd = async () => {
        try {
            const result: AddAppointmentResult = await Default.actions.executeAsync('AddAppointment', 'app');
            if (!result.success) {
                DialogService.critical({
                    title: 'Error',
                    message: result.error || 'Failed to Create Appointment'
                });
                return;
            }

            this.dismissDialog();

        } catch (error) {
            DialogService.critical({
                title: 'Error',
                message: 'Failed to Create Appointment'
            });
            return;
        }

        this.props.onClose();
    }

    showDialog(start: moment.Moment, end: moment.Moment) {
        if (!this.state.selectedAppointmentType) {
            throw new Error('Missing appointment type');
        }

        var form = Form.get('AddAppointment');
        form.bindAction('schedule', this.onAdd);
        form.bindAction('cancel', this.dismissDialog);

        form.assignValues({
            subject: this.props.lead.name,
            date: start.format('YYYY-MM-DD'),
            start: start.format('HH:mm'),
            end: end.format('HH:mm'),
            userId: this.state.selectedUserId,
            leadId: this.props.lead.id,
            appointmentTypeId: this.state.selectedAppointmentType.id,
            notes: undefined
        });

        this.setState({
            ...this.state,
            confirmDialog: true
        });
    }

    @action
    onSlotClicked = (evt: Slot) => {
        var duration = this.state.selectedAppointmentType &&
            this.state.selectedAppointmentType.settings &&
            this.state.selectedAppointmentType.settings.duration ?
            this.state.selectedAppointmentType.settings.duration : 60;

        if (evt.tag) {
            if (evt.tag['_type'] === 'Event' || evt.tag['_type'] === 'AllDayEvent') {
                // selecting an existing event?!??
                var blockStart = moment(evt.tag.start);
                this.showDialog(blockStart, blockStart.clone().add(duration, 'minutes'));
                return;
            }

            if (evt.tag['_type'] === 'OpenSlot') {
                this.showDialog(evt.tag.start, evt.tag.end);
                return;
            }
        }

        var start = moment(evt['dayId'], 'YYYY-MM-DD').add(evt.start, 'minutes');

        this.showDialog(start, start.clone().add(duration, 'minutes'));
    }

    onAppointmentTypeSelected = async (id: string) => {
        if (!this.state.appointmentTypes) {
            console.error('no appointment types');
            return;
        }

        console.log(`selected appointment type: ${id}`);

        this.setState({ selectedUserId: '' });

        var state = {
            ...this.state,
        };

        for (var appt of this.state.appointmentTypes) {
            if (appt.id === id) {
                state.selectedAppointmentType = appt;
                await this.getUsers(state);
                break;
            }
        }
    }

    onUserSelected = (id: string) => {
        console.log(`selected user: ${id}`);
        this.setState({
            ...this.state,
            selectedUserId: id
        });
    }

    dismissDialog = () => {
        this.setState({
            ...this.state,
            confirmDialog: false
        });
    }

    render() {
        const selectedAppointmentType = this.state.selectedAppointmentType && this.state.selectedAppointmentType.id ?
            this.state.selectedAppointmentType.id : '';

        var { appointmentTypes, users } = this.state;
        const showDropdowns = (appointmentTypes && appointmentTypes.length > 1) ||
            (users && users.length > 1);

        const style = showDropdowns ? { height: 'calc(100 * var(--vh) - 64px - 24px - 60px)' } :
            { height: 'calc(100 * var(--vh) - 64px - 24px)' };

        return (
            <Dialog
                className="AppointmentDialog"
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
                open={true}
                onClose={this.props.onClose}
                disableEnforceFocus={true}
            >
                <DialogTitle style={{ paddingTop: 8, paddingBottom: 8 }}>
                    <div className="PopupDialog_title">
                        <span>Schedule</span>
                        <IconButton onClick={this.props.onClose}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="DialogContent">
                    {showDropdowns &&
                        <div className="CalendarDialog_dropDown">
                            {
                                appointmentTypes && length > 1 &&
                                <DropDown
                                    title="Type"
                                    selected={selectedAppointmentType}
                                    items={appointmentTypes}
                                    onChange={this.onAppointmentTypeSelected}
                                />
                            }
                            {
                                users && users.length > 1 &&
                                <DropDown
                                    title="User"
                                    selected={this.state.selectedUserId}
                                    items={users}
                                    onChange={this.onUserSelected}
                                />
                            }
                        </div>
                    }
                    <div className="Calendar" style={style}>
                        {
                            this.state.selectedAppointmentType && this.state.selectedUserId &&
                            <FullCalendar
                                splitOpenSlots={true}
                                userId={this.state.selectedUserId}
                                appointmentType={this.state.selectedAppointmentType}
                                onClick={this.onSlotClicked}
                                collapseDays={false}
                                loadMore={true}
                            />
                        }
                    </div>
                    {
                        this.state.confirmDialog &&
                        <FormDialog
                            open={true}
                            form="AddAppointment"
                            onClose={this.dismissDialog}
                            contentClassName="FormDialog_content"
                        />
                    }
                </DialogContent>
            </Dialog>
        );
    }
}