import Dialog from '@crochik/pi-react/services/Dialog';

import * as API from '../services/API';
import LoginService from '../services/Login';

async function disable() {
    console.log('Disable outlook sync');
    var success = await API.disableO365Sync();
    if ( !success ) {
        Dialog.inform({
            title: 'Error',
            message: 'Error Disabling Office 365 Calendar Sync'
        });
        return;
    }

    LoginService().signOut();
}

export default function action() {
    Dialog.confirm({
        title: 'Are you sure?',
        message: 'Before you can use this application again you will have to re-enable sync. If you continue, we will reset our copy of your calendar and disable the booking functionality.',
        onPositive: disable,
        positiveCaption: 'Yes',
        negativeCaption: 'No',
    });
}