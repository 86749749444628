import * as React from 'react';

import { CustomPage } from '@crochik/pi-react/application/Page';

import '../ContentPage.css';

import Integrations, { Type } from '../Comps/Integrations';

interface IProps {
    type?: Type;
}

interface State {
}

class PageComponent extends React.Component<IProps, State> {

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    render() {
        const { type } = this.props;

        return (
            <div className="ContentPage">
                <div className="ContentPage_body">
                    <Integrations type={type || 'Org'} />
                </div>
            </div>
        );
    }
}

export const Organization = (name?: string) => new CustomPage({ name: name || 'Integrations', label: 'Integrations' }, <PageComponent />);
export const Account = (name?: string) => new CustomPage({ name: name || 'AccountIntegrations', label: 'Integrations' }, <PageComponent type="Account" />);