import * as React from 'react';

import { CustomPage } from '@crochik/pi-react/application/Page';
import { Form } from '@crochik/pi-react';
import { VerticalForm } from '@crochik/pi-react/ui/material/VerticalForm';

interface State {
    form?: Form;
}

class PageComponent extends React.Component<{}, State> {
    boundState: State;

    constructor(props: any) {
        super(props);

        this.state = {
            form: Form.get('TestForm')
        };
    }

    render() {
        const {form} = this.state;
        if (!form) {
            return (
                <div>Error...</div>
            );
        }
        
        return (
            <VerticalForm form={form}/>
        );
    }
}

export default (name: string) => new CustomPage({ name, label: 'Test' }, <PageComponent />);