import { IMenu } from '@crochik/pi-react/context/IMenu';
import { IForm } from '@crochik/pi-react/context/IForm';
import { IPage } from '@crochik/pi-react/context/IPage';
import { IDataView } from '@crochik/pi-react/context/IDataView';

import { Menu } from '@crochik/pi-react/application/Menu';
import { createPage } from '@crochik/pi-react/application/Page';

import { DataView } from '@crochik/pi-react/ui/DataView';
import { Form } from '@crochik/pi-react/ui/Form';

export interface AppConfig {
    name: string;
    initialPage?: string;
    menu?: string;
    menus?: IMenu[];
    forms?: IForm[];
    pages?: IPage[];
    datagrids?: IDataView[];
}

export default async function load(url: string): Promise<AppConfig> {
    var resp = await fetch(url);
    var json = await resp.json();
    var config = json as AppConfig;

    console.debug(config);

    if (config.forms) {
        config.forms.forEach((form) => Form.create(form));
    }

    if (config.pages) {
        config.pages.forEach((page) => createPage(page));
    }

    if (config.menus) {
        config.menus.forEach((menu) => Menu.create(menu));
    }

    if (config.datagrids) {
        config.datagrids.forEach((dg) => DataView.create(dg));
    }

    return config;
}
