import * as api from '@crochik/schedulerapi.ts';
import * as moment from 'moment';

import { IField } from '@crochik/pi-react/context';
import { getAccessToken, getConfig, getApiServer } from './api';

var _defaultAppointmentType: string | undefined;
var _integrations: api.IIntegration[] | undefined = undefined;

export interface IGetUserAvailability {
    userId: string;
    appointmentTypeId: string;
    start: Date;
    end: Date;
}

export interface ISendGridIntegration {
    apiKey?: string;
    fromEmail?: string;
    fromName?: string;
}

export interface ISendGridLeadIntegration extends ISendGridIntegration {
    id: string;
    templateId: string;
    autoSchedule: boolean;
}

export interface ISendGridAppointmentIntegration extends ISendGridIntegration {
    id: string;
    addedTemplateId?: string;
    cancelledTemplateId?: string;
    requiredIntegrationId?: string;
}

export interface IAutoScheduleLeadTypeIntegration {
    id: string;
    appointmentTypeId?: string;
}

export interface ILuminLeadTypeIntegration extends api.LuminLeadTypeIntegrationData {
    id: string;
    key: string;
}

function available(): api.AvailabilityApi {
    return new api.AvailabilityApi(getConfig());
}
function feature(): api.FeatureApi {
    return new api.FeatureApi(getConfig());
}
function appointmentType(): api.AppointmentTypeApi {
    return new api.AppointmentTypeApi(getConfig());
}
function appointment(): api.AppointmentApi {
    return new api.AppointmentApi(getConfig());
}
function calendarEvent(): api.CalendarEventApi {
    return new api.CalendarEventApi(getConfig());
}
function user(): api.UserApi {
    return new api.UserApi(getConfig());
}
function organization(): api.OrganizationApi {
    return new api.OrganizationApi(getConfig());
}
function identity(): api.IdentityApi {
    return new api.IdentityApi(getConfig());
}
function setting(): api.SettingApi {
    return new api.SettingApi(getConfig());
}
function leadType(): api.LeadTypeApi {
    return new api.LeadTypeApi(getConfig());
}
function lead(): api.LeadApi {
    return new api.LeadApi(getConfig());
}
function integration(): api.IntegrationApi {
    return new api.IntegrationApi(getConfig());
}
function sendGrid(): api.SendGridApi {
    return new api.SendGridApi(getConfig());
}
function lumin(): api.LuminApi {
    return new api.LuminApi(getConfig());
}
function zoom(): api.ZoomApi {
    return new api.ZoomApi(getConfig());
}
function gtm(): api.GoToMeetingApi {
    return new api.GoToMeetingApi(getConfig());
}
function autoSchedule(): api.AutoScheduleApi {
    return new api.AutoScheduleApi(getConfig());
}
function integrationLead(): api.IntegrationLeadApi {
    return new api.IntegrationLeadApi(getConfig());
}
function integrationAppointment(): api.IntegrationAppointmentApi {
    return new api.IntegrationAppointmentApi(getConfig());
}
function appConfig(): api.AppConfigApi {
    return new api.AppConfigApi(getConfig());
}

export async function defaultAppointmentType(): Promise<string> {
    if (!_defaultAppointmentType) {
        var appointmentTypes = await getAppointmentTypes();
        if (appointmentTypes.length >= 1) {
            _defaultAppointmentType = appointmentTypes[0].id as string;

        } else {
            // var appointmentType = await appointmentType().appointmentTypePost({
            //     name: 'default'
            // });
            // _defaultAppointmentType = appointmentType().id as string;
            return Promise.reject('No appointment types defined for user');
        }
    }

    return _defaultAppointmentType;
}

export function getLeadStatus(): Promise<api.LeadStatus[]> {
    return new api.LeadStatusApi(getConfig()).get();
}

export function triggerEvent(leadId: string, integrationId: string, evt: api.IntegrationEvent): Promise<api.IntegrationEvent> {
    return new api.LeadIntegrationApi(getConfig()).updateIntegration(
        leadId,
        integrationId,
        evt
    );
}

export function exportReport(report: string, filter?: string): Promise<Response> {
    const config = new api.Configuration({
        basePath: getApiServer(),
        accessToken: getAccessToken()
    });

    const args = api.ReportApiFetchParamCreator(config).accountReport(report as any, filter, {
        headers: { 'Accept': 'text/csv' }
    });

    return fetch(config.basePath + args.url, args.options);
}

export function report(report: string, filter?: string): Promise<api.Report> {

    var client = new api.ReportApi(new api.Configuration({
        basePath: getApiServer(),
        accessToken: getAccessToken()
    }));

    return client.accountReport(report as any, filter, {
        headers: { 'Accept': 'application/json' }
    });
}

export function getSendGridOnLeadType(id: string): Promise<api.SendGridLeadIntegrationData> {
    return sendGrid().getSendGridLeadTypeIntegration(id);
}
export function getSendGridOnOrg(): Promise<api.SendGridEntityIntegrationData> {
    return sendGrid().getSendGridOrganizationIntegration();
}
export function getLuminOnOrg(): Promise<api.LuminIntegrationData> {
    return lumin().getLuminOrganizationIntegration();
}
export function getAutoScheduleOnOrg(): Promise<api.AutoScheduleIntegrationData> {
    return autoSchedule().getAutoScheduleOrganizationIntegration();
}
export function getZoomOnOrg(): Promise<api.ZoomIntegrationData> {
    return zoom().getZoomOrganizationIntegration();
}
export function getGoToMeetingOnOrg(): Promise<api.ZoomIntegrationData> {
    return gtm().getGoToMeetingOrganizationIntegration();
}
export function getAutoScheduleOnLeadType(id: string): Promise<api.AutoScheduleIntegrationData> {
    return autoSchedule().getAutoScheduleLeadTypeIntegration(id);
}
export function getLuminOnLeadType(id: string): Promise<api.LuminLeadTypeIntegrationData> {
    return lumin().getLuminLeadTypeIntegration(id);
}

export function enableSendGridOnLeadType(settings: ISendGridLeadIntegration): Promise<api.SendGridLeadIntegrationData> {
    return sendGrid().addSendGridIntegrationToLeadType(
        settings.id, {
        data: {
            fromEmail: settings.fromEmail,
            fromName: settings.fromName,
            toEmailField: 'email',
            toNameField: 'name',
            templateId: settings.templateId
        },
        auth: {
            apiKey: settings.apiKey
        }
    }
    );
}

export function enableAutoScheduleOnLeadType(settings: IAutoScheduleLeadTypeIntegration): Promise<api.AutoScheduleIntegrationData> {
    return autoSchedule().addAutoScheduleIntegrationToLeadType(
        settings.id, {
        appointmentTypeId: settings.appointmentTypeId
    }
    );
}

export function enableLuminOnLeadType(settings: ILuminLeadTypeIntegration): Promise<api.LuminLeadTypeIntegrationData> {
    let { id, key, ...data } = settings;

    return lumin().addLuminIntegrationToLeadType(
        id,
        {
            data,
            auth: {
                key
            }
        }
    );
}

export function enableZoomOnAppointmentType(settings: { id: string }): Promise<api.ZoomIntegrationData> {
    return zoom().addZoomIntegrationToAppointmentType(
        settings.id, {
        entityId: undefined // no meaning as now
    }
    );
}
export function enableGoToMeetingOnAppointmentType(settings: any): Promise<api.GoToMeetingIntegrationData> {
    return gtm().addGoToMeetingIntegrationToAppointmentType(
        settings.id, {
        entityId: undefined // no meaning as now
    }
    );
}
export function enableZoomOnOrg(settings?: any): Promise<api.ZoomIntegrationData> {
    return zoom().addZoomIntegrationToOrganization(
        {
            entityId: undefined // no meaning as now
        }
    );
}
export function enableGoToMeetingOnOrg(settings: any): Promise<api.GoToMeetingIntegrationData> {
    return gtm().addGoToMeetingIntegrationToOrganization(
        {
            entityId: undefined // no meaning as now
        }
    );
}

export function enableAutoScheduleOnOrg(settings: any): Promise<api.AutoScheduleIntegrationData> {
    return autoSchedule().addAutoScheduleIntegrationToOrganization(
        {
            appointmentTypeId: undefined
        }
    );
}

export function enableLuminOnOrg(settings: api.LuminIntegrationData): Promise<api.LuminIntegrationData> {
    return lumin().addLuminIntegrationToOrganization(settings);
}

export function getSendGridOnAppointmentType(id: string): Promise<api.SendGridAppointmentIntegrationData> {
    return sendGrid().getSendGridAppointmentTypeIntegration(id);
}
export function getZoomOnAppointmentType(id: string): Promise<api.ZoomIntegrationData> {
    return zoom().getZoomAppointmentTypeIntegration(id);
}
export function getGoToMeetingOnAppointmentType(id: string): Promise<api.ZoomIntegrationData> {
    return gtm().getGoToMeetingAppointmentTypeIntegration(id);
}

export function enableSendGridOnAppointmentType(settings: ISendGridAppointmentIntegration): Promise<api.SendGridAppointmentIntegrationData> {
    return sendGrid().addSendGridIntegrationToAppointmentType(
        settings.id, {
        data: {
            fromEmail: settings.fromEmail,
            fromName: settings.fromName,
            toEmailField: 'email',
            toNameField: 'name',
            requiredIntegrationId: settings.requiredIntegrationId && settings.requiredIntegrationId.length === 36 ? settings.requiredIntegrationId : undefined,
            cancelledTemplateId: settings.cancelledTemplateId,
            addedTemplateId: settings.addedTemplateId
        },
        auth: {
            apiKey: settings.apiKey
        }
    }
    );
}
export function enableSendGridOnOrg(settings: ISendGridIntegration): Promise<api.SendGridEntityIntegrationData> {
    return sendGrid().addSendGridIntegrationToOrganization(
        {
            data: {
                fromEmail: settings.fromEmail,
                fromName: settings.fromName,
                toEmailField: 'email',
                toNameField: 'name'
            },
            auth: {
                apiKey: settings.apiKey
            }
        }
    );
}

export async function getIntegrations(): Promise<api.IIntegration[]> {
    // cache
    if (!_integrations) {
        _integrations = await integration().getAll();
    }

    return _integrations;
}

export function getUserIntegrations(): Promise<api.IIntegration[]> {
    return integration().getEntityIntegrations();
}
export function getOrgIntegrations(): Promise<api.IIntegration[]> {
    return integration().getOrganizationIntegrations();
}
export function getEntityIntegrations(): Promise<api.IIntegration[]> {
    // return integration().getOrganizationIntegrations();
    return Promise.reject('not implemented');
}

export function getAppointmentTypeIntegrations(id: string): Promise<api.IIntegration[]> {
    return integration().getAppointmentTypeIntegrations(id);
}
export function getLeadTypeIntegrations(id: string): Promise<api.IIntegration[]> {
    return integration().getLeadTypeIntegrations(id);
}

export function me(): Promise<api.User> {
    return user().me();
}

export function deleteUser(id?: string): Promise<Response> {
    return id ? user().deleteUser(id) : user()._delete();
}

export function setAppointmentTypeName(appointmentTypeId: string, name: string): Promise<api.AppointmentType> {
    return appointmentType().updateName(appointmentTypeId, name);
}

export function disableScheduler(appointmentTypeId: string): Promise<api.AppointmentType> {
    return appointmentType().updateLeadType(appointmentTypeId);
}

export function setAppointmentTypeSettings(appointmentTypeId: string, settings: api.SchedulingSettings): Promise<api.AppointmentType> {
    return appointmentType().updateSettings(appointmentTypeId, settings);
}

export function getAppConfig(appointmentTypeId: string): Promise<api.AppConfig> {
    return appConfig().getAppConfig(appointmentTypeId);
}

export function setAppConfig(appointmentTypeId: string, config: api.AppConfig): Promise<api.AppConfig> {
    return appConfig().setAppConfig(appointmentTypeId, config);
}

export function createLeadType(name: string): Promise<api.LeadType> {
    return leadType().add({
        name,
        settings: {
            fields: []
        }
    });
}

export function addAppointment(leadId: string, appt: api.AddAppointment): Promise<api.Appointment> {
    return appointment().addAppointmentToLead(leadId, appt);
}

export function getPossibleUsers(leadId: string, appointmentTypeId: string): Promise<api.User[]> {
    return user().getPossibleUsers(leadId, appointmentTypeId);
}

export function getOrganizations(): Promise<api.Organization[]> {
    return organization().getOrganizations();
}

export function getOrgUsers(orgId?: string): Promise<api.User[]> {
    if (orgId) {
        return user().getOrganizationUsers(orgId);
    }

    return user().getMyOrganizationUsers();
}

export function getUsers(): Promise<api.User[]> {
    return user().getUsers_1();
}

export function getMergeCandidates(): Promise<api.MergeUserCandidateMatch[]> {
    return user().getMergeCandidates();
}

export function mergeUsers(entityIds: string[]): Promise<api.User> {
    return user().mergeUsers(entityIds);
}

export function importInspireNetUsers(): Promise<Response> {
    return new api.InspirenetApi(getConfig()).importUsers();
}

export function getAppointmentTypes(): Promise<api.AppointmentType[]> {
    return appointmentType().get();
}

export function getAppointments(appointmentTypeId?: string): Promise<api.Appointment[]> {
    if (!appointmentTypeId) {
        var start = moment().startOf('day').toDate();
        return appointment().get(start);
    }

    return appointment().getByType(appointmentTypeId);
}

export function getOrgAppointments(appointmentTypeId?: string): Promise<api.Appointment[]> {
    if (!appointmentTypeId) {
        var start = moment().startOf('day').toDate();
        return appointment().getOrgAppointments(start);
    }

    return appointment().getOrgAppointmentsByType(appointmentTypeId);
}

export function getAvailability(): Promise<api.UserAvailability[]> {
    return available().availability();
}

export function saveAvailability(avail: api.UserAvailability[]): Promise<api.UserAvailability[]> {
    return available().putAvailability(avail);
}

export async function updateAvailability(dayId: number, startMinutes: number, durationMinutes: number, appointmentTypeIds: string[], id?: string): Promise<api.UserAvailability> {
    var avail: api.UserAvailability = {
        id,
        dayId,
        startMinutes,
        durationMinutes,
        appointmentTypeIds
    };

    var rows = await available().putAvailability([avail]);
    if (rows.length !== 1) throw new Error('Unexpected number of rows');
    return rows[0];
}

export function deleteAvailability(id: string): Promise<Response> {
    return available().deleteAvailability_2(id);
}

export async function getOpenSlots(): Promise<api.TimeSlot[]> {
    var appointmentType = await defaultAppointmentType();
    return available().allSlots(
        appointmentType,
        moment().startOf('day').toDate(),
        moment().startOf('day').add('days', 14).toDate()
    );
}

export async function getCalendar(args: IGetUserAvailability): Promise<api.EventsAndSlots> {
    const calendar = await available().userAvailability(
        args.userId,
        args.appointmentTypeId,
        args.start,
        args.end
    );

    calendar.events = dedup(calendar.events);

    return calendar;
}

export function dedup(events: api.CalendarEvent[] | undefined): api.CalendarEvent[] | undefined {
    if (!events) return events;

    const piEvents: { [url: string]: boolean } = {};
    for (var evt of events) {
        if (evt.source === 'pi' && evt.webLink) {
            piEvents[evt.webLink] = true;
        }
    }

    return events.filter(x => x.source === 'pi' || !x.webLink || !piEvents[x.webLink]);
}

export async function getMyCalendar(start: Date, end: Date): Promise<api.EventsAndSlots> {
    var appointmentType = await defaultAppointmentType();
    var calendar = await available().calendar(
        appointmentType,
        start,
        end
    );

    calendar.events = dedup(calendar.events);

    return calendar;
}

export function getEvents(): Promise<api.CalendarEvent[]> {
    return calendarEvent().myEvents(
        moment().startOf('day').toDate(),
        moment().startOf('day').add('days', 14).toDate()
    );
}

export async function isCalendarSyncEnabled(): Promise<boolean> {
    var settings = await feature().calendar();
    console.debug(settings);
    return settings.length > 0;
}

export function hasMicrosoftAccount(): Promise<boolean> {
    return hasAccount('Microsoft');
}
export function hasInspireNetAccount(): Promise<boolean> {
    return hasAccount('InspireNet');
}
export function hasZoomAccount(): Promise<boolean> {
    return hasAccount('Zoom');
}
export function hasGoToMeetingAccount(): Promise<boolean> {
    return hasAccount('GoToMeeting');
}

export function disableAppointmentTypeIntegration(id: string, serviceName: string) {
    return integration().removeAppointmentTypeIntegration(id, serviceName);
}

export function disableLeadTypeIntegration(id: string, serviceName: string) {
    return integration().removeLeadTypeIntegration(id, serviceName);
}

export function disableOrgIntegration(serviceName: string): Promise<any> {
    return Promise.reject('Not implemented');
}

export function getAccounts(provider: 'Microsoft' | 'InspireNet' | 'Zoom' | 'SendGrid' | 'GoToMeeting'): Promise<api.Identity[]> {
    return identity().getIdentity(provider);
}

export async function hasAccount(provider: 'Microsoft' | 'InspireNet' | 'Zoom' | 'SendGrid' | 'GoToMeeting'): Promise<boolean> {
    try {
        var identities = await getAccounts(provider);
        return identities.length === 1;

    } catch (ex) {
        if ('status' in ex) {
            console.error(`got ${ex.staus} from API`);
        }
        return false;
    }
}

export async function enbleCalendarAsync(): Promise<boolean> {
    try {
        await feature().calendarEnable();
        console.log('success');

    } catch (ex) {
        console.error(`Failed to enable sync: ${ex.status}`);
        return false;
    }

    return true;
}

export async function disableO365Sync(): Promise<boolean> {
    var resp = await feature().calendarDisable();
    return resp.status === 200;
}

export function getSettings(): Promise<api.Setting[]> {
    return setting().getAllSetting();
}

export function setTimeZone(timezone: string): Promise<api.TimeZone> {
    return setting().setTimeZone(timezone);
}

export function setWorkingHours(startMinutes: number, endMinutes: number): Promise<api.WorkingHoursSetting> {
    return setting().setWorkingHours({
        startMinutes, endMinutes
    });
}

export function getLeads(leadTypeId: string): Promise<api.Leads> {
    return lead().getByType(leadTypeId);
}

export function searchLeads(value: string, max?: number): Promise<api.LeadSearchResults> {
    return lead().search(
        {
            criteria: api.LeadSearch.CriteriaEnum.Auto,
            value
        },
        max && max > 0 ? max : 0
    );
}

export function searchAccountLeads(value: string, max?: number): Promise<api.LeadSearchResults> {
    return lead().accountSearch(
        {
            criteria: api.LeadSearch.CriteriaEnum.Auto,
            value
        },
        max && max > 0 ? max : 0
    );
}

export function getLead(id: string): Promise<api.Leads> {
    return lead().get(id);
}

export function getLeadIntegrations(id: string): Promise<api.LeadIntegration[]> {
    return integrationLead().getIntegrations(id);
}

export function getLeadAppointments(id: string): Promise<api.Appointment[]> {
    return appointment().getByLead(id);
}

export function getAppointment(id: string): Promise<api.Appointment> {
    return appointment().getById(id);
}

export function cancelAppointment(id: string): Promise<api.Appointment> {
    return appointment().cancel(id);
}

export function getAppointmentIntegrations(id: string): Promise<api.AppointmentIntegration[]> {
    return integrationAppointment().getIntegrations(id);
}

export function getLeadTypes(): Promise<api.LeadType[]> {
    return leadType().getAll();
}

export function getUserIdentities(): Promise<api.Identity[]> {
    return identity().identity();
}

export function saveLeadTypeSettings(id: string, mapping: api.LeadTypeSettings): Promise<api.LeadTypeSettings> {
    return leadType().addMapping(id, mapping);
}

export function updateLeadType(id: string, name?: string, flowId?: string): Promise<api.LeadType> {
    return leadType().update(id, name, flowId);
}

export function deleteLeadType(id: string): Promise<api.LeadType> {
    return leadType()._delete(id);
}

// export function donwloadLeadTypeMapping(id: string): Promise<Response> {
//     return leadType().downloadMapping(id);
// }

export function getLeadTypeMapping(id: string): Promise<api.LeadTypeSettings> {
    // return leadType().getMapping(id);
    return leadType().calculateMapping(id);
}

export function getLeadTypeSettings(id: string): Promise<api.LeadTypeSettings> {
    return leadType().getMapping(id);
}

export function getLeadType(id: string): Promise<api.LeadType> {
    return leadType().getById(id);
}

export function addAppointmentType(name: string): Promise<api.AppointmentType> {
    return appointmentType().add(name);
}

export function getAppointmentType(id: any): Promise<api.AppointmentType> {
    return appointmentType().getById(id);
}

export function getAvailabilityStats(id?: string): Promise<api.AvailabilityStats> {
    if (id) {
        return new api.StatsApi(getConfig()).getAvailabilityForOrg(id);
    }

    return new api.StatsApi(getConfig()).getOrgAvailability();
}

export function getAppointmentAggregation(start: Date, end: Date, byStart: boolean): Promise<api.AppointmentAggregation> {
    if (byStart) {
        return new api.StatsApi(getConfig()).aggregateAppointmentsByStart(start, end);
    } else {
        return new api.StatsApi(getConfig()).aggregateAppointmentsByCreation(start, end);
    }
}

export function getAppointmentAggregationForOrg(orgId: string, start: Date, end: Date, byStart: boolean): Promise<api.AppointmentAggregation> {
    if (byStart) {
        return new api.StatsApi(getConfig()).aggregateAppointmentsForOrgByStart(orgId, start, end);
    } else {
        return new api.StatsApi(getConfig()).aggregateAppointmentsForOrgByCreation(orgId, start, end);
    }
}

export function getLeadAggregation(id?: string): Promise<api.LeadAggregation> {
    if (id) {
        return new api.StatsApi(getConfig()).aggregateLeadsForOrg(id);
    }

    return new api.StatsApi(getConfig()).getLeadsPerDay();
}

export function getLeadAggregationForAccount(): Promise<api.LeadAggregation> {
    return new api.StatsApi(getConfig()).getLeadsPerDay();
}

export function getLeadsPerHourAggregation(id?: string): Promise<api.LeadAggregation> {
    if (id) {
        return new api.StatsApi(getConfig()).aggregateLeadsForOrgByHour(id);
    }

    return new api.StatsApi(getConfig()).getLeadsPerHour();
}

export function getLeadsPerHourAggregationForAccount(id?: string): Promise<api.LeadAggregation> {
    return new api.StatsApi(getConfig()).getLeadsPerHour();
}

export function getLeadEvents(leadId: string): Promise<api.LeadEvent[]> {
    return new api.AuditApi(getConfig()).auditLeadAsymc(leadId);
}

// TODO: ????
export function mapField(field: api.FormField): IField {
    if (!field.name) throw new Error('missing name');
    // if ( !field.type ) throw new Error('missing type');

    return {
        name: field.name as string,
        label: field.label,
        enable: field.enable,
        visible: field.visible,
        type: (field.type || 'text') as string,
        defaultValue: field.defaultValue,
        isRequired: field.isRequired,
    };
}
