import * as React from 'react';
import * as moment from 'moment';

import * as api from '@crochik/schedulerapi.ts';
import DataService from '@crochik/pi-react/services/DataService';
import { Typography } from '@material-ui/core';

interface IProps {
    lead: api.Lead;
}

interface IState {
    events?: api.LeadEvent[];
}

export class History extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
        };
    }

    componentWillMount() {
        this.load();
    }

    async load() {
        const { lead } = this.props;

        var events: api.LeadEvent[] = await DataService().select('LeadEvents', {
            query: {
                id: lead.id,
            }
        });

        this.setState({
            events
        });
    }

    renderEvent(e: api.LeadEvent) {
        return (
            <tr key={e.id} className="LeadHistory_row">
                <td className="LeadHistory_td_center">
                    <Typography color="textPrimary" variant="body2">
                        {moment(e.createdOn).format('L')}<br />
                        {moment(e.createdOn).format('LT')}
                    </Typography>
                </td>
                <td className="LeadHistory_icon">
                    {e.integrationId &&
                        <img width="32" height="32" src={`/icons/${e.integrationId}.png`} />
                    }
                </td>
                <td className="LeadHistory_td_left">
                    <Typography color="textPrimary" variant="body2">{e.message}</Typography>
                    {!!e.entity &&
                        <Typography color="textSecondary" variant="body2">by {e.entity}</Typography>
                    }
                </td>
                <td className="LeadHistory_td_center">
                    {e.appointmentId &&
                        <Typography color="textPrimary" variant="body2">
                            {moment(e.appointmentStart).format('L')}<br />
                            {`${moment(e.appointmentStart).format('LT')} - ${moment(e.appointmentEnd).format('LT')}`}
                        </Typography>
                    }
                </td>
            </tr>
        );
    }

    render() {
        const { events } = this.state;

        if (!events) {
            return (
                <div className="LeadHistory_body">Loading...</div>
            );
        }

        return (
            <div className="LeadHistory_body">
                <table>
                    <tbody>
                        {events.map(e => this.renderEvent(e))}
                    </tbody>
                </table>
            </div>
        );
    }
}
