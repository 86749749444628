import * as React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';

export interface MenuOption<T> {
    name: string;
    value?: T;
}

interface IProps<T> {
    options: MenuOption<T>[];
    onClick(item: MenuOption<T>);
}

interface IState {
    open: boolean;
}

export class MoreMenu<T> extends React.Component<IProps<T>, IState> {
    anchor: any;

    constructor(props: IProps<T>) {
        super(props);

        this.state = {
            open: false,
        };
    }

    onOpen = (open: boolean) => () => {
        this.setState({
            open
        });
    }

    onClick = (item: MenuOption<T>) => () => {
        const { onClick } = this.props;
        if (onClick) onClick(item);
        this.setState({
            open: false
        });
    }

    setAnchor = (e: any) => {
        this.anchor = e;
    }

    render() {
        const { open } = this.state;
        const { options } = this.props;

        return (
            <div ref={this.setAnchor}>
                <IconButton
                    onClick={this.onOpen(true)}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    keepMounted={true}
                    open={open}
                    anchorEl={this.anchor}
                    onClose={this.onOpen(false)}
                    PaperProps={{
                        style: {
                            maxHeight: 200,
                            width: 200,
                        },
                    }}
                >
                    {options.map(option => (
                        <MenuItem key={option.name} onClick={this.onClick(option)} selected={false}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    }
}