import * as React from 'react';
import { observer } from 'mobx-react';

import DataService from '@crochik/pi-react/services/DataService';
import * as api from '@crochik/schedulerapi.ts';

import { CustomPage } from '@crochik/pi-react/application/Page';

import './ContentPage.css';
import { VerticalForm } from '@crochik/pi-react/ui/material/VerticalForm';

import { initWorkingHours } from '../actions/Settings';
import { Grid } from '@material-ui/core';
import { Form } from '@crochik/pi-react/ui/Form';

interface Props {
}

interface State {
    enableWorkingHours: boolean;
    enableAppointmentType: boolean;
}

@observer
class PageComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            enableWorkingHours: false,
            enableAppointmentType: false
        };
    }

    async componentWillMount() {
        var enableWorkingHours = false;
        var enableAppointmentType = false;
        var timezone;

        var settings: api.Setting[] = await DataService().select('Settings');
        for (var setting of settings) {
            switch (setting.id) {
                case 'WorkingHours':
                    initWorkingHours(setting.value);
                    enableWorkingHours = true;
                    break;

                case 'TimeZone':
                    timezone = setting.value;
                    break;

                default:
                    break;
            }
        }

        var types = await DataService().select('AppointmentTypes') as api.AppointmentType[];
        for (var type of types) {
            if (type.leadTypeId && type.id) {
                // find first with scheduler enabled
                var id = type.id;
                // read again individually to get the setting
                var appointmentType = await DataService().one('AppointmentType', { args: [null, id] });
                var schedulerUrl = `${process.env.REACT_APP_scheduler_url}/nav.html?apptType=${id}`;
                // update form
                var form = Form.get('BasicAppointmentTypeSettings');
                form.assignValues(appointmentType);
                form.assignValues({ schedulerUrl });
                if (appointmentType.settings) {
                    form.assignValues(appointmentType.settings);
                }
                enableAppointmentType = true;
                break;
            }
        }

        // time zone
        Form.get('WorkingHours').assignValues({timezone});

        this.setState({
            enableWorkingHours,
            enableAppointmentType
        });
    }

    render() {
        return (
            <div className="ContentPage_scrollable">
                <Grid container={true} spacing={16}>
                    <Grid item={true} sm={6} xs={12}>
                        {this.state.enableAppointmentType && <VerticalForm form="BasicAppointmentTypeSettings" actionProps={{ color: 'primary' }} />}
                    </Grid>
                    <Grid item={true} sm={6} xs={12}>
                        {this.state.enableWorkingHours && <VerticalForm form="WorkingHours" actionProps={{ color: 'primary' }} />}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <PageComponent />);