import * as api from '@crochik/schedulerapi.ts';
import { CachedApiModel, Dict } from './api';

export const ANY = '[any]';

type Entity = { name?: string };

export function sort(l: Entity, r: Entity) {
    if (!l.name) {
        return !r.name ? 0 : -1;
    }
    
    return (!r.name) ? 1 : l.name.localeCompare(r.name);
}

export interface UpdateStepResult {
    step?: api.FlowStep;
    error?: string;
}

class LeadStatusClient extends CachedApiModel<api.LeadStatusApi, api.LeadStatus> {
    protected get api() {
        return new api.LeadStatusApi(this.getConfig());
    }

    protected async loadAll(): Promise<api.LeadStatus[]> {
        var list = await this.api.get();
        list.sort(sort);
        list.splice(0, 0, {
            name: '[ANY]'
        });

        return list;
    }
}

class EventTypeClient extends CachedApiModel<api.EventTypeApi, api.EventType> {
    protected get api() {
        return new api.EventTypeApi(this.getConfig());
    }

    loadAll(): Promise<api.EventType[]> {
        return this.api.get();
    }

    add(evt: api.EventType): Promise<api.EventType> {
        return this.api.create(evt);
    }
}

class FlowActionClient extends CachedApiModel<api.FlowActionsApi, api.FlowAction> {
    protected get api() {
        return new api.FlowActionsApi(this.getConfig());
    }

    loadAll(): Promise<api.FlowAction[]> {
        return this.api.get();
    }

    getForTrigger(eventTypeId: string): Promise<api.FlowAction[]> {
        return this.api.getActionsForEventType(eventTypeId);
    }

    getForm(id: string, triggerEventId: string): Promise<api.Form> {
        return this.api.getForm(id, triggerEventId);
    }
}

export const leadStatus = new LeadStatusClient();
export const eventType = new EventTypeClient();
export const flowAction = new FlowActionClient();

export class FlowSteps {
    flowId: string;

    flowStepsList: api.FlowStep[];
    leadStatusList: api.LeadStatus[];
    eventTypeDict: Dict<api.EventType>;
    flowActionDict: Dict<api.FlowAction>;

    constructor(flowId: string) {
        this.flowId = flowId;
    }
}

class FlowClient extends CachedApiModel<api.FlowApi, api.Flow> {
    protected get api() {
        return new api.FlowApi(this.getConfig());
    }

    protected loadAll(): Promise<api.Flow[]> {
        return this.api.get();
    }

    public async getSteps(id: string): Promise<FlowSteps> {
        var [flowStepsList, leadStatusList, eventTypeDict, flowActionDict] = await Promise.all([
            this.api.getEvents(id),
            leadStatus.all(),
            eventType.get(),
            flowAction.get()
        ]);

        const result = new FlowSteps(id);
        result.flowStepsList = flowStepsList;
        result.leadStatusList = leadStatusList;
        result.eventTypeDict = eventTypeDict;
        result.flowActionDict = flowActionDict;

        return result;
    }

    public async create(entityId: string, name: string, description?: string): Promise<api.Flow> {
        return this.api.create({
            entityId,
            name,
            description
        });
    }

    public async add(flowId: string, evt: api.FlowStepUpdate): Promise<UpdateStepResult> {
        try {
            var step = await this.api.addEvent(flowId, evt);
            return { step };

        } catch (ex) {
            var response = ex as Response;
            if (response.status === 400) {
                return {
                    error: await response.text()
                };
            }

            return {
                error: response.statusText
            };
        }
    }

    public async update(flowId: string, evt: api.FlowStep): Promise<UpdateStepResult> {
        try {
            var step = await this.api.updateStep(flowId, evt.id as string, evt);
            return { step };

        } catch (ex) {
            var response = ex as Response;
            if (response.status === 400) {
                return {
                    error: await response.text()
                };
            }

            return {
                error: response.statusText
            };
        }
    }

    public async delete(flowId: string, stepId: string): Promise<api.FlowStep> {
        return await this.api.deleteStep(flowId, stepId);
    }
}

export const flow = new FlowClient();

export const ChangeStatusActionId = 'eded7e06-8bba-4968-9e5e-284702c5a099';
export const LeadCreatedEventId = 'ed51c9ce-945d-47cb-af2f-6d4535f7ae11';
export const InitialLeadStatusId = '1d0d0bf0-4c72-4b79-8d68-bd11802df9ec';