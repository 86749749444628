import * as React from 'react';
import { FieldMapperConfig } from '@crochik/schedulerapi.ts';

import './Fields.css';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

interface Props {
    fields: FieldMapperConfig[];
    prefix?: string;
    hideSource?: boolean;
    editable: boolean;
}

interface State {
    field?: FieldMapperConfig;
    editing?: string;
    autoSelect: boolean;
}

export function sortFields(a: FieldMapperConfig, b: FieldMapperConfig): number {
    if (a.source === b.source) {
        return 0;

    } else if (!a.source) {
        return 1;

    } else if (!b.source) {
        return -1;

    } else {
        return a.source < b.source ? -1 : 1;
    }
}

@observer
export class Fields extends React.Component<Props> {
    currentNode: string[];
    boundState: State;
    fieldTypes: string[];

    constructor(props: Props) {
        super(props);

        this.boundState = observable({
            field: undefined,
            editing: undefined,
            autoSelect: true
        });

        var types = [
            // Undefined,
            'Text',
            'Email',
            'Phone',
            // Password,
            'address',
            'Postalcode',
            'Time',
            'Date',
            'Datetime',
            'Number',
            'Hidden',
            'Boolean'
        ];

        this.fieldTypes = types.sort();
    }

    renderPath(name: string | undefined): JSX.Element[] {
        if (!name) {
            this.currentNode = [];
            return [];
        }

        var parts = name.split('.');
        var start: number;
        for (start = 0; start < parts.length - 1 && start < this.currentNode.length - 1; start++) {
            if (parts[start] !== this.currentNode[start]) break;
        }

        var node: JSX.Element[] = [];
        for (var c = start; c < parts.length - 1; c++) {
            node.push((
                <tr key={`${name}_${parts[c]}`} className="Fields_section"><td style={{ paddingLeft: 4 + c * 20 }} colSpan={6}>{parts[c]}</td></tr>
            ));
        }

        this.currentNode = parts;

        return node;
    }

    renderSource(name: string | undefined) {
        if (!name) return <td>Error</td>;
        var parts = name.split('.');
        return <td style={{ paddingLeft: 4 + (parts.length - 1) * 20 }}>{parts[parts.length - 1]}</td>;
    }

    camelize(str: string) {
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match: string, index: number) {
            if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
    }

    @action
    onClickEditable = (e: React.MouseEvent, field: FieldMapperConfig, column: string) => {
        if (this.props.hideSource || !field.source) return;
        
        e.stopPropagation();

        switch (column) {
            case 'name':
                if (!field.name) {
                    var name = field.source;
                    name = name.replace(/[^a-zA-Z0-9]/g, ' ');
                    name = this.camelize(name);
                    field.name = name;
                }
                break;

            case 'label':
                if (!field.label) {
                    field.label = field.name;
                }
                break;

            default:
                break;
        }

        Object.assign(this.boundState, {
            field,
            editing: column,
            autoSelect: true
        });
    }

    renderEditable(field: FieldMapperConfig, column: string) {
        if (!this.props.editable) {
            return <td key={field[column]}>{field[column]}</td>;
        }

        if (field === this.boundState.field && this.boundState.editing === column) {
            return (
                <td className="Field_editable">
                    <input
                        ref={(e) => this.boundState.autoSelect && e && e.select()}
                        onBlur={this.cancelEdit}
                        type="text"
                        value={field[column] || ''}
                        onChange={(evt) => {
                            this.boundState.autoSelect = false;
                            field[column] = evt.target.value;
                        }}
                    />
                </td>
            );
        }

        return (
            <td className="Field_editable" onClick={(e) => this.onClickEditable(e, field, column)}>{field[column]}</td>
        );
    }

    @action
    toggle(field: FieldMapperConfig, column: string) {
        console.log(`toggle ${column}: ${field[column]}`);
        field[column] = field[column] ? false : true;
    }

    renderCheckbox(field: FieldMapperConfig, column: string) {
        if (!this.props.editable) {
            return <td key={field[column]}>{field[column] ? '*' : ''}</td>;
        }

        return (
            <td
                className="Field_checkbox"
                onDoubleClick={() => this.toggle(field, column)}
            >
                {field[column] ? '*' : ''}
            </td>
        );
    }

    renderType(field: FieldMapperConfig) {
        if (!this.props.editable) {
            return <td key={field.type}>{field.type}</td>;
        }

        const column = 'type';
        if (field === this.boundState.field && this.boundState.editing === column) {
            return (
                <td className="Field_editable">
                    <select
                        onChange={(e) => {
                            console.log(e.target.value);
                            field.type = e.target.value as any as FieldMapperConfig.TypeEnum;
                        }}
                        value={field.type}
                        onBlur={this.cancelEdit}
                    >
                        {this.fieldTypes.map(type =>
                            <option key={type} value={type.toLocaleLowerCase()}>{type}</option>
                        )}
                    </select>
                </td>
            );
        }

        return (
            <td
                className="Field_editable"
                onClick={(e) => this.onClickEditable(e, field, column)}
            >
                {field.type}
            </td>
        );
    }

    renderFields() {
        this.currentNode = [];

        return this.props.fields.map((field) => {
            var node: JSX.Element[] = [];
            let source = field.source || `${this.props.prefix}${field.name}`;
            if (!this.props.hideSource) node.push(...this.renderPath(source));
            node.push(
                <tr key={field.source || field.name} className="Fields_field">
                    {!this.props.hideSource && this.renderSource(source)}
                    {this.renderEditable(field, 'name')}
                    {this.renderEditable(field, 'label')}
                    {this.renderType(field)}
                    {this.renderCheckbox(field, 'isRequired')}
                    {this.renderEditable(field, 'defaultValue')}
                    {/* <td>{field.visible && <pre>{JSON.stringify(field.visible, null, 2)}</pre>}</td> */}
                </tr>
            );
            return node;
        });
    }

    cancelEdit = () => {
        Object.assign(this.boundState, {
            field: undefined,
            editing: undefined
        });
    }
    render() {
        return (
            <div className="Fields" onClick={this.cancelEdit}>
                <table >
                    <tbody>
                        <tr className="header">
                            {!this.props.hideSource && <td>Source</td>}
                            <td>Target</td>
                            <td>Label</td>
                            <td>Type</td>
                            <td>Required</td>
                            <td>Default</td>
                            {/* <td>Enable</td> */}
                            {/* <td>Visible</td> */}
                        </tr>
                        {this.renderFields()}
                    </tbody>
                </table>
            </div>
        );
    }
}