import App from '@crochik/pi-react/application/App';
import { runInAction } from 'mobx';

function report(report: string) {
    runInAction(() => {
        App().selectPage(`Report=${report}`, report, { id: report });
    });

    // App().navigate({
    //     page: 'Report',
    //     path: `Report=${report}`,
    //     args: [
    //         report,
    //         {
    //             id: report
    //         }
    //     ]
    // });
}

export function appointmentsByTool() {
    report('appointmentsByTool');
}

export function leadSources() {
    report('leadSources');
}

export function leadSourcesByDate() {
    report('leadSourcesByDate');
}