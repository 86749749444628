import * as React from 'react';

import { CustomPage } from '@crochik/pi-react/application/Page';
import Dialog from '@crochik/pi-react/services/Dialog';

import Card from '@material-ui/core/Card';
import ActionButton from '@crochik/pi-react/ui/material/ActionButton';

import { Typography, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { Default } from '@crochik/pi-react/context';
import { ISession } from '../Session';
import { observer } from 'mobx-react';
import * as API from '../services/API';
import { AppointmentType, UserAvailability, Identity } from '@crochik/schedulerapi.ts';
import { VerticalForm } from '@crochik/pi-react/ui/material/VerticalForm';

import './ContentPage.css';
import './InitialWizard.css';
import { Form } from '@crochik/pi-react/ui/Form';

import { goHomePage } from '..';
import LoginService from '../services/Login';

interface State {
    message?: string;

    loadingTimezone: boolean;
    loadingAppointmentType: boolean;
    loadingAvailability: boolean;
    timeZone?: string;
    appointmentTypes?: AppointmentType[];
    hasAvailability?: boolean;
    addingTimezone: boolean;
    addingAppointmentType: boolean;
    addingAvailability: boolean;
    enablingSync: boolean;
    syncEnabled: boolean;
    microsoftAccount?: Identity;
}

interface IAvailability {
    monStart: string;
    monEnd: string;
    satStart: string;
    satEnd: string;
    sunStart: string;
    sunEnd: string;
    lunchStart: string;
    lunchEnd: string;
}

@observer
class InitialWizard extends React.Component<{}, State> {
    session: ISession;

    constructor(props: any) {
        super(props);

        this.session = Default.state.get('Session');

        this.state = {
            message: undefined,
            loadingTimezone: true,
            loadingAppointmentType: true,
            loadingAvailability: true,
            addingTimezone: false,
            addingAppointmentType: false,
            addingAvailability: false,
            microsoftAccount: undefined,
            enablingSync: false,
            syncEnabled: this.session.isSyncEnabled ? true : false
        };
    }

    async componentDidMount() {
        this.loadTimezone();
        this.loadAppointmentType();
        this.loadAvailability();
        this.loadMicrosoftAccount();

        // if ( this.session.hasMicrosoftAccount && !this.session.isSyncEnabled ) {
        //     this.autoEnableSync();
        // }

        Form.bindAction('AddAppointmentTypeWizard', 'next', this.addAppointmentType);
        Form.bindAction('AddAppointmentTypeWizard', 'linkGTM', this.addApptAndGTMAccount);
        Form.bindAction('AddAppointmentTypeWizard', 'linkZoom', this.addApptAndZoomAccount);

        Form.bindAction('WizardAvailability', 'setAvailability', this.saveAvailability);
    }

    async loadMicrosoftAccount() {
        var accounts = await API.getAccounts('Microsoft');

        this.setState({
            microsoftAccount: accounts && accounts.length === 1 ? accounts[0] : undefined
        });
    }

    async loadAppointmentType() {
        var result = await API.getAppointmentTypes();
        var inspirenet = await API.hasInspireNetAccount();

        if (result.length === 0) {
            if (inspirenet) {
                this.autoAddAppointmentType();
            } else {
                this.setState({
                    loadingAppointmentType: false
                });
            }
            return;
        }

        if (result.length === 1 && !inspirenet) {
            await this.autoConfigureAppointmentType(result[0]);
        }

        this.setState({
            loadingAppointmentType: false,
            appointmentTypes: result
        });
    }

    async autoConfigureAppointmentType(apptType: AppointmentType) {
        if (!apptType || !apptType.id) return;
        var id: string = apptType.id as string;

        // check integration
        var integrations = await API.getAppointmentTypeIntegrations(id);
        if (integrations.length > 0) return;

        console.log('configure integrations for appointment type');

        this.setState({
            loadingAppointmentType: false,
            addingAppointmentType: true,
        });

        // sendgrid
        await API.enableSendGridOnOrg({
            apiKey: 'PI',
            fromName: 'ProgramInteface.com',
            fromEmail: 'noreply@programinterface.com'
        });

        // zoom
        var zoomAccount = await API.hasZoomAccount();
        if (zoomAccount) {
            console.log('configure zoom integration');
            await API.enableZoomOnOrg();
            await API.enableZoomOnAppointmentType({ id });

            await API.enableSendGridOnAppointmentType({
                id,
                requiredIntegrationId: 'c0bb2cfb-644a-4b53-9cfd-144a63c89360',
                addedTemplateId: 'd-3d46548d544046f0b12d0e876aac778b',
                cancelledTemplateId: 'd-2abd7828ad1c4e49a9d3af9d166f6503'
            });
        }

        // gtm
        // ...

        this.setState({
            loadingAppointmentType: false,
            addingAppointmentType: false,
            appointmentTypes: [apptType]
        });
    }

    addApptAndZoomAccount = async () => {
        await this.createAppointmentType();
        Default.actions.execute('linkZoomAccount', 'app');
    }

    addApptAndGTMAccount = async () => {
        await this.createAppointmentType();
        Default.actions.execute('linkGTMAccount', 'app');
    }

    addAppointmentType = async () => {
        var appt = await this.createAppointmentType();
        this.setState({
            addingAppointmentType: false,
            appointmentTypes: [appt]
        });
    }

    async createAppointmentType(): Promise<AppointmentType> {
        this.setState({
            loadingAppointmentType: false,
            addingAppointmentType: true,
            appointmentTypes: undefined
        });

        var settings = Form.get('AddAppointmentTypeWizard').values;
        console.log(`Name: ${settings['type']}`);
        var title: string;
        switch (settings['type']) {
            case 'online': title = 'Online Meeting'; break;
            case 'offline': title = 'Customer Address Meeting'; break;
            case 'office': title = 'Office Meeting'; break;

            default:
                title = settings['type'];
                break;
        }

        var appt = await API.addAppointmentType(title);
        if (!appt.id) throw new Error('Failed to create appointment');

        appt = await API.getAppointmentType(appt.id);
        if (!appt.id || !appt.settings) throw new Error('Failed to load default settings for appt');

        appt.settings.duration = settings['duration'];
        appt = await API.setAppointmentTypeSettings(appt.id, appt.settings);

        return appt;
    }

    autoAddAppointmentType() {
        this.setState({
            loadingAppointmentType: false,
            addingAppointmentType: true,
            appointmentTypes: undefined
        });

        API.addAppointmentType('First Appointment')
            .then((appt) => {
                this.setState({
                    addingAppointmentType: false,
                    appointmentTypes: [appt]
                });
            })
            .catch((ex) => console.error('failed to add appointment type'));
    }

    loadTimezone() {
        API.getSettings()
            .then(settings => {
                for (var setting of settings) {
                    if (setting.id === 'TimeZone' && (setting.orgValue || setting.userValue)) {
                        this.setState({
                            loadingTimezone: false,
                            timeZone: setting.userValue || setting.orgValue
                        });
                        return;
                    }
                }

                this.setTimezone();
                // this.setState({ loadingTimezone: false });
            })
            .catch(ex =>
                console.error(ex)
            );
    }

    loadAvailability() {
        // DataService().select('Availability');
        API.getAvailability()
            .then(res => {
                this.setState({
                    loadingAvailability: false,
                    hasAvailability: res.length > 0
                });
            })
            .catch(ex => console.error('failed to check availability'));

    }

    stringToMinutes(str: string): number {
        if (!str || str.length < 1) {
            return -1;
        }

        var hours = parseInt(str.substr(0, 2), 10);
        var min = parseInt(str.substr(3, 2), 10);
        return hours * 60 + min;
    }

    calcSlots(start: string, end: string, lunchStart: string, lunchEnd: string): { start: number, end: number }[] {
        if (!start || !end) {
            return [];
        }

        var minStart = this.stringToMinutes(start);
        var minEnd = this.stringToMinutes(end);
        if (minStart < 0 || minEnd < 0 || minEnd <= minStart) {
            return [];
        }

        if (lunchStart && lunchEnd) {
            var minLunchStart = this.stringToMinutes(lunchStart);
            var minLunchEnd = this.stringToMinutes(lunchEnd);

            if (minLunchStart >= 0 && minLunchEnd >= minLunchStart) {
                if (minLunchStart < minEnd && minLunchEnd > minStart) {
                    if (minLunchStart > minStart) {
                        if (minLunchEnd >= minEnd) {
                            return [{ start: minStart, end: minLunchStart }];
                        } else {
                            return [
                                { start: minStart, end: minLunchStart },
                                { start: minLunchEnd, end: minEnd }
                            ];
                        }
                    } else if (minLunchEnd < minEnd) {
                        return [{ start: minLunchEnd, end: minEnd }];
                    }
                }
            }
        }

        return [{ start: minStart, end: minEnd }];
    }

    saveAvailability = async () => {
        this.setState({
            addingAvailability: true
        });

        var form = Form.get('WizardAvailability').values as IAvailability;

        if (!this.state.appointmentTypes || this.state.appointmentTypes.length < 1) {
            console.error('Can\'t set availability, no appointment types defined');
            return;
        }

        var appointmentTypeIds: string[] = [];
        for (var appt of this.state.appointmentTypes) {
            if (!appt.id) continue;
            appointmentTypeIds.push(appt.id);
        }

        var avail: UserAvailability;
        var slot: { start: number, end: number };
        var availability: UserAvailability[] = [];
        var mon = this.calcSlots(form.monStart, form.monEnd, form.lunchStart, form.lunchEnd);
        for (var dayId = 1; dayId < 6; dayId++) {
            for (slot of mon) {
                avail = {
                    dayId,
                    startMinutes: slot.start,
                    durationMinutes: slot.end - slot.start,
                    appointmentTypeIds
                };
                availability.push(avail);
            }
        }

        var sat = this.calcSlots(form.satStart, form.satEnd, form.lunchStart, form.lunchEnd);
        for (slot of sat) {
            avail = {
                dayId: 6,
                startMinutes: slot.start,
                durationMinutes: slot.end - slot.start,
                appointmentTypeIds
            };
            availability.push(avail);
        }

        var sun = this.calcSlots(form.sunStart, form.sunEnd, form.lunchStart, form.lunchEnd);
        for (slot of sun) {
            avail = {
                dayId: 0,
                startMinutes: slot.start,
                durationMinutes: slot.end - slot.start,
                appointmentTypeIds
            };
            availability.push(avail);
        }

        API.saveAvailability(availability)
            .then(res => {
                this.setState({
                    addingAvailability: false,
                    hasAvailability: true
                });
            })
            .catch(ex => console.error('failed to save availability'));
    }

    autoEnableSync = () => {
        this.setState({
            enablingSync: true
        });

        API.enbleCalendarAsync()
            .then(res => {
                if (!res) {
                    this.failedToEnableSync();
                    return;
                }

                console.log('sync enabled');
                this.setState({
                    enablingSync: false,
                    syncEnabled: true
                });

                this.session.isSyncEnabled = true;
                this.done();
            })
            .catch(ex => {
                console.error('failed to enable sync');
                this.failedToEnableSync();
            });
    }

    failedToEnableSync = () => {
        Dialog.confirm({
            title: 'Failed To Enable Sync',
            message: 'You may need to login again with your Office365 account to allow access to your calendar. Continue?',
            positiveCaption: 'Yes',
            negativeCaption: 'Later',
            onPositive: () => {
                console.log('logout');
                LoginService().signOut();
            },
            onNegative: () => {
                this.done();
            }
        });
    }

    setTimezone = async () => {
        this.setState({
            loadingTimezone: false,
            addingTimezone: true
        });

        var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log(`set time zone to ${tz}`);

        try {
            var resp = await API.setTimeZone(tz);

            this.setState({
                addingTimezone: false,
                timeZone: resp.id
            });
        } catch (ex) {
            console.error('Error setting timezone');
        }
    }

    renderTimeZone(ret: React.ReactNode[]) {

        if (this.state.loadingTimezone) {
            ret.push(
                <Card className="ContentPage_card">
                    <CardHeader title="Locale" />
                    <CardContent>
                        <Typography style={{ color: 'orange' }} variant="subtitle1">Checking status...</Typography>
                    </CardContent>
                </Card>
            );
            return;
        }

        if (this.state.addingTimezone) {
            ret.push(
                <Card className="ContentPage_card">
                    <CardHeader title="Locale" />
                    <CardContent>
                        <Typography style={{ color: 'black' }} variant="subtitle1">Saving...</Typography>
                    </CardContent>
                </Card>
            );
            return;
        }

        var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (this.state.timeZone) {
            // ret.push(<Typography style={{ color: 'green' }} variant="subheading">Locale: {this.state.timeZone}</Typography>);
            // ret.push(<br />);
            this.renderAppointmentType(ret);
            return;
        }

        ret.push(
            <Card className="ContentPage_card">
                <CardHeader title="Locale" />
                <CardContent>{`Set Locale to ${tz}`}</CardContent>
                <CardActions>
                    <ActionButton
                        title={`Set Locale to ${tz}`}
                        action={this.setTimezone}
                        variant="contained"
                        color="primary"
                    />
                </CardActions>
            </Card>
        );
    }

    renderAvailability(ret: React.ReactNode[]) {
        if (this.state.loadingAvailability) {
            ret.push(
                <Card className="ContentPage_card">
                    <CardHeader title="Availability" />
                    <CardContent>
                        <Typography style={{ color: 'orange' }} variant="subtitle1">Checking status...</Typography>
                    </CardContent>
                </Card>
            );
            return;
        }

        if (this.state.addingAvailability) {
            ret.push(
                <Card className="ContentPage_card">
                    <CardHeader title="Availability" />
                    <CardContent>
                        <Typography style={{ color: 'black' }} variant="subtitle1">Saving...</Typography>
                    </CardContent>
                </Card>
            );
            return;
        }

        if (this.state.hasAvailability) {
            // ret.push(<Typography style={{ color: 'green' }} variant="subheading">Availability: OK</Typography>);
            // ret.push(<br />);
            this.renderAddAccount(ret);
            return;
        }

        ret.push(<VerticalForm form="WizardAvailability" actionProps={{ variant: 'contained', color: 'primary' }} />);
        ret.push(<Typography style={{ color: 'black' }} variant="body2">(*) Set your general availability. You will have the ability to fine tune it later.</Typography>);
    }

    renderAppointmentType(ret: React.ReactNode[]) {
        if (this.state.loadingAppointmentType) {
            ret.push(
                <Card className="ContentPage_card">
                    <CardHeader title="Appointment Type" />
                    <CardContent>
                        <Typography style={{ color: 'orange' }} variant="subtitle1">Checking status...</Typography>
                    </CardContent>
                </Card>
            );
            return;
        }

        if (this.state.addingAppointmentType) {
            ret.push(
                <Card className="ContentPage_card">
                    <CardHeader title="Appointment Type" />
                    <CardContent>
                        <Typography style={{ color: 'black' }} variant="subtitle1">Creating...</Typography>
                    </CardContent>
                </Card>
            );
            return;
        }

        if (this.state.appointmentTypes) {
            // var msg = this.state.appointmentTypes.length === 1 ? this.state.appointmentTypes[0].name : `${this.state.appointmentTypes.length} defined`;
            // ret.push(<Typography style={{ color: 'green' }} variant="subheading">Appointment Type: {msg}</Typography>);
            // ret.push(<br />);
            this.renderAvailability(ret);
            return;
        }

        ret.push(<VerticalForm form="AddAppointmentTypeWizard" actionProps={{ color: 'primary', variant: 'contained' }} />);
    }

    renderAddAccount(ret: React.ReactNode[]) {
        if (this.session.hasMicrosoftAccount) {
            // ret.push(<Typography style={{ color: 'green' }} variant="subheading">Microsoft Office 365: OK</Typography>);
            // ret.push(<br />);
            this.renderCalendarSync(ret);

        } else {
            ret.push(
                <Card className="ContentPage_card">
                    <CardHeader title="Office 365 Account" />
                    <CardContent>
                        Link your Office 365 Account so we can keep your calendar in sync and avoid double booking.
                    </CardContent>
                    <CardActions>
                        <div className="InitialWizard_footer">
                            <ActionButton
                                title="Link Office 365 Account"
                                action="linkMicrosoftAccount"
                                context="app"
                                variant="contained"
                                color="primary"
                            />
                            <ActionButton
                                title="Skip"
                                action={this.done}
                                variant="contained"
                                color="primary"
                            />
                        </div>
                    </CardActions>
                </Card>
            );
        }
    }

    done = () => {
        goHomePage();
    }

    renderCalendarSync(ret: React.ReactNode[]) {
        if (this.state.syncEnabled) {
            // ret.push(<Typography style={{ color: 'green' }} variant="subheading">Calendar Sync: Enabled</Typography>);
            // ret.push(<br/>);
            ret.push(
                <Card className="ContentPage_card">
                    <CardHeader title="Setup Complete" />
                    <CardContent>
                        <Typography style={{ color: 'black' }} variant="subtitle1">All ready to go...</Typography>
                    </CardContent>
                    <CardActions>
                        <ActionButton
                            title="Continue"
                            action={this.done}
                            variant="contained"
                            color="primary"
                        />
                    </CardActions>
                </Card>
            );
            return;
        }

        if (this.state.enablingSync) {
            ret.push(
                <Card className="ContentPage_card">
                    <CardHeader title="Calendar Sync" />
                    <CardContent>
                        <Typography style={{ color: 'black' }} variant="subtitle1">Enabling...</Typography>
                    </CardContent>
                </Card>
            );
            return;
        }

        ret.push(
            <Card className="ContentPage_card">
                <CardHeader title="Calendar Sync" />
                <CardContent>
                    <Typography style={{ color: 'black' }} variant="body2">
                        Enabling sync with your Microsoft Office 365 calendar will prevent double booking
                    and automatically add any scheduled appoinments directly to it. <br />
                    </Typography>
                </CardContent>
                <CardActions>
                    <div className="InitialWizard_footer">
                        <ActionButton
                            title="Enable"
                            action={this.autoEnableSync}
                            variant="contained"
                            color="primary"
                            disabled={this.session.isSyncEnabled || !this.session.hasMicrosoftAccount}
                        />
                        <ActionButton
                            title="Skip"
                            action={this.done}
                            variant="contained"
                            color="primary"
                        />
                    </div>
                </CardActions>
            </Card>
        );
    }

    render() {
        // if (!this.state.message) {
        //     return (
        //         <></>
        //     );
        // }

        // var html = {
        //     __html: this.state.message
        // };

        var ret: React.ReactNode[] = [];
        this.renderTimeZone(ret);

        return (
            <div className="ContentPage">
                <div className="ContentPage_body">
                    {/* <Card className="ContentPage_card"> */}
                    {/* <div dangerouslySetInnerHTML={html} /> */}
                    {/* <Typography color="primary" variant="title">Initial Setup</Typography><br /> */}

                    {ret}
                    {/* </Card> */}
                </div >
            </div>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <InitialWizard />);