import { observable } from 'mobx';
import { Default } from '@crochik/pi-react/context';
import * as API from './services/API';
import { User, WorkingHoursSetting } from '@crochik/schedulerapi.ts';
import DialogService from '@crochik/pi-react/services/Dialog';

interface ILaunchUrl {
    path: string;
    search?: string;
    searchParams?: { [name: string]: string };
    referrer?: string;
}

export interface ISession {
    launchUrl: ILaunchUrl;
    isSyncEnabled?: boolean;
    hasMicrosoftAccount?: boolean;
    user?: User;
    workingHours?: WorkingHoursSetting;
    flavor?: string;
    accessToken?: string;
}

var manager: Session | undefined = undefined;

export class Session implements ISession {
    state: ISession;

    static get(): ISession {
        return Default.state.get('Session') as ISession;
    }

    static manager(): Session {
        if (!manager) throw new Error('Session manager has not been initialized');
        return manager;
    }

    get launchUrl(): ILaunchUrl { return this.state.launchUrl; }
    get isSyncEnabled(): boolean { return this.state.isSyncEnabled || false; }
    get hasMicrosoftAccount(): boolean { return this.state.hasMicrosoftAccount || false; }

    constructor(flavor?: string) {
        if (manager) throw new Error('Session manager has already been initialized');
        manager = this;

        this.state = observable({
            launchUrl: this.parseLocation(),
            isSyncEnabled: false,
            hasMicrosoftAccount: false,
            user: undefined,
            workingHours: undefined,
            accessToken: undefined,
            flavor
        });

        Default.state.set('Session', this.state);
    }

    async updateStatus(accessToken: string) {
        var user: User;

        try {
            this.state.accessToken = accessToken;
            user = await API.me();

        } catch (ex) {
            console.error(ex);

            this.state.accessToken = undefined;
            this.state.user = undefined;

            DialogService.critical({
                title: 'User Error',
                message: 'Failed to load user info'
            });
            return;
        }

        API.getSettings()
            .then((settings) => {
                for (var setting of settings) {
                    switch (setting.id) {
                        case 'WorkingHours':
                            console.log(`working hours: ${setting.value}`);
                            if (setting.value) this.state.workingHours = JSON.parse(setting.value);
                            break;

                        default:
                            break;
                    }
                }
            })
            .catch(ex => {
                console.error('failed to load settings for the user');
            });

        try {
            this.state.isSyncEnabled = await API.isCalendarSyncEnabled();
            if (this.state.isSyncEnabled) {
                this.state.hasMicrosoftAccount = true;
                this.state.user = user;
                return;
            }

        } catch (ex) {
            this.state.isSyncEnabled = false;
            console.error(`Failed to check whether calendar is enabled or not: ${ex.status}`);
        }

        this.state.hasMicrosoftAccount = await API.hasMicrosoftAccount();
        this.state.user = user;
    }

    private nullIfEmpty(value?: string) {
        return value && value.length > 0 ? value : undefined;
    }

    private parseLocation(): ILaunchUrl {
        var search = location.search;
        var searchParams: { [name: string]: string } | undefined = undefined;
        if (search && search.startsWith('?')) {
            var params = search.substring(1).split('&');
            // tslint:disable-next-line:forin
            for (var param in params) {
                var tokens = params[param].split('=');
                if (tokens.length === 2) {
                    if (!searchParams) {
                        searchParams = {};
                    }
                    searchParams[tokens[0]] = decodeURIComponent(tokens[1]);
                }
            }
        }

        var requestStr = sessionStorage.getItem('request');
        if (requestStr) {
            var launchUrl = JSON.parse(requestStr) as ILaunchUrl;
            console.log(`found on storage: ${requestStr}`);
            launchUrl.searchParams = {
                ...launchUrl.searchParams,
                ...searchParams
            };
            if (launchUrl.searchParams && Object.keys(launchUrl.searchParams).length < 1) {
                launchUrl.searchParams = undefined;
            }
            if (!launchUrl.search) {
                launchUrl.search = this.nullIfEmpty(location.search);
            }
            return launchUrl;
        }

        var req: ILaunchUrl = {
            search: this.nullIfEmpty(location.search),
            path: location.pathname,
            searchParams: searchParams,
            referrer: this.nullIfEmpty(document.referrer)
        };

        var request = JSON.stringify(req);
        sessionStorage.setItem('request', request);

        return req;
    }
}