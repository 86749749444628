import * as React from 'react';
import * as chartjs from 'chart.js';
import * as Chart from '../BarChart';

import moment = require('moment');
import { AppointmentAggregation } from '@crochik/schedulerapi.ts';

export enum Report {
    ByTool,
    Active,
    ActiveByTool
}

interface Props {
    report: Report;
    appointments?: AppointmentAggregation;
    onClick?: () => any;
    isSelected?: boolean;
    title: string;
}

interface State {
    data?: chartjs.ChartData;
}

export class Appointments extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = this.calculateState(props);
    }

    componentWillReceiveProps(props: Props) {
        const state = this.calculateState(props);
        this.setState(state);
    }

    calculateState(props: Props): State {
        const { report } = props;

        const state = {
            data: {
                labels: new Array<string>(),
                datasets: new Array<chartjs.ChartDataSets>()
            },
            users: {}
        };

        const { appointments } = props;
        const { data } = state;
        if (!appointments || !data) return {};

        const start = moment(appointments.start).startOf('day');
        const end = moment(appointments.end).startOf('day');
        for (var date = start.clone(); date < end; date.add(1, 'day')) {
            data.labels.push(`${date.format('ddd')}, ${date.format('MM-DD')}`);
        }

        switch (report) {
            case Report.ActiveByTool:
                this.groupByTool(props, state, start, data.labels.length, false);
                break;

            case Report.ByTool:
                this.groupByTool(props, state, start, data.labels.length, true);
                break;

            case Report.Active:
            default:
                this.groupByState(props, state, start, data.labels.length);
                break;
        }

        return state;
    }

    render() {
        const { isSelected, onClick, title } = this.props;
        const { data } = this.state;

        return (
            <Chart.BarChart
                data={data}
                isSelected={isSelected}
                title={title}
                onClick={onClick}
            />
        );
    }

    groupByState(props: Props, state: State, start: moment.Moment, days: number) {
        const { appointments } = props;
        const { data } = state;
        if (!data || !data.datasets || !appointments || !appointments.data) return;

        const active = Chart.initDataSet('Happened', Chart.getStyle('33, 195, 150'));
        const cancelled = Chart.initDataSet('Cancelled', Chart.getStyle('243, 150, 33')); 
        data.datasets.push(active);
        data.datasets.push(cancelled);

        if (!active.data || !cancelled.data) throw 'something did not work';
        for (var i = 0; i < days; i++) {
            active.data[i] = 0;
            cancelled.data[i] = 0;
        }

        for (var c = 0; c < appointments.data.length; c++) {
            const row = appointments.data[c];
            var index = moment(row.localDate).diff(start, 'days');

            active.data[index] = (active.data[index] as number) + (row.active as number);
            cancelled.data[index] = (cancelled.data[index] as number) + (row.cancelled as number);
        }
    }

    groupByTool(props: Props, state: State, start: moment.Moment, days: number, includeCancelled: boolean) {
        var tools: { [tool: string]: chartjs.ChartDataSets } = {};
        var nTools = 0;

        const { appointments } = props;
        if (!appointments || !appointments.data || !state.data || !state.data.datasets) return;

        for (var c = 0; c < appointments.data.length; c++) {
            const row = appointments.data[c];

            var key = row.tool || 'PI';
            var ds: chartjs.ChartDataSets = tools[key];
            if (!ds) {
                var color = Chart.colors[nTools];
                ds = Chart.initDataSet(key, Chart.getStyle(color));
                if (ds.data) for (var i = 0; i < days; i++) {
                    ds.data[i] = 0;
                }
                state.data.datasets.push(ds);
                tools[key] = ds;
                nTools++;
            }

            var index = moment(row.localDate, 'MM-DD-YYYY').diff(start, 'days');
            if (!ds.data) continue;

            var value = (row.active as number);
            if ( value && includeCancelled) value += (row.cancelled as number);
            if (!value) console.log(`error: ${value}`);

            ds.data[index] = value;
        }
    }
}