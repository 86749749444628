import { reaction } from 'mobx';

import { Default } from '@crochik/pi-react/context';
import { IApp } from '@crochik/pi-react/context/IApp';
import { App, default as getApp } from '@crochik/pi-react/application/App';

import defaultActions from './actions';
import defaultServices from './services';

import defaultViews from './dataViews';
import defaultMenus from './menus';
import defaultForms from './forms';
import defaultPages from './pages';

import { default as LoginService, register as registerLogin } from './services/Login';

import load, { AppConfig } from './Loader';
import { Session } from './Session';
import { User } from '@crochik/schedulerapi.ts';

var session: Session;

function launchPage(app: App, name: string): boolean {
    if (!session.launchUrl.searchParams) return false;

    if (name in session.launchUrl.searchParams) {
        let id = session.launchUrl.searchParams[name];
        if (app.selectPage(`${name}=${id}`, 'Launch', { id: id })) return true;
    }

    return false;
}

function loadInitialPage(app: App, config: AppConfig) {
    let pages = ['Lead', 'Appointment', 'LeadType', 'AppointmentType', 'Report', 'Flow', 'Organization', 'User', 'Flow'];
    for (let name of pages) {
        if (launchPage(app, name)) {
            if (session.state.user) {
                app.setMenu(session.state.user.role as string | undefined);
            }
            return;
        }
    }

    pages = ['Leads', 'MyCalendar', 'BizCalendar', 'Profile', 'Flows', 'EventType', 'LeadStatus', 'Flows', 'Test'];
    for (let name of pages) {
        if (session.launchUrl.search === `?${name}`) {
            if (app.selectPage(name)) {
                if (session.state.user) {
                    app.setMenu(session.state.user.role as string | undefined);
                }
                return;
            }
        }
    }

    console.log(session.launchUrl.search);

    goHomePage();
}

export function goHomePage() {
    var app = getApp();

    if (session.state.user) {
        app.setMenu(session.state.user.role as string | undefined);

        switch (session.state.user.role) {
            case User.RoleEnum.Admin:
                app.selectPage('AccountDashboard');
                break;
            case User.RoleEnum.Manager:
                app.selectPage('Dashboard');
                break;
            case User.RoleEnum.User:
            default:
                app.selectPage('Agenda');
                break;
        }

    } else {
        app.setMenu(undefined);
        app.selectPage('Loading');
    }
}

async function loggedIn(app: App, config: AppConfig) {
    app.selectPage('Loading');

    // await session.updateStatus();

    if (!session.state.user) {
        console.error('Failed to get user');
        app.selectPage('Welcome');
        app.setMenu(undefined);
        return;
    }

    // save "autologin" token
    localStorage.setItem('autoLogin', JSON.stringify({
        userId: session.state.user.id,
        orgId: session.state.user.organizationId,
        accountId: session.state.user.accountId,
    }));

    if (process.env.REACT_APP_FULLSTORY === 'on' && Math.random() <= .1) {
        try {
            var initFullStory = window['__pi_initFullStory__'];
            if (initFullStory) {
                initFullStory(window, document, window['_fs_namespace'], 'script', 'user');
            }

            // FullStory
            window['FS'].identify(session.state.user.id, {
                displayName: session.state.user.name,
                impersonate: null
            });
        } catch (reason) {
            console.error('failed to init fullstory');
        }
    }

    var isReady = session.isSyncEnabled || session.state.user.role === User.RoleEnum.Admin;
    if (isReady) {
        loadInitialPage(app, config);
        return;
    }

    app.selectPage('InitialWizard');
}

function loggedOut(app: App) {
    // if (process.env.REACT_APP_FULLSTORY === 'on') {
    //     // FullStory
    //     window['FS'].identify(false);
    // }

    app.selectPage('Welcome');
    app.setMenu(undefined);
}

async function delayInit(app: App) {
    var config = await load(`app.json?${new Date().getTime()}`);

    reaction(() => Session.get().user, async () => {
        var user = Session.get().user;
        if (user) {
            loggedIn(app, config);
        } else {
            loggedOut(app);
        }
    });

    var user = await LoginService().init();
    if (!user) {
        loggedOut(app);
    }
}

function init(): App {
    console.debug(`Environment: ${process.env.NODE_ENV}`);
    console.log(`>> ${process.env.NODE_ENV}`);

    if (process.env.NODE_ENV !== 'production') {
        console.log(process.env);
        window['__pi__'] = Default;
    }

    session = new Session(process.env.REACT_APP_flavor);

    var idpSettings: Oidc.OidcClientSettings = {
        authority: process.env.REACT_APP_authority,
        client_id: process.env.REACT_APP_client_id,
        redirect_uri: process.env.REACT_APP_redirect_uri,
        response_type: process.env.REACT_APP_response_type,
        scope: process.env.REACT_APP_scope,
        post_logout_redirect_uri: process.env.REACT_APP_post_logout_redirect_uri,
        acr_values: process.env.REACT_APP_acr_values
    };

    registerLogin(idpSettings, session);

    var autoLogin = false;
    const json = localStorage.getItem('autoLogin');
    if (json) {
        const config = JSON.parse(json);
        if (config && config['userId']) {
            localStorage.removeItem('autoLogin');
            autoLogin = true;
        }
    }

    if (session.launchUrl.searchParams || autoLogin) {
        LoginService().redirectLogin();
    }

    defaultServices();
    defaultActions();
    defaultForms();
    defaultViews();
    defaultPages();
    defaultMenus();

    var config: IApp = {
        name: 'ProgramInterface.com',
        initialPage: 'Loading',
        ga: process.env.REACT_APP_GA,
    };

    return new App(config, delayInit);
}

export default function wrapinit(): App {
    try {
        return init();

    } catch (e) {
        alert('Initialization failed');
        throw e;
    }
}