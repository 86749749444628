
import * as API from '../services/API';
import App from '@crochik/pi-react/application/App';
import Dialog from '@crochik/pi-react/services/Dialog';

async function exec() {
    try {
        var flow = await API.flow.create('', 'New Flow');
        App().selectPage(`Flow=${flow.id}`, '', { id: flow.id });

    } catch (ex) {
        Dialog.critical({
            title: 'Error',
            message: 'Failed to create new Flow'
        });
    }
}

export function add() {
    Dialog.confirm({
        title: 'New Flow',
        message: 'Create a new flow?',
        onPositive: exec,
        positiveCaption: 'Yes',
        negativeCaption: 'No',
    });
}