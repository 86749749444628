import * as React from 'react';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { LeadTypeSettings } from '@crochik/schedulerapi.ts';
import ActionButton from '@crochik/pi-react/ui/material/ActionButton';

import DataService from '@crochik/pi-react/services/DataService';
import { Default } from '@crochik/pi-react';

interface IProps {
    id: string;
    title: string;
    onClose: () => any;
}

interface IState {
    settings?: LeadTypeSettings;
    json?: string;
}

export class SettingsDialog extends React.Component<IProps, IState> {
    json?: string;

    constructor(props: IProps) {
        super(props);

        this.state = {

        };
    }

    async componentDidMount() {
        const { id } = this.props;

        const settings = await DataService().one('LeadTypeSettings', { args: [null, { id }] }) as LeadTypeSettings;
        this.setState({
            settings,
            json: JSON.stringify(settings, null, 2)
        });
    }

    onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.json = event.target.value;
    }

    onSave = async () => {
        const { id, onClose } = this.props;

        if (this.json) {
            try {
                const settings = JSON.parse(this.json) as LeadTypeSettings;
                await Default.actions.executeAsync('save', 'page.LeadType.Settings', id, settings);
                onClose();

            } catch (reason) {
                console.error(reason);
            }
        }
    }

    render() {
        const { title, onClose } = this.props;
        const { json } = this.state;

        return (
            <Dialog
                className="FormDialog"
                title={title}
                open={true}
                onClose={onClose}
                TransitionComponent={(props) => (<Slide direction="up" {...props} />)}
                fullWidth={true}
                maxWidth="sm"
                fullScreen={true}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent className="DialogContent">
                    {
                        json ? (
                            <textarea onChange={this.onChange} style={{ width: '100%', height: '100%' }} defaultValue={json} />
                        ) : (
                                <div>Loading...</div>
                            )
                    }
                </DialogContent>

                <DialogActions style={{ padding: 24 }}>
                    <ActionButton title="Close" action={onClose} />
                    <ActionButton title="Update" action={this.onSave} />
                </DialogActions>
            </Dialog>
        );
    }
}