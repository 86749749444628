import * as api from '@crochik/schedulerapi.ts';
import { Session } from '../../Session';

const apiServer = process.env.REACT_APP_pi_api;
console.debug(`API server: ${apiServer}`);

export function getAccessToken(): string {
    var accessToken = Session.get().accessToken;
    if (!accessToken) {
        throw new Error('No session');
    }
    return accessToken;
}

export function getConfig(): api.Configuration {
    return new api.Configuration({
        basePath: apiServer,
        accessToken: getAccessToken()
    });
}

export function getApiServer() {
    return apiServer;
}

export class Base {
    getConfig() {
        return new api.Configuration({
            basePath: apiServer,
            accessToken: getAccessToken()
        });
    }
}

export type Dict<T> = { [id: string]: T };

export interface ApiModel {
    id?: string;
    name?: string;
}

export abstract class CachedApiModel<TApi, T extends ApiModel> extends Base {
    _list?: T[] = undefined;
    _dict?: Dict<T> = undefined;

    async get(): Promise<Dict<T>> {
        if (!this._dict) {
            const list = await this.all();
            const obj: Dict<T> = {};
            for (const item of list) {
                if (!item.id) continue;
                obj[item.id] = item;
            }
            this._dict = obj;
        }

        return this._dict;
    }

    async all(): Promise<T[]> {
        if (!this._list) {
            this._list = await this.loadAll();
        }
        return this._list;
    }

    protected abstract get api(): TApi;
    protected abstract loadAll(): Promise<T[]>;
}
