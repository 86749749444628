import { Base } from './api';
import * as api from '@crochik/schedulerapi.ts';

class FlowTransitionClient extends Base {
    autoFlowId = 'c7825521-2b6b-4c0a-ad5f-a841580386ef';
    salesforceFlowId = 'a2a0b3d8-ae75-47a9-8f10-0bb20af9802f';
    inspirenetFlowId = 'fc100000-0000-0000-0000-000000000000';
    tag = 'ExternalLead';

    protected get api() {
        return new api.FlowTransitionApi(this.getConfig());
    }

    async get(flowId: string, entityId: string, tag: string): Promise<api.FlowTransition | null> {
        try {
            return await this.api.getFlowTransition(flowId, entityId, tag);

        } catch (ex) {
            if (ex.status === 404) {
                return null;
            }

            console.error('Failed to get flow', ex);
            throw ex;
        }
    }

    // fci helpers
    async isUsingSalesforce(entityId: string): Promise<boolean> {
        var transition = await this.get(this.autoFlowId, entityId, this.tag);
        console.log('Transition:', transition);
        return !!transition && transition.targetFlowId === this.salesforceFlowId;
    }

    async migrateToSalesforce(entityId: string): Promise<boolean> {
        var result = await this.api.addFlowTransition(this.autoFlowId, entityId, this.tag, {
            entityId,
            currentFlowId: this.autoFlowId,
            targetFlowId: this.salesforceFlowId,
            tag: this.tag
        });

        return result.targetFlowId === this.salesforceFlowId;
    }
    
    async revertToInspirenet(entityId: string): Promise<boolean> {
        await this.api.deleteFlowTransition(this.autoFlowId, entityId, this.tag);
        return true;
    }
}

export const flowTransition = new FlowTransitionClient();