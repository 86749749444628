import * as React from 'react';
import { CustomPage } from '@crochik/pi-react/application/Page';
import Card from '@material-ui/core/Card';

import ActionButton from '@crochik/pi-react/ui/material/ActionButton';

import './ContentPage.css';
import './Welcome.css';
import { Session } from '../Session';

interface State {
    message?: string;
}

class Welcome extends React.Component<{}, State> {
    boundState: State;

    constructor(props: any) {
        super(props);

        this.state = {
            message: undefined
        };
    }

    async componentDidMount() {
        var resp = await fetch(`${Session.get().flavor}/welcome.html`);
        var html = await resp.text();
        this.setState({
            ...this.state,
            message: html
        });
    }

    render() {
        if (!this.state.message) {
            return (
                <div/>
            );
        }

        var html = {
            __html: this.state.message
        };

        return (
            <div className="ContentPage">                
                <div className="ContentPage_body">
                    <Card className="ContentPage_card">
                        <div dangerouslySetInnerHTML={html} />
                        <div className="Welcome_login">
                            Login with<br />
                            <ActionButton title="Office 365" action="loginOffice365" context="app" variant="contained" color="primary" />
                            <ActionButton title="InspireNet" action="loginInspireNet" context="app" variant="contained" color="primary" />
                            {/* <ActionButton title="Levitate" action="loginRealMagic" context="app" variant="contained" color="primary" /> */}
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <Welcome />);