import * as React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

// TODO: nullable so it will match the models generated by the api
interface IItem {
    id?: string;
    name?: string;
}

interface Props {
    selected: string;
    items: IItem[];
    title: string;
    onChange: (id: string) => any;
}

interface State {
}

export class DropDown extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    onSelect = event => {
        var value = event.target.value;
        this.props.onChange(value);
    }

    render() {
        return (
            <FormControl className="DropDown">
                <InputLabel>{this.props.title}</InputLabel>
                <Select
                    value={this.props.selected}
                    onChange={this.onSelect}
                >
                    {this.props.items.map(item => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
}
