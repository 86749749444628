import { Form } from '@crochik/pi-react/ui/Form';
import * as API from '../services/API';
import Dialog from '@crochik/pi-react/services/Dialog';

export function addEvent(context: string) {
    var form = Form.get(context.substring(5));
    var selectField = form.fieldMap['event'] as any;
    
    const { event: eventCode, id, notes, integrationId } = form.values as any;
    const eventName = selectField['options'][eventCode] as string;

    API.triggerEvent(id, integrationId, {
        event: eventName,
        notes,
        data: {
            eventId: parseInt(eventCode, 10),
        }
    }).then(() => {
        Dialog.inform({
            title: 'Request Submitted',
            message: 'Request Submitted'
        });
    }).catch(reason => {
        Dialog.error({
            title: 'Error',
            message: `Error #${reason.status}${reason.statusText}`
        });
    });
}