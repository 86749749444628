import * as React from 'react';
import * as chartjs from 'chart.js';
import * as Chart from '../BarChart';

import moment = require('moment');
import { User, AvailabilityStats } from '@crochik/schedulerapi.ts';

export enum Report {
    AppointmentsByUser,
    OpenSlotsByUser,
    Overall
}

interface Props {
    title: string;
    report: Report;
    users?: { [id: string]: User };
    availability?: AvailabilityStats;
    onClick?: () => any;
    isSelected?: boolean;
}

interface State {
    data?: chartjs.ChartData;
    users?: { [userId: string]: chartjs.ChartDataSets };
}

export class Availability extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = this.calculateState(props);
    }

    componentWillReceiveProps(props: Props) {
        const state = this.calculateState(props);
        this.setState(state);
    }

    calculateState(props: Props): State {
        const { users, report } = props;

        const state = {
            data: {
                labels: new Array<string>(),
                datasets: new Array<chartjs.ChartDataSets>()
            },
            users: {}
        };

        const { availability } = props;
        const { data } = state;
        if (!availability || !data) return {};

        const start = moment(availability.start).startOf('day');
        const end = moment(availability.end).startOf('day');
        for (var date = start.clone(); date < end; date.add(1, 'day')) {
            data.labels.push(`${date.format('ddd')}, ${date.format('MM-DD')}`);
        }

        if (users) {
            var orderedList: { id: string, name: string }[] = [];

            // tslint:disable-next-line:forin
            for (var userId of Object.keys(users)) {
                orderedList.push({
                    id: userId,
                    name: this.getUserName(userId)
                });
            }
            orderedList = orderedList.sort((l, r) => l.name.localeCompare(r.name));

            for (var c = 0; c < orderedList.length; c++) {
                const color = Chart.colors[c];
                const user = orderedList[c];

                const ds = Chart.initDataSet(user.name, Chart.getStyle(color));
                state.users[user.id] = ds;
                state.data.datasets.push(ds);

                if (ds.data) {
                    for (var i = 0; i < data.labels.length; i++) {
                        ds.data[i] = 0;
                    }
                }
            }
        }

        switch (report) {
            case Report.AppointmentsByUser:
            case Report.OpenSlotsByUser:
                this.groupByUser(props, state, start);
                break;

            case Report.Overall:
            default:
                this.group(props, state, start, data.labels.length);
                break;
        }

        return state;
    }

    render() {
        const { isSelected, onClick, title } = this.props;
        const { data } = this.state;

        return (
            <Chart.BarChart
                data={data}
                isSelected={isSelected}
                title={title}
                onClick={onClick}
            />
        );
    }

    group(props: Props, state: State, start: moment.Moment, nDays: number) {
        const { availability } = props;
        const { data } = state;
        if (!data || !data.datasets || !availability || !availability.data) return;

        const slots = Chart.initDataSet('Available', Chart.getStyle('33, 195, 150'));
        const appts = Chart.initDataSet('Scheduled', Chart.getStyle('33, 150, 243')); // 245, 150, 33
        data.datasets.push(appts);
        data.datasets.push(slots);
        if (appts.data && slots.data) for (var i = 0; i < nDays; i++) {
            appts.data[i] = 0;
            slots.data[i] = 0;
        }

        if (!slots.data || !appts.data) throw 'something did not work';

        for (var c = 0; c < availability.data.length; c++) {
            const row = availability.data[c];
            var index = moment(row.date).diff(start, 'days');

            var openSlots = slots.data[index] as number;
            var appointmentsCount = appts.data[index] as number;
            openSlots += row.openSlotsCount as number;
            appointmentsCount += row.appointmentsCount as number;
            slots.data[index] = openSlots;
            appts.data[index] = appointmentsCount;
        }
    }

    getUserName(userId: string) {
        const { users } = this.props;
        if (users && users[userId]) {
            var name = users[userId].name;
            if (name) {
                var index = name.indexOf(' ');
                return index > 0 ? name.substring(0, index) : name;
            }
        }

        return userId;
    }

    groupByUser(props: Props, state: State, start: moment.Moment) {
        const { availability } = props;
        const { users } = state;
        if (!users || !availability || !availability.data) return;

        for (var c = 0; c < availability.data.length; c++) {
            const row = availability.data[c];
            var ds: chartjs.ChartDataSets = users[row.userId as string];
            var index = moment(row.date).diff(start, 'days');
            if (!ds.data) continue;

            switch (this.props.report) {
                case Report.AppointmentsByUser:
                    ds.data[index] = row.appointmentsCount as number;
                    break;

                case Report.OpenSlotsByUser:
                    ds.data[index] = row.openSlotsCount as number;
                    break;

                default:
                    break;
            }
        }
    }
}