import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { Checkbox } from '@material-ui/core';

import * as api from '@crochik/schedulerapi.ts';

import './Integration.css';

interface Props {
    integration: api.IIntegration;
    checked?: boolean;
    onClick?: (integration: api.IIntegration) => any;
}

interface State {

}

export class Integration extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {};
    }

    onClick = () => {
        if (this.props.onClick) this.props.onClick(this.props.integration);
    }

    render() {
        return (
            <table
                key={this.props.integration.id}
                className="Integration"
                onClick={this.onClick}
            >
                <tbody>
                    <tr>
                        <td className="Integration_checkbox" rowSpan={2}>
                            <Checkbox
                                checked={this.props.checked}
                                color="primary"
                            />
                        </td>
                        <td className="Integration_icon" rowSpan={2}>
                            <img width="32" height="32" src={`/icons/${this.props.integration.id}.png`} />
                        </td>
                        <td><Typography color="default" variant="subtitle1">{this.props.integration.name}</Typography></td>
                    </tr>
                    <tr><td>
                        <Typography color="default" variant="body2">{this.props.integration.description}</Typography>
                    </td></tr>
                </tbody>
            </table>
        );
    }
}
