import { action } from 'mobx';
import * as moment from 'moment';

import * as service from '@crochik/pi-react/services/DataService';
import { DataView } from '@crochik/pi-react/ui/DataView';
import { IField, IForm } from '@crochik/pi-react/context/IForm';

import * as api from '@crochik/schedulerapi.ts';

import { Session } from '../Session';
import * as API from './API';
import { Organization } from '@crochik/schedulerapi.ts';

function sort<T extends { name?: string }>(query: service.Query, rows: T[]): T[] {
    if (query && query.query && 'orderBy' in query.query) {
        rows.sort((l, r) => (l.name || '').localeCompare(r.name || ''));
    }

    return rows;
}

class DataService extends service.DataService {
    selectMapping: { [entity: string]: ((query?: service.Query) => Promise<any>) } = {};
    oneMapping: { [entity: string]: ((query?: service.Query) => Promise<any>) } = {};
    users?: { [id: string]: api.User } = undefined;
    leadTypes?: { [id: string]: api.LeadType } = undefined;

    static getId(query?: service.Query) {
        console.log(query);

        if (!query) {
            throw new Error('Could not find id');
        }

        if (query.query && 'id' in query.query) {
            return query.query['id'];
        }

        if (!query.args || query.args.length !== 1) {
            throw new Error('Could not find id');
        }

        return query.args[0];
    }

    constructor() {
        super();

        this.selectMapping = {
            AccountLeads: this.searchAccountLeads,
            AccountIntegrations: () => API.getEntityIntegrations(),
            AccountUsers: this.getAccountUsers,
            AppointmentAggregationByCreation: (query) => this.getAppointmentAggregation(false, query),
            AppointmentAggregationByStart: (query) => this.getAppointmentAggregation(true, query),
            AppointmentIntegrations: this.appointmentIntegrations,
            Appointments: this.appointments,
            AppointmentsByType: this.appointments,
            AppointmentTypeIntegrations: (query) => API.getAppointmentTypeIntegrations(query && query.args ? query.args[0] : ''),
            AppointmentType: this.getAppointmentTypes,
            AppointmentTypes: this.getAppointmentTypes,
            Availability: () => API.getAvailability(),
            AvailabilityStats: this.getAvailabilityStats,            
            Event: () => API.getEvents(),
            EventType: this.eventTypes,
            FlowActions: this.flowActions,
            Flow: this.getFlows,
            Flows: this.getFlows,
            FlowSteps: (query) => API.flow.getSteps(query && query.args ? query.args[0] : undefined),
            FlowEvent: this.getFlowEvents,
            FlowTrigger: this.getFlowTriggers,
            FullCalendar: this.getCalendar,
            Integrations: () => API.getIntegrations(),
            LeadAggregation: this.getLeadsAggregation,
            LeadAggregationForAccount: this.getLeadsAggregationForAccount,
            LeadsPerHourAggregation: this.getLeadsPerHourAggregation,
            LeadsPerHourAggregationForAccount: this.getLeadsPerHourAggregationForAccount,
            LeadAppointments: this.leadAppointments,
            LeadEvents: (query) => API.getLeadEvents(DataService.getId(query)),
            LeadIntegrations: this.leadIntegrations,
            Leads: this.searchLeads,
            LeadsByType: this.leadsByType,
            LeadStatus: this.getLeadStatus,
            LeadTypeIntegrations: (query) => API.getLeadTypeIntegrations(query && query.args ? query.args[0] : ''),
            LeadTypes: () => API.getLeadTypes(),
            MergeUsers: this.getMergeCandidates,
            OpenSlots: () => API.getOpenSlots(),
            Organizations: () => API.getOrganizations(),
            OrgMetaData: (query) => API.metaData.getForOrg(query && query.args ? query.args[1]['entityId'] : ''),
            OrgAppointments: this.orgAppointments,
            OrgIntegrations: () => API.getOrgIntegrations(),
            OrgUsers: this.getOrgUsers,
            PossibleUsers: this.getPossibleUsers,
            Report: this.getReport,
            Settings: () => API.getSettings(),
            UserIdentities: () => API.getUserIdentities(),
            UserIntegrations: () => API.getUserIntegrations(),
            User: this.getUsers,
            Users: this.getUsers,
        };

        this.oneMapping = {
            AppConfig: this.getAppConfig,
            Appointment: this.getAppointment,
            AppointmentType: this.getAppointmentType,
            AutoScheduleLeadTypeIntegration: (query: service.Query) => API.getAutoScheduleOnLeadType(query && query.args ? query.args[1] : ''),
            AutoScheduleOrgIntegration: (query) => API.getAutoScheduleOnOrg(),
            FlowAction: this.getFlowAction,
            FlowActionForm: this.getFlowActionForm,
            GoToMeetingAppointmentTypeIntegration: (query: service.Query) => API.getGoToMeetingOnAppointmentType(query && query.args ? query.args[1] : ''),
            GoToMeetingOrgIntegration: (query) => API.getGoToMeetingOnOrg(),
            Lead: this.getLead,
            LeadType: this.getLeadType,
            LeadTypeSettings: this.getLeadTypeSettings,
            LuminLeadTypeIntegration: (query: service.Query) => API.getLuminOnLeadType(query && query.args ? query.args[1] : ''),
            LuminOrgIntegration: (query) => API.getLuminOnOrg(),
            SendGridAppointmentTypeIntegration: (query: service.Query) => API.getSendGridOnAppointmentType(query && query.args ? query.args[1] : ''),
            SendGridLeadTypeIntegration: (query: service.Query) => API.getSendGridOnLeadType(query && query.args ? query.args[1] : ''),
            SendGridOrgIntegration: (query) => API.getSendGridOnOrg(),
            User: this.getUser,
            ZoomAppointmentTypeIntegration: (query: service.Query) => API.getZoomOnAppointmentType(query && query.args ? query.args[1] : ''),
            ZoomOrgIntegration: (query) => API.getZoomOnOrg(),
        };
    }

    async getUsers(query: service.Query): Promise<api.User[]> {
        return sort(query, await API.getUsers());
    }

    async getFlows(query: service.Query): Promise<api.Flow[]> {
        return sort(query, await API.flow.all());
    }

    async flowActions(query: service.Query): Promise<api.FlowAction[]> {
        if (query.args && query.args.length === 1) {
            const { eventTypeId } = query.args[0];
            if (eventTypeId) {
                return sort(query, await API.flowAction.getForTrigger(eventTypeId));
            }
        }

        var rows = await API.flowAction.all();
        return sort(query, rows);
    }

    async getFlowAction(query: service.Query): Promise<api.FlowAction> {
        if (query.args && query.args.length === 1) {
            const id = query.args[0] as string;
            const map = await API.flowAction.get();
            return map[id];
        }

        throw 'Invalid arguments';
    }

    async getFlowActionForm(query: service.Query): Promise<IForm> {
        if (query.args && query.args.length === 1) {
            const { actionId, triggerEventId } = query.args[0];
            return await API.flowAction.getForm(actionId, triggerEventId) as IForm;
        }

        throw 'Invalid arguments';
    }

    async getFlowTriggers(query: service.Query): Promise<api.EventType[]> {
        var list: api.EventType[] = [];
        for (var event of await API.eventType.all()) {
            if (event.trigger && event.trigger.type === api.Trigger.TypeEnum.User) {
                list.push(event);
                continue;
            }
        }

        return sort(query, list);
    }

    async getFlowEvents(query: service.Query): Promise<api.EventType[]> {
        var list: api.EventType[] = [];

        var rows = await API.eventType.all();
        
        if (query.args && query.args.length === 1) {
            const { type } = query.args[0];
            rows = rows.filter(e => e.type && type.startsWith(e.type));
        }

        for (var event of rows) {
            if (!event.trigger || event.trigger.type === api.Trigger.TypeEnum.System || event.trigger.type === api.Trigger.TypeEnum.SideEffect) {
                list.push(event);
                continue;
            }
        }

        return sort(query, list);
    }

    async eventTypes(query: service.Query): Promise<api.EventType[]> {
        return sort(query, await API.eventType.all());
    }

    async getAppointmentTypes(query: service.Query): Promise<api.AppointmentType[]> {
        return sort(query, await API.getAppointmentTypes());
    }

    async getLeadStatus(query: service.Query): Promise<api.LeadStatus[]> {
        return sort(query, await API.leadStatus.all());
    }

    async getLeadTypesDict(): Promise<{ [id: string]: api.LeadType }> {
        if (!this.leadTypes) {
            const leadTypes = await API.getLeadTypes();
            const list: { [id: string]: api.LeadType } = {};
            for (var leadType of leadTypes) {
                if (!leadType.id) continue;
                list[leadType.id] = leadType;
            }
            this.leadTypes = list;
        }

        return this.leadTypes;
    }

    searchLeads = (query: service.Query): Promise<api.LeadSearchResult[]> => {
        return this._searchLeads(query);
    }

    searchAccountLeads = (query: service.Query): Promise<api.LeadSearchResult[]> => {
        const accountQuery: service.Query = {
            ...query,
            query: {
                context: 'Account'
            }
        };

        return this._searchLeads(accountQuery);
    }

    _searchLeads = async (query: service.Query): Promise<api.LeadSearchResult[]> => {
        let name: string = '';
        if (query.args && query.args.length === 2 && query.args[0] === 'Search') {
            name = query.args[1];
        }

        const isAccount = query.query && query.query['context'] === 'Account';
        const result = isAccount ?
            await API.searchAccountLeads(name, 100) :
            await API.searchLeads(name, 50);

        // map assignedEntity and leadStatus
        const users = await this.getUsersDict();
        const leads = result && result.leads ? result.leads : [];

        const leadStatus = await API.leadStatus.get();
        const leadTypes = await this.getLeadTypesDict();

        for (var lead of leads) {
            const entity = lead.assignedEntityId ? users[lead.assignedEntityId] : undefined;
            lead['assignedEntity'] = entity ? entity.name : lead.assignedEntityId;

            const status = lead.leadStatusId ? leadStatus[lead.leadStatusId] : undefined;
            lead['leadStatus'] = status ? status.name : lead.leadStatusId;

            const leadType = lead.leadTypeId ? leadTypes[lead.leadTypeId] : undefined;
            lead['leadType'] = leadType ? leadType.name : lead.leadTypeId;
        }

        return leads;
    }

    getAccountUsers = async (query: service.Query): Promise<any> => {
        var users = API.getUsers();
        var orgs = API.getOrganizations();
        var result = await Promise.all([users, orgs]);

        var orgDict: { [id: string]: api.Organization } = {};
        result[1].forEach((org: api.Organization) => orgDict[org.id as string] = org);
        var ret = result[0].map((user: api.User) => {
            var org = user.organizationId ? orgDict[user.organizationId] : null;
            return org ? {
                ...user,
                organization: org.name
            } : user;
        });

        return ret;
    }

    getReport = (query: service.Query): Promise<any> => {
        if (!query.args || query.args.length < 1) return Promise.reject('Invalid or Missing args');

        return API.report(query.args[0], query.args[1]);
    }

    getMergeCandidates = async (): Promise<any[]> => {
        var rows = await API.getMergeCandidates();
        var mapped = rows.map(src => {
            if (!src || !src.user1 || !src.user2) return src;
            return {
                ...src,
                key: `${src.user1.entityId}${src.user2.entityId}`,
                identity1: `${src.user1.name}\n${src.user1.email}\n${src.user1.context}`,
                identity2: `${src.user2.name}\n${src.user2.email}\n${src.user2.context}`,
            };
        });

        return mapped;
    }

    getAvailabilityStats(query?: service.Query): Promise<api.AvailabilityStats> {
        var id = undefined;

        if (query && query.args && query.args.length === 2) {
            const { entityId } = query.args[1];
            id = entityId;
        }

        return API.getAvailabilityStats(id);
    }

    getAppointmentAggregation = async (byStart: boolean, query?: service.Query): Promise<api.AppointmentAggregation | undefined> => {
        if (!query || !query.args || query.args.length < 1) {
            return Promise.reject('Invalid or missing arguments');
        }

        var arg = query.args[0];
        if (query.args.length === 2) {
            const { entityId } = query.args[1];
            return API.getAppointmentAggregationForOrg(entityId, arg.start, arg.end, byStart);
        }

        return API.getAppointmentAggregation(arg.start, arg.end, byStart);
    }

    getLeadsAggregation = async (query?: service.Query): Promise<api.LeadAggregation | undefined> => {
        var id = undefined;
        if (query && query.args && query.args.length === 2) {
            const { entityId } = query.args[1];
            id = entityId;
        }

        return API.getLeadAggregation(id);
    }

    getLeadsAggregationForAccount = async (query?: service.Query): Promise<api.LeadAggregation | undefined> => {
        return API.getLeadAggregationForAccount();
    }

    getLeadsPerHourAggregation = async (query?: service.Query): Promise<api.LeadAggregation | undefined> => {
        var id = undefined;
        if (query && query.args && query.args.length === 2) {
            const { entityId } = query.args[1];
            id = entityId;
        }

        return API.getLeadsPerHourAggregation(id);
    }

    getLeadsPerHourAggregationForAccount = async (query?: service.Query): Promise<api.LeadAggregation | undefined> => {
        return API.getLeadsPerHourAggregationForAccount();
    }    

    getOrgUsers = async (query: service.Query): Promise<api.User[] | undefined> => {
        var orgId: string | undefined;

        if (!query || !query.args || query.args.length !== 2) {
            orgId = undefined;

        } else {
            var org: Organization = query.args[1];
            if (!org.id) {
                Promise.reject('Missing Org Id');
                return;
            }
            orgId = org.id;
        }

        return API.getOrgUsers(orgId);
    }

    getAppConfig = async (query: service.Query): Promise<api.AppConfig | undefined> => {
        if (!query || !query.args || query.args.length !== 2) {
            Promise.reject('invalid number or args');
            return;
        }

        if (query.args[0] === 'AppointmentType') {
            return API.getAppConfig(query.args[1]);
        }

        Promise.reject('invalid args');
        return undefined;
    }

    getAppointmentType = async (query: service.Query): Promise<api.AppointmentType | undefined> => {
        if (!query || !query.args || query.args.length !== 2) {
            Promise.reject('invalid number or args');
            return;
        }

        var id = query.args[1];
        return await API.getAppointmentType(id);
    }

    getCalendar = async (query: service.Query): Promise<api.EventsAndSlots | null> => {
        console.log('getCalendar');
        if (query && query.args && query.args.length === 2) {
            // scheduler
            if (query.args[0] === 'User') {
                console.log('get calendar for user');
                let args: API.IGetUserAvailability = query.args[1];
                return await API.getCalendar(args);
            }
            Promise.reject('invalid query');
            return null;

        }

        // my calendar
        const start = moment().startOf('day').toDate();
        const end = moment().startOf('day').add('days', 14).toDate();
        return await API.getMyCalendar(start, end);
    }

    getPossibleUsers = async (query: service.Query): Promise<api.User[] | undefined> => {
        if (!query || !query.args || query.args.length < 2) {
            Promise.reject('Missing args');
            return;
        }

        var args = query.args[1];
        return await API.getPossibleUsers(args['leadId'], args['appointmentTypeId']);
    }

    // returns array with only one element
    getLead = async (query: service.Query): Promise<api.Leads | undefined> => {
        if (!query || !query.args || query.args.length < 2 || !query.args[1]['id']) {
            Promise.reject('Missing id');
            return;
        }

        const id = query.args[1]['id'];
        const lead = await API.getLead(id);

        const leadTypes = await this.getLeadTypesDict();
        const leadType = leadTypes[lead.leadTypeId as string];
        lead['leadType'] = leadType ? leadType.name : lead.leadTypeId;

        return lead;
    }

    getLeadType = async (query: service.Query): Promise<api.LeadType | undefined> => {
        if (!query || !query.args || query.args.length < 2 || !query.args[1]['id']) {
            Promise.reject('Missing id');
            return;
        }

        var id = query.args[1]['id'];
        return await API.getLeadType(id);
    }

    getLeadTypeSettings = async (query: service.Query): Promise<api.LeadTypeSettings | undefined> => {
        if (!query || !query.args || query.args.length < 2 || !query.args[1]['id']) {
            Promise.reject('Missing id');
            return;
        }

        var id = query.args[1]['id'];
        return await API.getLeadTypeSettings(id);
    }

    getAppointment = async (query: service.Query): Promise<api.Appointment | undefined> => {
        if (!query || !query.args || query.args.length < 2 || !query.args[1]['id']) {
            Promise.reject('Missing id');
            return;
        }

        var id = query.args[1]['id'];
        var appt = await API.getAppointment(id);
        if (appt && appt.userId) {
            var user = await this.getUser({ args: ['User', { id: appt.userId }] });
            if (user) appt['userName'] = user.name;
        }

        return appt;
    }

    getUser = async (query: service.Query): Promise<api.User | undefined> => {
        var id: string;
        if (!query || !query.args || query.args.length < 2 || !query.args[1]['id']) {
            var user = Session.get().user;
            if (!user || !user.id) {
                Promise.reject('Missing user Id');
                return;
            }
            id = user.id;
        } else {
            id = query.args[1]['id'];
        }

        var users = await this.getUsersDict();
        return users[id];
    }

    getUsersDict = async (): Promise<{ [id: string]: api.User }> => {
        if (!this.users) {
            var users = await API.getUsers();
            var ret = {};
            for (var c = 0; c < users.length; c++) {
                var user = users[c];
                if (!user.id) continue;
                ret[user.id] = user;
            }
            this.users = ret;
        }

        return this.users;
    }

    addUser = async (appts: api.Appointment[]) => {
        var users = await this.getUsersDict();
        for (var c = 0; c < appts.length; c++) {
            var appt = appts[c];
            var user = users[appt.userId as string];
            appt['userName'] = user ? user.name : '';
        }
    }

    appointments = async (query?: service.Query): Promise<api.Appointment[] | undefined> => {
        var appointmentTypeId: string | undefined = undefined;
        if (query && query.query) {
            var appointmentType = query.query as api.AppointmentType;
            if (!appointmentType || !appointmentType.id) {
                Promise.reject('Missing Type');
                return;
            }
            appointmentTypeId = appointmentType.id;
        }

        var appts = await API.getAppointments(appointmentTypeId);
        await this.addUser(appts);

        return appts;
    }

    orgAppointments = async (query?: service.Query): Promise<api.Appointment[] | undefined> => {
        var appointmentTypeId: undefined | string = undefined;
        if (query && query.args && query.args.length === 1) {
            appointmentTypeId = query.args[0];
        }
        var appts = await API.getOrgAppointments(appointmentTypeId);
        await this.addUser(appts);

        return appts;
    }

    leadAppointments = async (query?: service.Query): Promise<api.Appointment[] | undefined> => {
        if (!query || !query.query) {
            Promise.reject('Missing query');
            return;
        }

        var leadId = query.query['id'];
        var appts = await API.getLeadAppointments(leadId);
        await this.addUser(appts);

        return appts;
    }

    appointmentIntegrations = async (query?: service.Query): Promise<any> => {
        if (!query || !query.query) {
            Promise.reject('Missing query');
            return;
        }

        var appointmentId = query.query['id'];
        return API.getAppointmentIntegrations(appointmentId);
    }

    leadIntegrations = async (query?: service.Query): Promise<any> => {
        if (!query || !query.query) {
            Promise.reject('Missing query');
            return;
        }

        var leadId = query.query['id'];
        return API.getLeadIntegrations(leadId);
    }

    leadsByType = async (query?: service.Query): Promise<any> => {
        if (!query || !query.query) {
            Promise.reject('Missing query');
            return;
        }

        var leadType = query.query as api.LeadType;
        if (!leadType.id) {
            Promise.reject('Missing lead type');
            return;
        }

        // update dataview fields (any better way?)
        var dv = DataView.get('LeadsByType');
        var state = DataView.getState('LeadsByType');
        // reset stat to get original fields
        state.fields = undefined;
        var fields: IField[] = [];
        fields.push(...dv.fields);
        state.fields = fields;
        state.title = leadType.name;
        // reset current data?
        dv.reset();

        var leads = await API.getLeads(leadType.id);
        if (!leads || !leads.list) return undefined;
        if (leads.fields) {
            fields.push(...(leads.fields.map(API.mapField)));
            state.fields = fields;
        }

        var rows = leads.list.map((lead) => {
            return { ...lead, ...lead.values };
        });

        return rows;
    }

    @action
    async select(entity: string, query?: service.Query): Promise<any> {
        let getter = this.selectMapping[entity];
        if (!getter) {
            console.error(`${entity}: data service not implemented`);
            return [];
        }

        let json = await getter(query);

        return json;
    }

    @action
    async one(entity: string, query?: service.Query): Promise<any> {
        let getter = this.oneMapping[entity];
        if (!getter) {
            console.error(`${entity}: data service not implemented`);
            return [];
        }

        let json = await getter(query);

        return json;
    }
}

export default function register() {
    service.register(new DataService());
}