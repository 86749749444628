import { IEditEvent } from '@crochik/pi-react/ui/material/DataGridComponent';
import { AppointmentIntegration, Appointment } from '@crochik/schedulerapi.ts';
import { Default } from '@crochik/pi-react/context';
import App from '@crochik/pi-react/application/App';
import Dialog from '@crochik/pi-react/services/Dialog';

import * as API from '../services/API';
import { Form } from '@crochik/pi-react/ui/Form';
import moment = require('moment');

export async function openAppointment(dataView: string, args: any[]) {
    var evt = args[0] as IEditEvent;
    var integration = evt.row as AppointmentIntegration;
    if (integration.url) {
        window.open(integration.url, '_blank');
    } else {
        console.log('no url associated with this integration');
    }
}

export function open(context: string) {
    let appointment: Appointment = Default.state.get(context);
    console.log(`open appt ${appointment.id}`);
    if (appointment.webLink) {
        window.open(appointment.webLink, '_blank');
    }
}

async function cancelAppointment(context: string, appointmentId: string) {
    var appt = await API.cancelAppointment(appointmentId);
    Default.state.assign(context, appt);

    Dialog.inform({ title: 'Appointment cancelled', message: 'Appointment cancelled' });
}

export function cancel(context: string) {
    let apptId = Default.state.get('id', context);
    console.log(`cancel appt ${apptId}`);

    Dialog.confirm({
        title: 'Cancel Appointment?',
        message: 'Are you sure you would like to cancel this appointment?',
        onPositive: () => cancelAppointment(context, apptId),
        positiveCaption: 'Yes',
        negativeCaption: 'No',
    });
}

export function openLead(context: string) {
    let leadId = Default.state.get('leadId', context);
    App().selectPage(`Lead=${leadId}`, context, { id: leadId });
}

interface AddAppointmentValues {
    date: string;
    start: string;
    end: string;
    subject: string;
    appointmentTypeId: string;
    userId: string;
    leadId: string;
    notes: string;
}

export interface AddAppointmentResult {
    success: boolean;
    error?: string;
    appointment?: Appointment;
}

export async function add(context: string, args: any): Promise<AddAppointmentResult> {
    var form = Form.get('AddAppointment');
    var values = form.values as AddAppointmentValues;

    var start = moment(values.start, 'HH:mm');
    var end = moment(values.end, 'HH:mm');
    const startDate = moment(values.date, 'YYYY-MM-DD').add(start.hours(), 'hours').add(start.minutes(), 'minutes');
    const endDate = moment(values.date, 'YYYY-MM-DD').add(end.hours(), 'hours').add(end.minutes(), 'minutes');
    if (!endDate.isAfter(startDate)) {
        return {
            success: false, 
            error: 'Invalid Range: End Time is before Start Time'
        };
    }

    var appointment = await API.addAppointment(values.leadId, {
        appointmentTypeId: values.appointmentTypeId,
        start: startDate.toDate(),
        end: endDate.toDate(),
        subject: values.subject,
        userId: values.userId,
        notes: values.notes,
    });

    return {
        success: true,
        appointment,
    };
}