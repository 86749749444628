import * as React from 'react';
import { CustomPage } from '@crochik/pi-react/application/Page';
import DataService from '@crochik/pi-react/services/DataService';
import { LeadAggregation } from '@crochik/schedulerapi.ts';
import { Grid } from '@material-ui/core';
import { Leads } from './Leads';

interface IProps {
}

interface IState {
    leadsAggregation?: LeadAggregation;
    leadsPerHourAggregation?: LeadAggregation;
}

export class AccountDashboard extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
        };
    }

    loadLeadAggregation() {
        DataService().select('LeadAggregationForAccount')
            .then(
                leads => {
                    this.setState({ leadsAggregation: leads });
                }
            ).catch(reason => {
                console.log('failed to aggregate leads');
            });
    }

    loadLeadsPerHour() {
        DataService().select('LeadsPerHourAggregationForAccount')
            .then(
                leads => {
                    this.setState({ leadsPerHourAggregation: leads });
                }
            ).catch(reason => {
                console.log('failed to aggregate leads');
            });
    }

    async componentWillMount() {
        this.loadLeadAggregation();
        this.loadLeadsPerHour();
    }

    render() {
        var {
            leadsAggregation,
            leadsPerHourAggregation
        } = this.state;

        return (
            <div className={'ContentPage_scrollable'}>
                <Grid container={true} spacing={16}>
                    <Grid item={true} md={12} xs={12}>
                        <Leads
                            title="Leads Per Hour"
                            leads={leadsPerHourAggregation}
                            perHour={true}
                            height={400}
                        />
                    </Grid>

                    <Grid item={true} md={12} xs={12}>
                        <Leads
                            title="Leads Per Day"
                            leads={leadsAggregation}
                            height={400}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <AccountDashboard />);