import * as api from '@crochik/schedulerapi.ts';
import * as API from '../services/API';
import Dialog from '@crochik/pi-react/services/Dialog';
import { DataView } from '@crochik/pi-react/ui/DataView';

export async function save(context: string, args: any[]) {
    const entityId = args[0] as string;
    const added = args[1] as api.EntityMetadata[];
    const callback = args[2] as () => any;

    API.metaData.addToOrg(entityId, added)
        .then(ok => {
            Dialog.inform({ title: 'Success', message: 'Saved' });
            callback();
        })
        .catch(ex => {
            Dialog.critical({ title: 'Organization Metadata', message: 'Error trying to save metadata.' });
            callback();
        });
}

export async function remove(context: string, args: any[]) {
    const entityId = args[0] as string;
    const view = args[1] as DataView;
    const callback = args[2] as () => any;

    var list = view.records.map(x => view.get(x) as api.EntityMetadata).filter(x => view.isSelected(x));

    API.metaData.removeFromOrg(entityId, list)
        .then(ok => {
            Dialog.inform({ title: 'Success', message: 'Removed' });
            callback();
        })
        .catch(ex => {
            Dialog.critical({ title: 'Organization Metadata', message: 'Error trying to remove.' });
            callback();
        });
}