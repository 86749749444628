import * as React from 'react';
import * as chartjs from 'chart.js';
import * as Chart from '../BarChart';

import moment = require('moment');
import { LeadAggregation } from '@crochik/schedulerapi.ts';

interface Props {
    leads?: LeadAggregation;
    onClick?: () => any;
    isSelected?: boolean;
    title: string;
    perHour?: boolean;
    height?: number;
}

interface State {
    data?: chartjs.ChartData;
}

export class Leads extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = this.calculateState(props);
    }

    componentWillReceiveProps(props: Props) {
        const state = this.calculateState(props);
        this.setState(state);
    }

    calculateState(props: Props): State {
        const state = {
            data: {
                labels: new Array<string>(),
                datasets: new Array<chartjs.ChartDataSets>()
            },
            users: {}
        };

        const { leads, perHour } = props;
        const { data } = state;
        if (!leads || !data) return {};

        const start = perHour ? moment(leads.start).startOf('hour') : moment(leads.start).startOf('day');
        const end = moment(); // .startOf('day').add(1, 'days');
        if (props.perHour) {
            for (var hour = start.clone(); hour < end; hour.add(1, 'hour')) {
                data.labels.push(`${hour.format('ddd')}, ${hour.format('hh A')}`);
            }

        } else {
            for (var date = start.clone(); date < end; date.add(1, 'day')) {
                data.labels.push(`${date.format('ddd')}, ${date.format('MM-DD')}`);
            }
        }

        this.groupByState(props, state, start, data.labels.length);

        return state;
    }

    render() {
        const { isSelected, onClick, title, height } = this.props;
        const { data } = this.state;

        return (
            <Chart.BarChart
                data={data}
                isSelected={isSelected}
                title={title}
                onClick={onClick}
                height={height}
            />
        );
    }

    groupByState(props: Props, state: State, start: moment.Moment, days: number) {
        const { leads, perHour } = props;
        const { data } = state;

        if (!data || !data.datasets || !leads || !leads.data) return;

        const totals: { [key: string]: number } = {};
        for (var c = 0; c < leads.data.length; c++) {
            const row = leads.data[c];
            if (!row.name) continue;

            const count = parseInt(row.count as string, 10);
            if (!totals[row.name]) {
                totals[row.name] = count;
            } else {
                totals[row.name] += count;
            }
        }

        const sort: { key: string, count: number }[] = [];
        Object.keys(totals).forEach(key => sort.push({ key, count: totals[key] }));
        sort.sort((l, r) => r.count - l.count);

        const leadSources: { [key: string]: chartjs.ChartDataSets } = {};
        for (c = 0; c < sort.length; c++) {
            const row = sort[c];

            const color = Chart.colors[c];
            var dataSet = Chart.initDataSet(row.key, Chart.getStyle(color));
            data.datasets.push(dataSet);
            leadSources[row.key] = dataSet;
        }

        for (c = 0; c < leads.data.length; c++) {
            const row = leads.data[c];
            if (!row.name) continue;

            dataSet = leadSources[row.name];
            var index = moment(row.createdOn).diff(start, perHour ? 'hours' : 'days');

            if (!dataSet.data) continue;
            const val = parseInt(row.count as string, 10);
            if (dataSet.data[index] === undefined) {
                for (var i = 0; i < data.datasets.length; i++) {
                    if (!data || !data.datasets) continue;
                    var ds = data.datasets[i];
                    if (!ds || !ds.data) continue;
                    ds.data[index] = 0;
                }
            }
            dataSet.data[index] = val;
        }
    }
}