import * as React from 'react';
import { CustomPage } from '@crochik/pi-react/application/Page';
import DataService from '@crochik/pi-react/services/DataService';
import * as API from '../../services/API';
import * as api from '@crochik/schedulerapi.ts';

import './styles.css';
import { Card } from '@material-ui/core';
import App from '@crochik/pi-react/application/App';
import LeadStatus from './LeadStatus';
import { observer } from 'mobx-react';
import { ClickEvent, ObjectType } from './Event';
import { PopupDialog } from '../Comps/PopupDialog';
import { ChainReactionWizard } from './ChainReactionWizard';
import { ReactionWizard } from './ReactionWizard';
import { observable, runInAction, reaction } from 'mobx';
import { ANY } from '../../services/API';
// import { Default } from '@crochik/pi-react';

interface IProps {
}

interface IState {
    flow?: API.FlowSteps;
    selectedLeadStatus?: api.LeadStatus;
    leadStatus?: api.LeadStatus[];
    launchEvent?: ClickEvent;
}

@observer
class PageComponent extends React.Component<IProps> {
    boundState: IState;

    constructor(props: IProps) {
        super(props);

        this.boundState = observable({
            flow: undefined,
            selectedLeadStatus: undefined,
            leadStatus: [],
            launchEvent: undefined,
        });

        // Default.state.set('Flow', this.boundState, 'page');

        reaction(() => this.boundState.flow, () => {
            this.boundState.leadStatus = this.boundState.flow ?
                this.filterUnusedLeadStatus(this.boundState.flow) :
                undefined;
        });
    }

    async componentDidMount() {
        let args = App().args;
        let id: string | undefined;
        if (args.length >= 2) {
            switch (args[0]) {
                case 'Flows':
                case 'Launch':
                    id = (args[1] as api.Flow).id;
                    break;
                default:
                    break;
            }
        }

        if (!id) {
            console.error('id not found', args);
            return;
        }

        this.boundState.flow = await DataService().select('FlowSteps', { args: [id] }) as API.FlowSteps;
    }

    filterUnusedLeadStatus(steps: API.FlowSteps): api.LeadStatus[] {
        const leadStatusDict: { [key: string]: api.LeadStatus } = {};
        const leadStatus: api.LeadStatus[] = [];

        steps.leadStatusList.forEach(e => {
            leadStatusDict[e.id || ANY] = e;
        });

        const stage: { [key: string]: api.FlowStep } = {};

        // prevent being added
        stage[API.InitialLeadStatusId] = {};

        steps.flowStepsList.forEach(step => {
            if (step.currentStatusId && !stage[step.currentStatusId]) {
                const obj = leadStatusDict[step.currentStatusId];
                stage[step.currentStatusId] = step;
                leadStatus.push(obj);
            }

            if (step.actionId === API.ChangeStatusActionId && step.options) {
                const id = step.options['leadStatusId'];
                if (id && !stage[id]) {
                    const obj = leadStatusDict[id];
                    stage[id] = step;
                    leadStatus.push(obj);
                }
            }
        });
        
        leadStatus.sort(API.sort);
        leadStatus.push(leadStatusDict[ANY]);
        leadStatus.push(leadStatusDict[API.InitialLeadStatusId]);

        return leadStatus;
    }

    onClick = (leadStatus: api.LeadStatus) => (event: ClickEvent) => {
        runInAction(() => {
            this.boundState.launchEvent = event;
            this.boundState.selectedLeadStatus = leadStatus;
        });
    }

    onCloseDialog = async () => {
        this.boundState.launchEvent = undefined;
        if (this.boundState.flow) {
            const steps = await DataService().select('FlowSteps', { args: [this.boundState.flow.flowId] });
            this.boundState.flow = steps;
        }
    }

    render() {
        const { flow, launchEvent, selectedLeadStatus, leadStatus } = this.boundState;
        if (!flow || !leadStatus) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div className="Flow pi-page">
                {leadStatus.map(leadStatus =>
                    <Card key={leadStatus.id} className="Flow-leadstatus">
                        <LeadStatus leadStatus={leadStatus} flow={flow} onClick={this.onClick(leadStatus)} />
                    </Card>
                )}

                {!!launchEvent && selectedLeadStatus &&
                    (launchEvent.objectType === ObjectType.Event ||
                        launchEvent.objectType === ObjectType.UserEvent ||
                        launchEvent.objectType === ObjectType.Root ) &&
                    <PopupDialog
                        key="chainReaction"
                        open={!!launchEvent}
                        title="Add Chain Reaction"
                        onClose={this.onCloseDialog}
                        maxWidth="md"
                        fullWidth={false}
                        fullScreen="auto"
                    >
                        <ChainReactionWizard
                            flow={flow}
                            leadStatus={selectedLeadStatus}
                            onClose={this.onCloseDialog}
                            launchEvent={launchEvent}
                        />
                    </PopupDialog>
                }
                {!!launchEvent && selectedLeadStatus &&
                    (launchEvent.objectType !== ObjectType.Event &&
                        launchEvent.objectType !== ObjectType.UserEvent &&
                        launchEvent.objectType !== ObjectType.Root ) &&
                    <PopupDialog
                        key="reaction"
                        open={!!launchEvent}
                        title="Reaction"
                        onClose={this.onCloseDialog}
                        maxWidth="md"
                        fullWidth={false}
                        fullScreen="auto"
                    >
                        <ReactionWizard
                            flow={flow}
                            leadStatus={selectedLeadStatus}
                            onClose={this.onCloseDialog}
                            launchEvent={launchEvent}
                        />
                    </PopupDialog>
                }

            </div>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <PageComponent />);