import Dialog from '@crochik/pi-react/services/Dialog';
import App from '@crochik/pi-react/application/App';
import LoginService from '../services/Login';
import * as API from '../services/API';
import { User, MergeUserCandidateMatch } from '@crochik/schedulerapi.ts';
import { Session } from '../Session';

function signOut() {
    LoginService().signOut();
}

async function signOutAndDeleteUser() {
    try {
        await API.deleteUser();

    } catch (ex) {
        Dialog.confirm({ title: 'Deleting User', message: 'Error: ' + ex.message });
    }

    signOut();
}

export function logout() {
    Dialog.confirm({
        title: 'Log Out?',
        message: 'Would you like to terminate this session?',
        onPositive: signOut,
        positiveCaption: 'Yes',
        negativeCaption: 'No',
    });
}

export function deleteUser() {
    Dialog.confirm({
        title: 'Are sure?',
        message: 'If you continue you will delete your account and all information associated with it permanently.',
        onPositive: signOutAndDeleteUser,
        positiveCaption: 'Yes',
        negativeCaption: 'No',
    });
}

async function execDeleteUser(userId: string) {
    try {
        await API.deleteUser(userId);

    } catch (ex) {
        Dialog.confirm({ title: 'Deleting User', message: 'Error: ' + ex.message });
    }
}

export function deleteOtherUser() {
    let appArgs = App().args;
    var row = appArgs[1] as User;
    if (!row || !row.id) {
        console.error('unexpected arguments');
        return;
    }
    const userId = row.id;

    Dialog.confirm({
        title: 'Are sure?',
        message: `If you continue you will delete '${row.name}' and all information associated with it permanently.`,
        onPositive: () => execDeleteUser(userId),
        positiveCaption: 'Yes',
        negativeCaption: 'No',
    });
}

export function signIn(provider?: string) {
    LoginService().signIn(provider);
}

export function signInWithMicrosoft() {
    const tenantId = process.env.REACT_APP_microsoft_tenant;
    LoginService().signIn('Microsoft', tenantId);
}

async function doImpersonateUser(userId: string) {

    var accessToken = await API.impersonate(userId);
    if (!accessToken) {
        Dialog.error({
            title: 'Error trying to impersonate user',
            message: 'Failed to impersonate user'
        });
        return;
    }

    // if (process.env.REACT_APP_FULLSTORY === 'on') {
    //     var state = Session.manager().state;
    //     var currUserName = state && state.user ? state.user.name : undefined;

    //     // FullStory
    //     window['FS'].setUserVars({
    //         displayName: currUserName,
    //         impersonate: userId
    //     });
    // }

    Session.manager().updateStatus(accessToken);
}

export function impersonate(context: string, args: any[]) {
    let appArgs = App().args;
    var row = appArgs[1] as User;
    if (!row || !row.id) {
        console.error('unexpected arguments');
        return;
    }

    var userId = row.id;
    console.log(`impersonate ${userId}`);

    Dialog.confirm({
        title: 'Are sure?',
        message: `You are about to impersonate ${row.name}`,
        onPositive: () => doImpersonateUser(userId),
        positiveCaption: 'Yes',
        negativeCaption: 'No',
    });
}

async function doMergeUsers(match: MergeUserCandidateMatch) {
    if (!match.user1 || !match.user2 || !match.user1.entityId || !match.user2.entityId) {
        console.error('missing entity ids');
        return;
    }

    var user = await API.mergeUsers([match.user1.entityId, match.user2.entityId]).catch(
        reason => {
            Dialog.error({
                title: 'Failed',
                message: 'Error merging identities'
            });
        }
    );

    if (user) {
        Dialog.inform({ title: 'Success', message: 'Merged identities' });
    }
}

export function mergeUsers(context: string, args: any[]) {
    var row: MergeUserCandidateMatch = args[0].row;

    Dialog.confirm({
        title: 'Are sure?',
        message: `You are about to merge two accounts. This operation can't be undone.`,
        onPositive: () => doMergeUsers(row),
        positiveCaption: 'Yes',
        negativeCaption: 'No',
    });
}

export async function importO365(context: string, args: any[]) {
    console.log('import from o365');
}

export async function importInspireNet(context: string, args: any[]) {
    console.log('import from inspirenet');
    var resp = await API.importInspireNetUsers().catch(reason => {
        Dialog.error({
            title: 'Failed',
            message: 'Error importing Users'
        });
    });

    if (resp) {
        Dialog.inform({ title: 'Success', message: 'Imported Users from InspireNet' });
    }
}