import { Session } from '../Session';

function link(provider: string, tenant?: string) {
    console.log('Link Account');

    var accessToken = Session.get().accessToken;
    var authority = process.env.REACT_APP_authority;

    var url = `${authority}/ExternalAccount/Add?provider=${provider}&returnUrl=${encodeURIComponent(document.location.href)}&token=${accessToken}`;
    if ( tenant ) url += `&tenant=${tenant}`;
    document.location.href = url;
}

export function addMicrosoft() {
    link('Microsoft');
}

export function addAccount() {
    const tenantId = process.env.REACT_APP_microsoft_tenant;
    link('Microsoft', tenantId);
}

export function addZoom() {
    link('Zoom');
}

export function addGoToMeeting() {
    link('GoToMeeting');
}

export function addSalesforce() {
    link('Salesforce');
}

export function addRealMagic() {
    link('RealMagic');
}