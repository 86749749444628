import * as api from '@crochik/schedulerapi.ts';
import { Base } from './api';

class MetaDataClient extends Base {
    protected get api() {
        return new api.MetaDataApi(this.getConfig());
    }

    all(): Promise<api.EntityMetadata[]> {
        return this.api.getAll();
    }

    getForOrg(organizationId: string): Promise<api.EntityMetadata[]> {
        return this.api.getByOrganization(organizationId);
    }

    addToOrg(organizationId: string, list: api.EntityMetadata[]): Promise<api.EntityMetadata[]> {
        return this.api.upsertToOrganization(organizationId, list);
    }

    removeFromOrg(organizationId: string, list: api.EntityMetadata[]): Promise<api.EntityMetadata[]> {
        return this.api.deleteFromOrganization(organizationId, list);
    }
}

export const metaData = new MetaDataClient();