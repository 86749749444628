import * as React from 'react';
import { action } from 'mobx';

import { LeadIntegration } from '@crochik/schedulerapi.ts';
import DataService from '@crochik/pi-react/services/DataService';
import { Form } from '@crochik/pi-react/ui/Form';
import { Panel } from '@crochik/pi-react/ui/material/Panel';
import { FormDialog } from '@crochik/pi-react/ui/material/FormDialog';
import { Action } from '@crochik/pi-react/context';
import * as api from '@crochik/schedulerapi.ts';

import Icon from '@material-ui/icons/MoreHoriz';
import { Typography, IconButton } from '@material-ui/core';

interface IProps {
    lead: api.Lead;
}

interface IState {
    integrations?: LeadIntegration[];
    inspirenetDialog?: Form;
}

export class Integrations extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {

        };
    }

    componentWillMount() {
        this.load();
    }

    async load() {
        const { lead } = this.props;

        const integrations: LeadIntegration[] = await DataService().select('LeadIntegrations', {
            query: {
                id: lead.id,
            }
        });

        this.setState({
            integrations
        });
    }

    onItegration = (integration: LeadIntegration) => {
        const { lead } = this.props;

        if (integration.integrationId === 'fc100000-0000-0000-0000-000000000000') {
            var form = Form.get('InspirenetDialog');
            form.resetValues();
            form.assignValues({
                id: lead.id,
                integrationId: integration.integrationId
            });
            this.setState({
                inspirenetDialog: form
            });
        }
    }

    renderIntegration(i: LeadIntegration) {
        const mapping = {
            'fc100000-0000-0000-0000-000000000000': this.onItegration
        };

        const { integrationId } = i;
        if (!integrationId) return;

        const clickable = !!mapping[integrationId];
        const className = clickable ? 'LeadIntegrations_clickable LeadIntegrations' : 'LeadIntegrations';
        const onClick = clickable ? () => mapping[integrationId](i) : undefined;

        return (
            <div
                key={integrationId}
                className={className}
                onClick={onClick}
            >
                <div className="LeadIntegrations_icon">
                    <img width="32" height="32" src={`/icons/${i.integrationId}.png`} />
                </div>
                <div style={{width: '100%'}}>
                    <Typography color="textPrimary" variant="body1">{i.status}</Typography>
                    <Typography color="textSecondary" variant="body2">{i.externalId}</Typography>
                </div>
                {clickable && <IconButton><Icon /></IconButton>}
            </div>
        );
    }

    @action
    onClose = () => {
        this.setState({
            inspirenetDialog: undefined,
        });
    }

    onAction = (action: Action) => {
        this.onClose();

        if (action.name === 'save') {
            const { inspirenetDialog } = this.state;

            if (inspirenetDialog) {
                return inspirenetDialog.execute(action);
            }
        }

        return true;
    }

    render() {
        const { integrations, inspirenetDialog } = this.state;
        if (!integrations) return <div />;

        return (
            <>
                <Panel title="Integrations">
                    {integrations.map((i) => this.renderIntegration(i))}
                </Panel>
                {inspirenetDialog &&
                    <FormDialog
                        form={inspirenetDialog}
                        onClose={this.onClose}
                        onActionClick={this.onAction}
                    />
                }
            </>
        );
    }
}
