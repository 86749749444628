import * as idp from 'pi-idp-api';
import { getAccessToken } from './api';

const idpServer = process.env.REACT_APP_authority;

function tokenApi(): idp.TokenApi {
    return new idp.TokenApi(new idp.Configuration({
        basePath: idpServer,
        accessToken: getAccessToken()
    }));
}

// function microsoftIdpApi(): idp.MicrosoftApi {
//     return new idp.MicrosoftApi(new idp.Configuration({
//         basePath: idpServer,
//         accessToken: getAccessToken()
//     }));
// }

export async function impersonate(userId: string): Promise<string | undefined> {
    var result = await tokenApi().impersonate({
        userId
    });
    return result ? result.accessToken : undefined;
}