import * as React from 'react';
import * as chartjs from 'chart.js';
import { Bar } from 'react-chartjs-2';

import './styles.css';
import { Card } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/UnfoldMore';
import ExpandLess from '@material-ui/icons/UnfoldLess';

export interface IBarStyle {
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: number;
    hoverBackgroundColor?: string;
    hoverBorderColor?: string;
}

interface Props {
    title: string;
    data?: chartjs.ChartData;
    onClick?: () => any;
    isSelected?: boolean;
    height?: number;
}

export const colors = [
    '33, 150, 243',
    '150, 150, 243',
    '150, 33, 243',
    '243, 33, 150',
    '243, 150, 150',
    '243, 150, 33',
    '33, 243, 150',
    '150, 243, 150',
    '150, 243, 33',
    '150, 243, 33',
    '33, 243, 33',
    '33, 243, 150',
];

export function initDataSet(label: string, style: IBarStyle): chartjs.ChartDataSets {
    const ds = {
        ...style,
        label: label,
        borderWidth: 1,
        data: []
    };

    return ds;
}

export function getStyle(rgbColor: string): IBarStyle {
    const style: IBarStyle = {
        backgroundColor: `rgba(${rgbColor}, .2`,
        borderColor: `rgba(${rgbColor}, 1)`,
        hoverBackgroundColor: `rgba(${rgbColor}, .5)`,
        hoverBorderColor: `rgba(${rgbColor}, 1)`,
    };

    return style;
}

export class BarChart extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }

    onClick = () => {
        const { onClick } = this.props;
        if (onClick) onClick();
    }

    render() {
        const { isSelected, data, title, onClick, height } = this.props;

        if (!data) {
            return (
                <Card className="DashboardBarChart">
                    {title}
                </Card>
            );
        }

        return (
            <Card className="DashboardBarChart" raised={isSelected} >
                <div className="DashboardBarChart_title" onClick={this.onClick}>
                    <span>{title}</span>
                    {onClick && (isSelected ? <ExpandLess/> : <ExpandMoreIcon/>)}
                </div>
                <div className="DashboardBarChart_graph">
                    <Bar
                        data={data}
                        width={100}
                        height={height || 250}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [{
                                    stacked: true
                                }],
                                yAxes: [{
                                    stacked: true
                                }]
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                            }
                        }}
                    />
                </div>
            </Card>
        );
    }    
}