import * as React from 'react';

import './styles.css';
import { Form, TYPE, ILookupFieldOptions, IForm } from '@crochik/pi-react';
import { FormBody } from '@crochik/pi-react/ui/material/FormBody';
import ActionButton from '@crochik/pi-react/ui/material/ActionButton';
import DataService from '@crochik/pi-react/services/DataService';
import * as API from '../../services/API';
import * as api from '@crochik/schedulerapi.ts';
import { toJS } from 'mobx';
import { ANY, UpdateStepResult } from '../../services/API';
import { ClickEvent, ObjectType } from './Event';
import { Dict } from '../../services/API/api';

interface ITriggerForm {
    trigger?: string;
    existingEvent?: string;
    triggerName?: string;
    eventName?: string;
    eventDescription?: string;
}

interface IActionForm {
    action?: string;
    description?: string;
}

interface ISelection {
    trigger: ITriggerForm;
    action: IActionForm;
    settings: object;
}

interface IProps {
    flow: API.FlowSteps;
    leadStatus: api.LeadStatus;
    launchEvent: ClickEvent;
    onClose: (result: api.FlowStep) => any;
}

interface IState {
    triggerEventId?: string;
    page: number;
    forms: Form[];
    hasSettings: boolean;
    events?: Dict<api.EventType>;
}

export class ChainReactionWizard extends React.Component<IProps, IState> {

    get form(): Form | undefined {
        const { forms, page } = this.state;
        return forms ? forms[page] : undefined;
    }

    constructor(props: IProps) {
        super(props);

        this.state = {
            page: 0,
            forms: [],
            hasSettings: false
        };
    }

    async componentDidMount() {
        const { launchEvent } = this.props;

        var triggerForm = this.triggerForm;
        triggerForm.resetValues();

        const triggerEventId = launchEvent.id;
        switch (launchEvent.objectType) {
            case ObjectType.UserEvent:
                if (!launchEvent.id) {
                    console.error('missing event id');
                    return;
                }
                triggerForm.assignValues({ trigger: triggerEventId });
                break;

            case ObjectType.Event:
                if (triggerEventId) {
                    triggerForm.assignValues({ trigger: 'event', existingEvent: triggerEventId });
                }
                break;

            default:
                return;
        }

        const events = await API.eventType.get();

        var form = new Form('NoFormError', { name: 'NoFormError', fields: [] });

        const forms = [
            triggerForm,
            triggerEventId ? this.createActionForm(events[triggerEventId]) : form,
            form,
        ];

        this.setState({
            page: triggerEventId ? 1 : 0,
            forms,
            triggerEventId,
            events
        });
    }

    get triggerForm(): Form {
        const eventTypeOptions: ILookupFieldOptions = {
            entity: 'FlowEvent',
            query: {
                query: { orderBy: 'name' },
            },
            valueProperty: 'id',
        };

        const triggerOptions: ILookupFieldOptions = {
            entity: 'FlowTrigger',
            query: {
                query: { orderBy: 'name' },
            },
            valueProperty: 'id',
            extraOptions: {
                // 'newAction': '-- New --',
                'event': '-- Event  --',
            },
        };

        const form: IForm = {
            title: 'New Chain Reaction',
            name: 'Add Event',
            fields: [
                {
                    name: 'trigger',
                    label: 'Trigger',
                    type: TYPE.LOOKUP,
                    options: triggerOptions,
                    isRequired: true
                },
                {
                    name: 'existingEvent',
                    label: 'Event',
                    type: TYPE.LOOKUP,
                    options: eventTypeOptions,
                    // defaultValue: 'new',
                    isRequired: true,
                    visible: [
                        'trigger==\'event\''
                    ]
                },
                {
                    name: 'triggerName',
                    label: 'Action Name',
                    type: TYPE.TEXT,
                    isRequired: true,
                    visible: [
                        'trigger==\'newAction\''
                    ]
                },
                {
                    name: 'eventName',
                    label: 'Event',
                    type: TYPE.TEXT,
                    visible: [
                        'triggerName',
                        'trigger==\'newAction\''
                    ]
                },
                {
                    name: 'eventDescription',
                    label: 'Description',
                    type: TYPE.TEXT,
                    options: { multline: true },
                    visible: [
                        'triggerName',
                        'trigger==\'newAction\''
                    ]
                },
            ],
        };

        return new Form('TriggerForm', form);
    }

    createActionForm(event: api.EventType): Form {
        const actionOptions: ILookupFieldOptions = {
            entity: 'FlowActions',
            query: {
                query: { orderBy: 'name' },
                args: [{ eventTypeId: event.id }]
            },
            valueProperty: 'id',
        };

        const form: IForm = {
            name: 'Reaction',
            fields: [
                {
                    name: 'action',
                    label: 'Reaction',
                    type: TYPE.LOOKUP,
                    options: actionOptions
                },
                {
                    name: 'description',
                    label: 'Description',
                    type: TYPE.TEXT,
                    options: { multline: true },
                    visible: ['action']
                },
            ],
        };

        return new Form('ActionForm', form);
    }

    async switchPage(index: number) {
        var { page, hasSettings, forms, triggerEventId, events } = this.state;

        // validation??
        // ...

        if (page === 0) {
            const eventForm = forms[0].values as ITriggerForm;
            const eventId = eventForm.trigger === 'event' ?
                eventForm.existingEvent :
                eventForm.trigger;

            if (!events || !eventId) {
                console.error('no event');
                return;
            }

            const selectedEvent = events[eventId];
            // console.log('selected event', selectedEvent, eventId);
            forms[1] = this.createActionForm(selectedEvent);

            this.setState({
                page: 1,
                forms,
                hasSettings,
                triggerEventId: eventId
            });
            return;
        }

        if (index === 2) {
            if (page === 1) {
                hasSettings = false;

                const actionForm = forms[1].values as IActionForm;
                if (!actionForm.action) {
                    console.error('no action');
                    return;
                }

                const form: IForm = await DataService().one('FlowActionForm', {
                    args: [{ actionId: actionForm.action, triggerEventId }]
                });

                if (form) {
                    forms[2] = new Form(form.name, form);
                    hasSettings = true;
                }

            } else if (!hasSettings) {
                // skip page
                index = 1;
            }
        }

        if (index >= forms.length) {
            this.setState({
                hasSettings,
                forms
            });

            await this.finish();
            return;
        }

        this.setState({
            page: index,
            forms,
            hasSettings
        });
    }

    async saveEvent(event: api.EventType): Promise<api.EventType> {
        event.id = undefined;
        event = await API.eventType.add(event);

        return event;
    }

    saveStep(flowId: string, step: api.FlowStepUpdate): Promise<UpdateStepResult> {
        return API.flow.add(flowId, step);
    }

    async addTrigger(result: ISelection): Promise<api.EventType | null> {
        const { flow } = this.props;

        if (!result.trigger.trigger) {
            console.error('missing trigger');
            return null;
        }

        var flowEvent: api.EventType;

        switch (result.trigger.trigger) {
            case 'newAction':
                var newEvent = await this.saveEvent({
                    name: result.trigger.eventName || result.trigger.triggerName,
                    description: result.trigger.eventDescription,
                    trigger: {
                        name: result.trigger.triggerName,
                        type: api.Trigger.TypeEnum.User
                    }
                });
                flowEvent = newEvent;
                break;

            case 'event':
                if (result.trigger.existingEvent) {
                    flowEvent = flow.eventTypeDict[result.trigger.existingEvent];
                } else {
                    console.error('missing event id');
                    return null;
                }
                break;

            default:
                // existing action
                flowEvent = flow.eventTypeDict[result.trigger.trigger];
                break;
        }

        return flowEvent;
    }

    async addAction(result: ISelection): Promise<api.FlowStep | null> {
        var trigger = await this.addTrigger(result);
        if (!trigger) {
            return null;
        }

        if (!result.action.action) {
            console.error('no action');
            return null;
        }

        const { flow, leadStatus } = this.props;
        const options: api.IActionOptions = {
            ...result.settings,
        };

        var addStep: api.FlowStepUpdate = toJS({
            eventIdTrigger: trigger.id,
            options,
            currentStatusId: leadStatus.id === ANY ? undefined : leadStatus.id,
            actionId: result.action.action,
            isUserTriggered: trigger.trigger && trigger.trigger.type === api.Trigger.TypeEnum.User,
            description: result.action.description
        });

        var stepResult = await this.saveStep(flow.flowId, addStep);
        if (stepResult.error || !stepResult.step) {
            console.error(stepResult.error);
            return null;
        }

        const { onClose } = this.props;
        onClose(stepResult.step);

        return stepResult.step;
    }

    async finish(): Promise<any> {
        const { forms, hasSettings } = this.state;

        var result: ISelection = {
            trigger: forms[0].values as ITriggerForm,
            action: forms[1].values as IActionForm,
            settings: hasSettings ? forms[2].values : {},
        };

        await this.addAction(result);
    }

    showPage = (index: number) => () => {
        this.switchPage(index);
    }

    render() {
        const { page, forms } = this.state;
        const last = page >= forms.length - 1;
        const form = this.form;

        if (!form) {
            return <div />;
        }

        return (
            <>
                <div key="form">
                    <FormBody form={form} />
                </div>
                <div key="footer" style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
                    {page === 0 ? (
                        <div key="back" />
                    ) : (
                            <ActionButton
                                color="primary"
                                variant="contained"
                                key="back"
                                title="Back"
                                action={this.showPage(page - 1)}
                            />
                        )}
                    <ActionButton
                        color={last ? 'secondary' : 'primary'}
                        variant="contained"
                        key="next"
                        title={last ? 'Finish' : 'Next'}
                        action={this.showPage(page + 1)}
                    />
                </div>
            </>
        );
    }
}
