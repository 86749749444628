// import { DataView } from '@crochik/pi-react/ui/DataView';

// import Branch from './Branch';
// import Tenant from './Tenant';
// import User from './User';
// import EventView from './Event';

export default function register() {
    // DataView.create('Branch', Branch);
    
    // DataView.create('Tenant', Tenant);
    // DataView.create('User', User);

    // DataView.create('Event', EventView);
}