function jsonFormat(day: number, month: number, year: number) {
    var str = `${year}-`;
    str += month < 9 ? `0${month + 1}-` : `${(month + 1)}-`;
    str += day < 10 ? `0${day}` : `${day}`;
    return str;
}

export function localtoDateString(date: Date): string {
    return jsonFormat(date.getDate(), date.getMonth(), date.getFullYear());
}

const DayOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function toLocalFormat(day: number, month: number, year?: number) {
    var str = month < 9 ? `0${month + 1}/` : `${(month + 1)}/`;
    str += day < 10 ? `0${day}` : `${day}`;
    if (year) str += `/${year}`;
    return str;
}

export function utcToDate(date: Date): string {
    return toLocalFormat(date.getUTCDate(), date.getUTCMonth()); // , date.getUTCFullYear()
}

export function utcToLongDate(date: Date): string {
    var format = toLocalFormat(date.getUTCDate(), date.getUTCMonth()); // , date.getUTCFullYear()
    return `${DayOfTheWeek[date.getUTCDay()]} ${format} `;
}

export function minutesToString(minutes: number) {
    var hour = Math.floor(minutes / 60);
    var min = minutes % 60;
    var am = minutes >= 720 ? 'PM' : 'AM';
    var time = `${hour > 12 ? hour - 12 : hour}:${min < 10 ? '0' + min : min} ${am}`;
    
    return time;
}

export function minutesToT24(minutes: number): string {
    var min = minutes % 60;
    var hour = (minutes - min) / 60;
    return `${hour < 10 ? '0' + hour : hour}:${min < 10 ? '0' + min : min}`;
}

export function t24ToMinutes(str: string): number {
    if (!str || str.length < 1) {
        return -1;
    }

    var hours = parseInt(str.substr(0, 2), 10);
    var min = parseInt(str.substr(3, 2), 10);
    return hours * 60 + min;
}
