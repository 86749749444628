import * as React from 'react';
import { Card, CardContent, CardHeader, Typography, PropTypes } from '@material-ui/core';
import * as api from '@crochik/schedulerapi.ts';
import * as moment from 'moment';

import './Agenda.css';
import App from '@crochik/pi-react/application/App';

interface Props {
    users: api.User[];
    appointments: api.Appointment[];
    showEmptyDays: boolean;
    showUserName: boolean;
}

interface User {
    slots: api.Appointment[];
}

interface Day {
    dateStr: string;
    users: User[];
    date: moment.Moment;
}

interface State {
    days?: Day[];
    showEmptyUser: boolean;
    showEmptyDays: boolean;
    showUserName: boolean;
}

export class Agenda extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showEmptyUser: false,
            showEmptyDays: props.showEmptyDays,
            showUserName: props.showUserName
        };
    }

    async componentWillReceiveProps(props: Props) {
        this.setState({
            showEmptyDays: props.showEmptyDays,
            showUserName: props.showUserName,
            days: undefined
        });

        await this.load(props);
    }

    async componentWillMount() {
        await this.load(this.props);
    }

    async load(props: Props) {
        var dict: { [id: string]: number } = {};
        for (var c = 0; c < props.users.length; c++) {
            var user = props.users[c];
            if (!user || !user.id) continue;
            dict[user.id] = c;
        }

        var days: Day[] = [];
        var currentDay: string | undefined = undefined;
        var day: Day | undefined = undefined;
        for (var appt of props.appointments) {
            var date = moment(appt.start).startOf('day');
            var dateStr = date.format('ddd MM/DD');
            if (!currentDay || currentDay !== dateStr) {
                if (day) {
                    // add missing days
                    for (var dt = day.date.clone().add(1, 'day'); dt.isBefore(date); dt.add(1, 'day')) {
                        days.push({
                            date: dt.clone(),
                            dateStr: dt.format('ddd MM/DD'),
                            users: []
                        });
                    }
                }

                currentDay = dateStr;
                day = {
                    date: date.clone(),
                    dateStr,
                    users: []
                };
                days.push(day);
            }

            if (!day || !appt.userId) throw 'error';

            var index = dict[appt.userId];
            if (!day.users[index]) day.users[index] = { slots: [] };
            day.users[index].slots.push(appt);
        }

        this.setState({
            days
        });
    }

    onClick = (appt: api.Appointment) => {
        // App().selectPage(`Appointment=${appt.id}`, '', { id: appt.id });
        App().selectPage(`Lead=${appt.leadId}`, '', { id: appt.leadId });
    }

    renderSlot(appt: api.Appointment) {
        var className = 'Agenda_subject';
        if (appt.state === api.Appointment.StateEnum.Cancelled) {
            className += ' Agenda_cancelled';
        }

        return (
            <div key={appt.id} className="Agenda_appointment" onClick={() => this.onClick(appt)}>
                <div className="Agenda_time">{moment(appt.start).format('LT')}<br />{moment(appt.end).format('LT')}</div>
                <div className={className}>{appt.subject}</div>
            </div>
        );
    }

    renderUserSlots(day: Day, index: number) {
        if (!day.users[index] || day.users[index].slots.length < 1) {
            return <div className="Agenda_user Agenda_empty" />;
        }

        var user = this.props.users[index];
        var userSlots: User = day.users[index];
        return (
            <div key={`${day.dateStr}${index}`} className="Agenda_user">
                {this.state.showUserName && <Typography color="default" variant="subtitle1">{user.name}</Typography>}
                {userSlots.slots.map(appt => this.renderSlot(appt))}
            </div>
        );
    }

    renderUsers(users: api.User[]) {
        if (!this.state.showUserName && users.length < 2) return undefined;
        return (
            <div className="Agenda_row">
                {
                    users.map(user => (
                        <div key={user.id} className="Agenda_user Agenda_userheader">
                            <Typography color="default" variant="subtitle1">{user.name}</Typography>
                        </div>
                    ))
                }
            </div>
        );
    }

    renderDay(day: Day): JSX.Element | undefined {
        var users: api.User[] = [];
        var indice: number[] = [];
        for (var c = 0; c < this.props.users.length; c++) {
            if (this.state.showEmptyUser || day.users[c]) {
                users.push(this.props.users[c]);
                indice.push(c);
            }
        }

        var className = 'Agenda_day';
        var color: PropTypes.Color = 'primary';
        if (day.date.day() === 0) {
            className += ' Agenda_sunday';
            color = 'secondary';
        }

        if (day.users.length < 1) {
            if (!this.state.showEmptyDays) return undefined;
            color = 'default';
        }

        return (
            <Card key={day.dateStr} className={className}>
                <CardHeader title={<Typography color={color} variant="subtitle1">{day.dateStr}</Typography>} style={{ textAlign: 'center' }} />
                {
                    day.users.length > 0 &&
                    <CardContent>
                        {/* {this.renderUsers(users)} */}
                        <div className="Agenda_row">
                            {
                                indice.map(index => this.renderUserSlots(day, index))
                            }
                        </div>
                    </CardContent>
                }
            </Card>
        );
    }

    render() {
        if (!this.state.days) return <div className="Agenda">Loading...</div>;
        if (this.state.days.length === 0) return <div className="Agenda">Sorry, nothing to look forward to... :(</div>;

        var weeks: JSX.Element[][] = [];
        var week: JSX.Element[] | undefined = undefined;
        var all: JSX.Element[] = [];
        for (var item of this.state.days) {
            var day = this.renderDay(item);
            if (!day) continue;
            if (item.date.day() === 0 || !week) {
                week = [];
                weeks.push(week);
            }

            week.push(day);
            all.push(day);
        }

        if (!this.props.showEmptyDays) {
            return (
                <div className="Agenda">{all}</div>
            );
        }

        return (
            <div className="Agenda">
                {weeks.map((week, index) => (
                    <div key={index} className="Agenda_week">{week}</div>
                ))}
            </div>
        );
    }
}