import * as React from 'react';
import { CustomPage } from '@crochik/pi-react/application/Page';
import { DataView } from '@crochik/pi-react/ui/DataView';
import { DataGridComponent } from '@crochik/pi-react/ui/material/DataGridComponent';
import DataService from '@crochik/pi-react/services/DataService';
import App from '@crochik/pi-react/application/App';
import * as api from '@crochik/schedulerapi.ts';
import { IDataView } from '@crochik/pi-react/context/IDataView';
import * as API from '../services/API';
import { Select } from '@material-ui/core';
import { observer } from 'mobx-react';

interface Props {
    reportName: string;
}

interface State {
    reportName?: string;
    report?: api.Report;
    filter?: string;
    dataView?: DataView;
}

@observer
class ReportComponent extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
        };
    }

    componentWillMount() {
        const { reportName } = this.props;
        this.load(reportName);
    }

    componentWillReceiveProps(props: Props) {
        const { reportName } = props;

        if (reportName !== this.state.reportName) {
            this.load(reportName);
        }
    }

    async load(reportName: string, filter?: string) {
        var report = await DataService().select('Report', {
            args: [
                reportName,
                filter
            ]
        });

        if (!report) {
            console.error('failed to load');
            return;
        }

        var view: IDataView = {
            ...report.view,
            actions: [
                {
                    name: 'Export'
                }
            ]
        };

        var dataView: DataView = new DataView('Report', view);
        dataView.bindAction('Export', this.exportReport);
        dataView.onloaded(report.rows);

        this.setState({
            reportName,
            filter,
            report,
            dataView
        });
    }

    exportReport = () => {
        const { reportName } = this.props;
        const { filter } = this.state;

        if (!reportName) return;

        API.exportReport(reportName, filter).then(async (resp: Response) => {
            if (!resp.ok) return;

            const csv = await resp.text();
            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            var link = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', `${reportName}.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    }

    render() {
        const { dataView } = this.state;
        if (!dataView) return null;

        return (
            <DataGridComponent
                view={dataView}
                skipLoad={true}
                headerComponent={this.options()}
            />
        );
    }

    onFilterChange = (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
        const { reportName } = this.props;
        if (!reportName) return;

        this.setState({
            dataView: undefined,
        });

        const filter = event.target.value;
        this.load(reportName, filter);
    }

    options() {
        const { report } = this.state;
        if (!report || !report.filters) return undefined;

        return (
            <Select
                native={true}
                value={report.currentFilter}
                onChange={this.onFilterChange}
                inputProps={{
                    name: 'filter',
                    id: 'filter',
                }}
            >
                {report.filters.map(f => <option key={f.value} value={f.value}>{f.name}</option>)}
            </Select>
        );
    }
}

@observer
class PageComponent extends React.Component<{}, State> {
    render() {
        var reportName = App().args[1].id;
        return (
            <div className="pi-page">
                <ReportComponent reportName={reportName} />
            </div>
        );
    }
}

export default (name: string) => new CustomPage({ name, label: 'Report' }, <PageComponent />);