import * as React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

import { Integration } from './Integration';
import DataService from '@crochik/pi-react/services/DataService';
import * as api from '@crochik/schedulerapi.ts';

import { Form } from '@crochik/pi-react/ui/Form';
import { FormDialog } from '@crochik/pi-react/ui/material/FormDialog';
import { Action } from '@crochik/pi-react/context';

export type Type = 'AppointmentType' | 'LeadType' | 'Account' | 'Org' | 'User';

interface Props {
    id?: string;
    type: Type;
}

interface State {
    integrations?: api.IIntegration[];
    form?: Form;
    selected?: { [id: string]: api.IIntegration };
}

const available: { [type: string]: { [integration: string]: boolean } } = {
    'AppointmentType': {
        Slack: true, SendGrid: true, Zoom: true, GoToMeeting: true
    },
    'LeadType': {
        Slack: true, SendGrid: true, AutoSchedule: true, Lumin: true
    },
    'Account': {
        Slack: true, SendGrid: false, Zoom: false, AutoSchedule: false, GoToMeeting: false, Lumin: false
    },
    'Org': {
        Slack: true, SendGrid: true, Zoom: true, AutoSchedule: true, GoToMeeting: true, Lumin: true
    }
};

export default class Integrations extends React.Component<Props, State> {
    map: { [integration: string]: boolean };

    constructor(props: Props) {
        super(props);

        this.state = {

        };

        this.map = available[props.type];
    }

    async componentWillMount() {
        var result = await DataService().select('Integrations') as api.IIntegration[];

        var selectedIntegrations = await DataService().select(`${this.props.type}Integrations`, { args: [this.props.id] }) as api.IIntegration[];
        var selected: { [id: string]: api.IIntegration } = {};
        for ( var i of selectedIntegrations ) {
            if ( !i.id ) continue;
            selected[i.id] = i;
        }

        this.setState({
            integrations: result.filter(i => i.serviceName && this.map[i.serviceName]),
            selected
        });
    }

    onIntegration = async (i: api.IIntegration) => {
        if (!i.serviceName) return;

        var record: object | undefined;

        var key = `${i.serviceName}${this.props.type}Integration`;
        var enabled: boolean = false;

        try {
            record = await DataService().one(key, { args: [null, this.props.id] });
            enabled = true;

        } catch (ex) {
            if (ex.status !== 404) {
                // TODO: getting a json parsing error instead of the 204
                // change the API to return something different
                console.error(ex);
            } else {
                console.log(`${i.serviceName} integration is not configured for ${this.props.id}`);
            }
        }

        var form = Form.get(key);
        form.resetValues();
        form.assignValues({
            ...record,
            id: this.props.id,
            enabled 
        });
        form.bindAction('Cancel', this.closeDialog);
        this.setState(
            {
                form
            }
        );
    }

    closeDialog = () => {
        this.setState({ form: undefined });
    }

    onAction = (action: Action) => {
        this.closeDialog();

        if (this.state.form) {
            return this.state.form.execute(action);
        }

        return false;
    }

    renderIntegration(i: api.IIntegration) {
        var checked = (this.state.selected && i.id && this.state.selected[i.id]) ? true : false;
        return <Integration key={i.id} integration={i} checked={checked} onClick={this.onIntegration} />;
    }

    render() {
        return (
            <div className="Settings">
                <Card>
                    <CardContent>
                        <Typography color="primary" variant="h6">Integrations</Typography>
                        {this.state.integrations && this.state.integrations.map(i => this.renderIntegration(i))}
                    </CardContent>
                </Card>
                {
                    this.state.form &&
                    <FormDialog form={this.state.form} onClose={this.closeDialog} onActionClick={this.onAction} />
                }
            </div>
        );
    }
}