import * as React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import * as moment from 'moment';

import * as api from '@crochik/schedulerapi.ts';

import { CustomPage } from '@crochik/pi-react/application/Page';
import { Calendar, CalendarProps, DaySlots, Slot } from '@crochik/pi-react/ui/material/Calendar';
import DataService from '@crochik/pi-react/services/DataService';

import { minutesToString } from '../../../services/date';
import { AppointmentType } from '@crochik/schedulerapi.ts';
import { Session } from '../../../Session';
import App from '@crochik/pi-react/application/App';

import './styles.css';
import { IGetUserAvailability } from '../../../services/API';

interface Props {
    splitOpenSlots?: boolean;
    appointmentType?: AppointmentType;
    userId?: string;
    collapseDays?: boolean;
    onClick?: (evt: Slot) => any;
    loadMore?: boolean;
}

interface BoundState {
    calendarProps?: CalendarProps;
    loadMore: boolean;
}

interface IConvert {
    start: number;
    end: number;
    splitOpenSlots: boolean;
    interval: number; // this.boundState.calendarProps ? this.boundState.calendarProps.interval : 30
    appointmentType?: AppointmentType;
    minDate: moment.Moment;
    maxDate: moment.Moment;
}

function convertToDays(convert: IConvert, data: api.EventsAndSlots): DaySlots[] {
    const days: DaySlots[] = [];
    const dict: { [id: string]: DaySlots } = {};

    var min = convert.minDate;
    var max = convert.maxDate;
    for (var day = min.clone(); day.isBefore(max); day.add(1, 'day')) {
        const id = day.format('YYYY-MM-DD');
        const slot = {
            title: day.format('ddd, MM-DD'),
            id,
            slots: []
        };

        dict[id] = slot;
        days.push(slot);
    }

    if (data.events) {
        data.events.forEach((record) => {
            if (record.isAllDay) {
                console.log('All Day Event: ignore for now');
                let start = moment(record.start).utc();
                let end = moment(record.end).utc();
                let slotStart = convert.start;
                let slotDuration = convert.end - convert.start;
                for (var date = start.clone(); date.diff(end) < 0; date = date.add(1, 'day')) {
                    const id = date.format('YYYY-MM-DD');
                    if (!(id in dict)) {
                        console.log(`din't find in dict ${id}`);
                        continue;
                    }
                    dict[id].slots.push({
                        date: id,
                        start: slotStart,
                        duration: slotDuration,
                        id: record.id ? record.id.toString() : 'error_no_id',
                        title: record.subject,
                        tag: { ...record, _type: 'AllDayEvent' },
                        className: 'CalendarPage_allDay clickable'
                    });
                }
                return;
            }

            const start = moment(record.start);
            const end = moment(record.end);
            let startOfDay = start.clone().startOf('day');

            const id = startOfDay.format('YYYY-MM-DD');

            let day = dict[id];
            if (!day) {
                console.log(`date out of range: ${id}`);
                return;
            }

            var className = 'pi-calendar_open pi-calendar_hover truncate-text';
            if (record.showAs !== 2) {
                className += ' FullCalendar_notbusy';
            } else if (record.source === 'pi') {
                className += ' FullCalendar_appt';
            } else {
                className += ' FullCalendar_notbusy';
            }

            var intervalStart = start.diff(startOfDay, 'minutes');
            var intervalDuration = end.diff(start, 'minutes');
            day.slots.push({
                date: id,
                start: intervalStart,
                duration: intervalDuration,
                id: `${id}-${start}`,
                title: record.subject,
                tag: { ...record, _type: 'Event' },
                className
            });
        });
    }

    if (data.slots) {
        data.slots.forEach((record) => {
            const start = moment(record.start);
            const end = moment(record.end);
            let startOfDay = start.clone().startOf('day');

            const id = startOfDay.format('YYYY-MM-DD');

            let day = dict[id];
            if (!day) {
                console.log(`date out of range: ${id}`);
                return;
            }
            let startMinutes = start.diff(startOfDay, 'minutes');
            let duration = end.diff(start, 'minutes');

            if (convert.splitOpenSlots) {
                // break slot
                var off = convert.interval;
                var apptDuration = (convert.appointmentType && convert.appointmentType.settings && convert.appointmentType.settings.duration) ?
                    convert.appointmentType.settings.duration : off;

                for (var c = 0; c <= duration - apptDuration; c += off) {
                    day.slots.push({
                        date: id,
                        start: startMinutes + c,
                        duration: apptDuration,
                        id: `${id}-${startMinutes + c}`,
                        title: minutesToString(startMinutes + c), // + ' - ' + minutesToString(startMinutes + c + duration),
                        tag: {
                            date: id,
                            start: startOfDay.clone().add(startMinutes + c, 'minutes'),
                            end: startOfDay.clone().add(startMinutes + c + apptDuration, 'minutes'),
                            duration: apptDuration,
                            slot: record,
                            _type: 'OpenSlot'
                        },
                        className: 'FullCalendar_available pi-calendar_open pi-calendar_hover'
                    });
                }

            } else {
                // add full slot
                var slot: Slot = {
                    date: id,
                    start: startMinutes,
                    duration: duration,
                    id: `${id}-${startMinutes}`,
                    title: minutesToString(startMinutes) + ' - ' + minutesToString(startMinutes + duration),
                    tag: { ...record, _type: 'OpenSlot' },
                    className: 'FullCalendar_available pi-calendar_open pi-calendar_hover'
                };

                day.slots.push(slot);
            }
        });
    }

    days.forEach((day) => {
        day.slots = day.slots.sort((a, b) => a.start - b.start);
    });

    return days;
}

@observer
export class FullCalendar extends React.Component<Props> {
    boundState: BoundState;
    start: number;
    end: number;

    constructor(props: any) {
        super(props);

        var wh = Session.get().workingHours;

        this.start = wh && wh.startMinutes ? wh.startMinutes : 8 * 60;
        this.end = wh && wh.endMinutes ? wh.endMinutes : 19 * 60;

        this.boundState = observable({
            calendarProps: undefined,
            loadMore: !!this.props.loadMore
        });
    }

    componentDidMount() {
        this.loadAsync(this.props);
    }

    componentWillReceiveProps(nextProps: Props) {
        if ((nextProps.appointmentType && this.props.appointmentType && this.props.appointmentType.id !== nextProps.appointmentType.id) ||
            (this.props.appointmentType && !nextProps.appointmentType) ||
            (!this.props.appointmentType && nextProps.appointmentType) ||
            (this.props.userId !== nextProps.userId)) {
            this.loadAsync(nextProps);
        }
    }

    @action
    async loadAsync(props: Props, loadMore?: boolean) {
        const numberOfDays = 14;
        const startOfToday = moment().startOf('day');

        var start = startOfToday;
        var end = startOfToday.clone().add('days', numberOfDays);
        if (loadMore) {
            start = end;
            end = startOfToday.clone().add('days', numberOfDays * 2);
        } else {
            this.boundState.calendarProps = undefined;
        }

        var query: any = undefined;
        if (props.userId && props.appointmentType) {
            const options: IGetUserAvailability = {
                userId: props.userId as string,
                appointmentTypeId: props.appointmentType.id as string,
                start: start.toDate(),
                end: end.toDate()
            };
            query = {
                args: ['User', options]
            };
        }

        var records: api.EventsAndSlots = await DataService().select('FullCalendar', query);

        var days = convertToDays(
            {
                start: this.start,
                end: this.end,
                interval: 30, // this.boundState.calendarProps ? this.boundState.calendarProps.interval : 30,
                appointmentType: props.appointmentType,
                splitOpenSlots: !!props.splitOpenSlots,
                minDate: start,
                maxDate: end
            },
            records
        );

        if (loadMore && this.boundState.calendarProps && this.boundState.calendarProps.days) {
            this.boundState.calendarProps.days = this.boundState.calendarProps.days.concat(days);
            this.boundState.loadMore = false;
        } else {
            this.init(days);
        }
    }

    init(days: DaySlots[]) {
        var props: CalendarProps = {
            // calendar1 only
            start: this.start,
            end: this.end,
            interval: 30,
            offY: 30,
            height: 30,

            // common
            showSlotLabels: true,
            selectedSlots: {},
            onClick: this.onClick,
            days,
            collapseDays: this.props.collapseDays
        };

        this.boundState.calendarProps = props;
    }

    async showLead(evt: api.CalendarEvent) {
        var appt: api.Appointment = await DataService().one('Appointment', {
            args: ['Appointment', { id: evt.id }]
        });

        if (appt) {
            App().selectPage(`Lead=${appt.leadId}`, '', { id: appt.leadId });
        } else {
            App().selectPage(`Appointment=${evt.id}`, '', { id: evt.id });
        }
    }

    onClick = (evt: Slot) => {
        if (this.props.onClick) {
            this.props.onClick(evt);
            return;
        }

        if (evt.tag && evt.tag._type) {
            console.log(evt);
            // if ('webLink' in evt.tag) {
            //     // event
            //     window.open(evt.tag['webLink'], '_blank');
            //     return;
            // }

            const type = evt.tag._type;
            switch (type) {
                // case 'OpenSlot':
                //     App().selectPage('MyAvailability');
                //     break;

                case 'AllDayEvent':
                case 'Event':
                    const singleEvent = evt.tag as api.CalendarEvent;
                    if (singleEvent.source === 'pi') {
                        this.showLead(singleEvent);

                    } else if (singleEvent.webLink) {
                        window.open(evt.tag['webLink'], '_blank');
                    }
                    break;

                default:
                    break;
            }
        }
    }

    @action
    onLoadMore = () => {
        this.loadAsync(this.props, true);
    }

    render() {
        const { loadMore } = this.boundState;

        if (!this.boundState.calendarProps) return <div>Loading</div>;
        return (
            <Calendar {...this.boundState.calendarProps} >
                {loadMore &&
                    <div onClick={this.onLoadMore} className="FullCalendar_loadMore">
                        <div>More</div>
                    </div>
                }
            </Calendar>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <FullCalendar />);