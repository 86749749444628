import * as React from 'react';
import { Form, IForm, TYPE } from '@crochik/pi-react';
import { FormBody } from '@crochik/pi-react/ui/material/FormBody';
import ActionButton from '@crochik/pi-react/ui/material/ActionButton';
import DataService from '@crochik/pi-react/services/DataService';
import * as API from '../../services/API';
import * as api from '@crochik/schedulerapi.ts';
// import { toJS } from 'mobx';
// import { ANY, AddStepResult } from '../../services/API';
import { ClickEvent } from './Event';

import './styles.css';
import { toJS } from 'mobx';

interface IProps {
    flow: API.FlowSteps;
    leadStatus: api.LeadStatus;
    launchEvent: ClickEvent;
    onClose: (result?: api.FlowStep) => any;
}

interface IState {
    step?: api.FlowStep;
    form?: Form;
}

interface IMainForm {
    name: string;
    description?: string;
}

export class ReactionWizard extends React.Component<IProps, IState> {
    _forms: Form[];

    get main(): Form {
        const form: IForm = {
            name: 'Reaction',
            fields: [
                {
                    name: 'name',
                    label: 'Reaction',
                    type: TYPE.TEXT,
                    enable: ['false']
                },
                {
                    name: 'description',
                    label: 'Description',
                    type: TYPE.TEXT,
                    options: { multline: true },
                },
            ],
        };

        return new Form('ActionForm', form);
    }

    constructor(props: IProps) {
        super(props);

        this._forms = [
            this.main
        ];

        this.state = {
        };
    }

    async componentDidMount() {
        const { flow, launchEvent } = this.props;
        const step = flow.flowStepsList.find(x => x.id === launchEvent.id);

        let form: Form | undefined = undefined;
        if (step && step.actionId && step.options) {
            const action = flow.flowActionDict[step.actionId];
            const iform: IForm = await DataService().one('FlowActionForm', {
                args: [{ actionId: step.actionId, triggerEventId: step.eventIdTrigger }]
            });

            form = iform ? new Form(iform.name, iform) : undefined;
            this._forms[0].assignValues({
                name: action ? action.name : undefined,
                description: step.description,
            });

            if (form) {
                form.assignValues(step.options);
                this._forms[1] = form;
            }
        }

        this.setState({ step, form });
    }

    saveStep = async () => {
        const { flow, onClose } = this.props;
        const { step, form } = this.state;

        if (!step || !form) {
            console.error('invalid state');
            return;
        }

        const main = toJS(this._forms[0].values) as IMainForm;
        const update = toJS(step);
        update.description = main.description;
        update.options = toJS(form.values);

        console.log('save', update);
        var stepResult = await API.flow.update(flow.flowId, update);
        if (stepResult.error || !stepResult.step) {
            console.error(stepResult.error);
            return;
        }

        onClose();
    }

    deleteStep = async () => {
        const { flow, onClose } = this.props;
        const { step } = this.state;
        if (!flow || !step || !step.id) return;

        try {
            await API.flow.delete(flow.flowId, step.id);
            onClose();

        } catch (resp) {
            console.error(resp);
        }
    }

    render() {
        const { step, form } = this.state;

        if (!step || !step.options) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <>
                <div key="form" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ paddingRight: 24 }}>
                        <FormBody form={this._forms[0]} />
                    </div>
                    {form && <FormBody form={form} />}
                </div>
                <div key="footer" style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
                    <ActionButton
                        color="secondary"
                        variant="contained"
                        key="Delete"
                        title="Delete"
                        action={this.deleteStep}
                    />
                    <ActionButton
                        color="primary"
                        variant="contained"
                        key="Save"
                        title="Save"
                        action={this.saveStep}
                    />
                </div>
            </>
        );
    }
}