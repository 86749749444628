import * as React from 'react';

import { Grid } from '@material-ui/core';

import { CustomPage } from '@crochik/pi-react/application/Page';
import { Form } from '@crochik/pi-react/ui/Form';
import App from '@crochik/pi-react/application/App';
import { ReadOnlyForm } from '@crochik/pi-react/ui/material/ReadOnlyForm';

import { Integrations } from './Integrations';
import { Appointments } from './Appointments';
import { History } from './History';

import { PopupDialog } from '../Comps/PopupDialog';

import { loadLead, createLeadForm } from '../utils';

import { Lead } from '@crochik/schedulerapi.ts';

import '../TabPage.css';
import './Lead.css';

import { observer } from 'mobx-react';

interface State {
    form?: Form;
    selectedTab: number;
    leadTypeId?: string;
    lead?: Lead;

    showHistory: boolean;
}

@observer
class PageComponent extends React.Component<{}, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            form: undefined,
            selectedTab: 0,
            showHistory: false
        };
    }

    async componentWillMount() {
        let args = App().args;
        let lead = args[1];

        var leads = await loadLead(lead.id);
        if (!leads || !leads.list) return;

        var form = createLeadForm(leads);
        if (form) {
            form.bindAction('History', this.toggleHistory);
        }

        this.setState({
            form: form,
            leadTypeId: leads.leadTypeId,
            lead: leads.list[0]
        });
    }

    toggleHistory = () => {
        this.setState({
            showHistory: !this.state.showHistory
        });
    }

    render() {
        const { lead, showHistory } = this.state;

        return (
            <>
                <div className="ContentPage_scrollable">
                    <Grid container={true} spacing={16}>
                        <Grid item={true} lg={8} sm={6} xs={12}>
                            {this.state.form &&
                                <ReadOnlyForm
                                    form={this.state.form}
                                    hideNullFields={true}
                                />
                            }
                        </Grid>

                        {lead &&
                            <Grid item={true} lg={4} sm={6} xs={12} >
                                <Appointments lead={lead} />
                                <br />
                                <Integrations lead={lead} />
                            </Grid>
                        }
                    </Grid>
                </div>
                {
                    showHistory &&
                    <PopupDialog
                        open={showHistory}
                        title="History"
                        onClose={this.toggleHistory}
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        fullScreen="auto"
                        maxWidth="md"
                    >
                        {lead &&
                            <History lead={lead} />
                        }
                    </PopupDialog>
                }
            </>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <PageComponent />);