import * as React from 'react';
import { observer } from 'mobx-react';

interface IProps {
    className?: string;
    onFilesDropped: (files: File[]) => any;
}

interface IState {
    isDraggingOver: boolean;
}

@observer
export class FileDropTarget extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isDraggingOver: false
        };
    }

    loadFile(files: File[]) {
        const { onFilesDropped } = this.props;
        if (onFilesDropped) {
            onFilesDropped(files);
        }
    }

    onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isDraggingOver: true,
        });
    }

    onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        this.setState({
            isDraggingOver: true,
        });
    }

    onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        this.setState({
            isDraggingOver: false,
        });
    }

    onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const list: File[] = [];
            for (var c = 0; c < e.dataTransfer.files.length; c++) {
                list.push(e.dataTransfer.files[c]);
            }
            this.loadFile(list);
        }

        this.setState({
            isDraggingOver: false,
        });
    }

    render() {
        const { className } = this.props;
        const { isDraggingOver } = this.state;

        const style = isDraggingOver ? {
            border: '6px dashed #2196f3',
            cursor: 'grab',
        } : {};

        return (
            <div
                style={style}
                className={className || 'FileDropTarget'}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
            >
                {this.props.children}
            </div>
        );
    }
}