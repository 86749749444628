import { Default } from '@crochik/pi-react/context';
import Dialog from '@crochik/pi-react/services/Dialog';

import * as API from '../services/API';
import { LuminIntegrationData } from '@crochik/schedulerapi.ts';

export function saveSendGrid(context: string) {
    var record = Default.state.get(context) as API.ISendGridAppointmentIntegration;
    console.log(record);
    
    API.enableSendGridOnOrg(record)
        .then( ok => {
            Dialog.inform({title: 'Success', message: 'SendGrid integration successfully configured.'});
        })
        .catch( ex => {
            Dialog.critical({title: 'Enabling Integration', message: 'Error trying to configure SendGrid integration.'});
        });
}

export async function saveZoom(context: string) {
    var ready = await API.hasZoomAccount();
    if ( !ready ) {
        Dialog.critical({title: 'Zoom Integration', message: 'You need to Add Zoom credentials to your account'});
        return;
    }

    var record = Default.state.get(context); 
    console.log(record);
    
    API.enableZoomOnOrg(record)
        .then( ok => {
            Dialog.inform({title: 'Success', message: 'Zoom integration successfully configured.'});
        })
        .catch( ex => {
            Dialog.critical({title: 'Enabling Integration', message: 'Error trying to configure Zoom integration.'});
        });
}

export async function saveGoToMeeting(context: string) {
    var ready = await API.hasGoToMeetingAccount();
    if ( !ready ) {
        Dialog.critical({title: 'GoToMeeting Integration', message: 'You need to Add GoToMeeting credentials to your account'});
        return;
    }

    var record = Default.state.get(context); 
    console.log(record);
    
    API.enableGoToMeetingOnOrg(record)
        .then( ok => {
            Dialog.inform({title: 'Success', message: 'GoToMeeting integration successfully configured.'});
        })
        .catch( ex => {
            Dialog.critical({title: 'Enabling Integration', message: 'Error trying to configure GoToMeeting integration.'});
        });
}

export async function saveAutoSchedule(context: string) {
    var record = Default.state.get(context); 
    console.log(record);
    
    API.enableAutoScheduleOnOrg(record)
        .then( ok => {
            Dialog.inform({title: 'Success', message: 'AutoScheduler successfully configured.'});
        })
        .catch( ex => {
            Dialog.critical({title: 'Enabling Integration', message: 'Error trying to enable AutoScheduler.'});
        });
}

export async function saveLumin(context: string) {
    var record = Default.state.get(context) as LuminIntegrationData;
    console.log(record);
    
    API.enableLuminOnOrg(record)
        .then( ok => {
            Dialog.inform({title: 'Success', message: 'Lumin successfully configured.'});
        })
        .catch( ex => {
            Dialog.critical({title: 'Enabling Integration', message: 'Error trying to enable Lumin.'});
        });
}

function disableIntegration(serviceName: string ) {
    console.log(`Disable Integration ${serviceName}`);

    API.disableOrgIntegration(serviceName)
        .then( ok => {
            Dialog.inform({title: 'Success', message: `${serviceName} integration disabled.`});
        })
        .catch( ex => {
            console.error(ex);
            Dialog.critical({title: 'Disable Integration', message: `Error trying to disable ${serviceName} for Appointment Type.`});
        });
}

export function disableZoom(context: string) {
    disableIntegration('Zoom');
}
export function disableSendGrid(context: string) {
    disableIntegration('SendGrid');
}
export function disableGoToMeeting(context: string) {
    disableIntegration('GoToMeeting');
}
export function disableAutoSchedule(context: string) {
    disableIntegration('AutoSchedule');
}
export function disableLumin(context: string) {
    disableIntegration('Lumin');
}