import * as React from 'react';
import { CustomPage } from '@crochik/pi-react/application/Page';
import { LinearProgress } from '@material-ui/core';

class PageComponent extends React.Component {
    render() {
        return (
            <LinearProgress style={{width: '100%'}} />
        );
    }
}

export default (name: string) => new CustomPage({ name, label: 'Loading...' }, <PageComponent />);