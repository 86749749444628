import * as React from 'react';
import { observer } from 'mobx-react';

import DataService from '@crochik/pi-react/services/DataService';
import * as api from '@crochik/schedulerapi.ts';

import { CustomPage } from '@crochik/pi-react/application/Page';

import { Agenda } from './Comps/Agenda';

import './ContentPage.css';

interface Props {
    organization: boolean;
}

interface State {
    users?: api.User[];
    appointments?: api.Appointment[];
}

@observer
class PageComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {

        };
    }

    async componentWillReceiveProps(props: Props) {
        this.setState({
            users: undefined,
            appointments: undefined
        });

        await this.loadAgenda(props);
    }

    async componentWillMount() {
        await this.loadAgenda(this.props);
    }

    async loadAgenda(props: Props) {
        var appointments: api.Appointment[];
        var users: api.User[];

        if (props.organization) {
            appointments = await DataService().select('OrgAppointments');
            users = await DataService().select('Users');
        } else {
            var user = await DataService().one('User') as api.User;
            users = [user];
            appointments = await DataService().select('Appointments');
        }

        this.setState({
            users,
            appointments
        });        
    }

    render() {
        if (!this.state.users || !this.state.appointments) return (
            <div className="ContentPage_scrollable">
                Loading
            </div>
        );

        return (
            <div className="ContentPage_scrollable">
                <Agenda users={this.state.users} appointments={this.state.appointments} showEmptyDays={this.props.organization} showUserName={this.props.organization}/>
            </div>
        );
    }
}

export default (name: string, organization: boolean, label?: string) => new CustomPage({ name, label }, <PageComponent organization={organization} />);