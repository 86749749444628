import * as React from 'react';
import App from '@crochik/pi-react/application/App';
import * as api from '@crochik/schedulerapi.ts';
import Dialog from '@crochik/pi-react/services/Dialog';
import { flowTransition } from '../../services/API';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import { CustomPage } from '@crochik/pi-react/application/Page';
import { DataView } from '@crochik/pi-react/ui/DataView';

import ActionButton from '@crochik/pi-react/ui/material/ActionButton';
import { DataGridComponent } from '@crochik/pi-react/ui/material/DataGridComponent';
import './styles.css';
import { EntityMetaData } from './MetaData';
import { Grid, Paper, Tabs, Tab } from '@material-ui/core';
import { Dashboard } from '../Dashboard';
import Integrations from '../Comps/Integrations';

interface State {
    view?: DataView;
    organization?: api.Organization;
    isSalesforce?: boolean;
    selectedTab: number;
}

class PageComponent extends React.Component<{}, State> {
    boundState: State;

    constructor(props: any) {
        super(props);

        this.state = {
            selectedTab: 0,
        };
    }

    async componentWillMount() {
        let appArgs = App().args;
        if (appArgs.length !== 2 || !('id' in appArgs[1]) && !('entityId' in appArgs[1])) {
            console.error('Missing required argument', appArgs);
            return;
        }

        var organization: api.Organization = ('id' in appArgs[1]) ? {
            id: appArgs[1]['id']
        } : appArgs[1] as api.Organization;

        if (!organization.id) return;
        var view = DataView.get('OrgUsers');
        view.reset();

        const isSalesforce = await flowTransition.isUsingSalesforce(organization.id);

        this.setState({
            organization,
            view,
            isSalesforce
        });
    }

    doMigrate = async () => {
        const { organization } = this.state;
        if (organization && organization.id) {
            var isSalesforce = await flowTransition.migrateToSalesforce(organization?.id);
            this.setState({
                isSalesforce
            });
            if (isSalesforce) {
                Dialog.inform({
                    title: 'Success',
                    message: 'Branch migrated to Salesforce',
                });
            }
        }
    }

    migrate = () => {
        Dialog.confirm({
            title: 'Migrate to Salesforce',
            message: 'Are you sure you want to migrate this branch to Salesforce?',
            onPositive: this.doMigrate
        });
    }

    doRevert = async () => {
        const { organization } = this.state;
        if (organization && organization.id) {
            var success = await flowTransition.revertToInspirenet(organization?.id);
            this.setState({
                isSalesforce: false
            });
            if (success) {
                Dialog.inform({
                    title: 'Success',
                    message: 'Branch reverted back to InspireNet',
                });
            }
        }
    }

    revert = () => {
        Dialog.confirm({
            title: 'Revert back to InspireNet',
            message: 'Are you sure you want to revert this branch back to InspireNet?',
            onPositive: this.doRevert
        });
    }

    onSelectTab = (event, value) => {
        this.setState({ selectedTab: value });
    }

    getMainTab() {
        const { organization, isSalesforce } = this.state;
        if (!organization) return null;

        return {
            name: 'Organization',
            content: (
                <Card className="Organization_main">
                    {organization.name &&
                        <Typography color="primary" variant="h6">{organization.name}</Typography>
                    }
                    <Typography color="default" variant="body2">
                        ID: <b>{organization.id}</b>
                    </Typography>
                    {organization.accountId &&
                        <Typography color="default" variant="body2">
                            Account ID: <b>{organization.accountId}</b>
                        </Typography>
                    }
                    {
                        isSalesforce ?
                            (
                                <Typography color="default" variant="subtitle1" style={{ marginTop: 12 }}>
                                    <span onClick={this.revert} className="Organization_salesforce">Migrated to Salesforce</span>
                                </Typography>
                            ) : (
                                <ActionButton variant="contained" color="secondary" title="Migrate to Salesforce" action={this.migrate} />
                            )
                    }
                </Card>
            )
        };
    }

    getUsersTab() {
        const { organization, view } = this.state;
        if (!view || !organization) return null;

        return {
            name: 'Users',
            content: (
                <Grid item={true} xs={12}>
                    <div className="Organization_users">
                        <DataGridComponent view={view} args={['', organization]} />
                    </div>
                </Grid>
            )
        };
    }

    getMetaDataTab() {
        const { organization } = this.state;
        if (!organization) return null;

        return {
            name: 'Territory',
            content: (
                <Grid item={true} xs={12} sm={6} md={3}>
                    <EntityMetaData entity={organization} title="Assigned Territory" metaDataKey="ZIPCODE" />
                </Grid>
            )
        };
    }

    getDashboardTab() {
        const { organization } = this.state;
        if (!organization) return null;

        return {
            name: 'Dashboard',
            content: (
                <Dashboard className="OrganizationDashboard" spacing={8} organization={organization} />
            )
        };
    }

    getIntegrations() {
        const { organization } = this.state;
        if (!organization || !organization.id) return null;

        return {
            name: 'Integrations',
            content: (
                <Integrations type="Org" id={organization.id} />
            )
        };
    }

    render() {
        const { organization } = this.state;
        if (!organization) {
            return (
                <div>Loading...</div>
            );
        }

        var tabs = [
            this.getMainTab(),
            this.getDashboardTab(),
            this.getUsersTab(),
            this.getMetaDataTab(),
            this.getIntegrations()
        ].filter(x => !!x) as { name: string, content: any }[];

        return (
            <div className="Organization TabPage">
                <div className="TabPage_tabs">
                    <Paper>
                        <Tabs
                            value={this.state.selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.onSelectTab}
                            scrollButtons="auto"
                            variant="scrollable"
                        >
                            {tabs.map(i => <Tab key={i.name} label={i.name} />)}
                        </Tabs>
                    </Paper>
                </div>
                <div className="TabPage_grid">
                    {tabs[this.state.selectedTab] && tabs[this.state.selectedTab].content}
                </div>
            </div>
        );
    }
}

export default (name: string) => new CustomPage({ name, label: 'Organization' }, <PageComponent />);