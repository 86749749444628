import * as React from 'react';

import { CustomPage } from '@crochik/pi-react/application/Page';
import { DataView } from '@crochik/pi-react/ui/DataView';
import { Form } from '@crochik/pi-react/ui/Form';
import App from '@crochik/pi-react/application/App';

import { Appointment } from '@crochik/schedulerapi.ts';

import { DataGridComponent } from '@crochik/pi-react/ui/material/DataGridComponent';
import { ReadOnlyForm } from '@crochik/pi-react/ui/material/ReadOnlyForm';
import { Grid } from '@material-ui/core';
import DataService from '@crochik/pi-react/services/DataService';

import './TabPage.css';
import './Appointment.css';

interface State {
    appointment?: Appointment;
    mainForm?: Form;
    integrationsView?: DataView;
}

class PageComponent extends React.Component<{}, State> {
    boundState: State;

    constructor(props: any) {
        super(props);

        this.state = {
            mainForm: undefined,
            integrationsView: undefined,
        };
    }

    async componentWillMount() {
        let args = App().args;
        let appt = args[1];
        console.log(`Load Appointment: ${appt.id}`);

        var row: Appointment = await DataService().one('Appointment', { args });
        if (!row || !row.leadId) return;

        this.createForm(row);

        this.setState({
            appointment: row,
        });
    }

    createForm(appointment: Appointment) {
        if (!appointment) {
            console.error('Invalid Appointment');
            console.log(appointment);
            return;
        }

        var form = Form.get('Appointment');
        form.assignValues(appointment);
        // form.title = appointment.subject || ''; // form.getValue(form.fieldMap['name']);

        let integrationsState = DataView.getState('AppointmentIntegrations');
        integrationsState.query = {
            id: appointment.id
        };
        let integrationsView = DataView.get('AppointmentIntegrations');
        integrationsView.reset();

        this.setState({
            ...this.state,
            mainForm: form,
            integrationsView,
        });
    }

    openLead = () => {
        if (!this.state.appointment) return;
        App().selectPage(`Lead=${this.state.appointment.leadId}`, this.context, { id: this.state.appointment.leadId });
    }

    render() {
        return (
            <div className="ContentPage_scrollable">
                <Grid container={true} spacing={16}>
                    <Grid item={true} lg={4} sm={6} xs={12}>
                        {this.state.mainForm &&
                            <ReadOnlyForm form={this.state.mainForm} hideNullFields={true} />
                        }
                    </Grid>
                    <Grid item={true} lg={4} sm={6} xs={12}>
                        {this.state.integrationsView &&
                            <DataGridComponent view={this.state.integrationsView} />
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <PageComponent />);