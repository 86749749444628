import { Default } from '@crochik/pi-react/context';
import Dialog from '@crochik/pi-react/services/Dialog';

import * as API from '../services/API';

export function saveSendGrid(context: string) {
    var record = Default.state.get(context) as API.ISendGridLeadIntegration;
    console.log(record);
    
    API.enableSendGridOnLeadType(record)
        .then( ok => {
            Dialog.inform({title: 'Success', message: 'SendGrid integration successfully configured.'});
        })
        .catch( ex => {
            Dialog.critical({title: 'Enabling Integration', message: 'Error trying to configure SendGrid integration for Lead Source.'});
        });
}

export function saveAutoSchedule(context: string) {
    var record = Default.state.get(context) as API.IAutoScheduleLeadTypeIntegration;
    console.log(record);
    
    API.enableAutoScheduleOnLeadType(record)
        .then( ok => {
            Dialog.inform({title: 'Success', message: 'AutoScheduler successfully configured.'});
        })
        .catch( ex => {
            Dialog.critical({title: 'Enabling Integration', message: 'Error trying to configure AutoSchedule for Lead Source.'});
        });
}

export function saveLumin(context: string) {
    var record = Default.state.get(context) as API.ILuminLeadTypeIntegration;
    console.log(record);
    
    API.enableLuminOnLeadType(record)
        .then( ok => {
            Dialog.inform({title: 'Success', message: 'Lumin successfully configured.'});
        })
        .catch( ex => {
            Dialog.critical({title: 'Enabling Integration', message: 'Error trying to configure Lumin for Lead Source.'});
        });
}

function disableIntegration(context: string, serviceName: string ) {
    var record = Default.state.get(context); 
    API.disableLeadTypeIntegration(record.id, serviceName)
    .then( ok => {
        Dialog.inform({title: 'Success', message: `${serviceName} integration disabled.`});
    })
    .catch( ex => {
        Dialog.critical({title: 'Disable Integration', message: `Error trying to disable ${serviceName} for Appointment Type.`});
    });
}

export function disableSendGrid(context: string) {
    disableIntegration(context, 'SendGrid');
}
export function disableAutoSchedule(context: string) {
    disableIntegration(context, 'AutoSchedule');
}
export function disableLumin(context: string) {
    disableIntegration(context, 'Lumin');
}