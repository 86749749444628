import * as React from 'react';

import { CustomPage } from '@crochik/pi-react/application/Page';
import { DataView } from '@crochik/pi-react/ui/DataView';
import App from '@crochik/pi-react/application/App';

import { LeadType } from '@crochik/schedulerapi.ts';

import { DataGridComponent } from '@crochik/pi-react/ui/material/DataGridComponent';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Paper } from '@material-ui/core';

import Integrations from '../Comps/Integrations';

import '../TabPage.css';
import Mapping from '../Comps/Mapping';
import { VerticalForm } from '@crochik/pi-react/ui/material/VerticalForm';
import DataService from '@crochik/pi-react/services/DataService';
import { Form } from '@crochik/pi-react/ui/Form';
import { SettingsDialog } from './SettingsDialog';

interface State {
    leadsView?: DataView;
    selectedTab: number;
    leadType?: LeadType;
    showSettings: boolean;
}

class PageComponent extends React.Component<{}, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            leadsView: undefined,
            leadType: undefined,
            selectedTab: 0,
            showSettings: false,
        };
    }

    async componentWillMount() {
        let args = App().args;
        console.log(args);

        let id = args[1]['id'];
        console.log(`Load Lead Type: ${id}`);
        var leadType = await DataService().one('LeadType', { args: [null, { id }] });
        if (!leadType) {
            console.error(`Couldn't load lead type ${id}`);
            return;
        }

        const form = Form.get('LeadType');
        form.assignValues(leadType);
        form.bindAction('Settings', this.onSettings);

        this.setState({
            leadsView: undefined,
            selectedTab: args.length > 2 ? args[2] : 0,
            leadType,
            showSettings: false
        });

        this.createLeadsView(leadType);
    }

    onSettings = () => {
        this.setState({
            showSettings: true
        });
    }

    createLeadsView(leadType: LeadType) {
        let leadsState = DataView.getState('LeadsByType');
        leadsState.query = leadType;

        let leadsView = DataView.get('LeadsByType');
        leadsView.reset();

        this.setState({
            ...this.state,
            leadsView
        });
    }

    onSelectTab = (event, value) => {
        this.setState({
            ...this.state,
            selectedTab: value
        });
    }

    onCloseSettings = () => {
        this.setState({
            showSettings: false
        });
    }

    render() {
        const { showSettings, leadType } = this.state;

        return (
            <>
                <div className="TabPage">
                    <div className="TabPage_tabs">
                        <Paper>
                            <Tabs
                                value={this.state.selectedTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={this.onSelectTab}
                            >
                                <Tab label="Settings" />
                                <Tab label="Leads" />
                                <Tab label="Mapping" />
                                {/* <Tab label="Integrations" /> */}
                            </Tabs>
                        </Paper>
                    </div>
                    {
                        this.state.selectedTab === 0 && this.state.leadType &&
                        <div className="TabPage_scrollable">
                            <Integrations id={this.state.leadType.id as string} type="LeadType" />
                            <br />
                            <VerticalForm form="LeadType" actionProps={{ color: 'primary', align: 'right' }} />
                        </div>
                    }
                    {
                        this.state.selectedTab === 1 && this.state.leadsView &&
                        <div className="TabPage_grid">
                            <DataGridComponent view={this.state.leadsView} />
                        </div>
                    }
                    {
                        this.state.selectedTab === 2 && this.state.leadType && this.state.leadType.id &&
                        <div className="TabPage_scrollable">
                            <Mapping leadTypeId={this.state.leadType.id} context="page.LeadType" />
                        </div>
                    }
                </div>
                {
                    showSettings && !!leadType && leadType.id && (
                        <SettingsDialog
                            onClose={this.onCloseSettings}
                            title={leadType.name || 'Settings'}
                            id={leadType.id}
                        />        
                    )
                }
            </>
        );
    }
}

export default (name: string, label?: string) => new CustomPage({ name, label }, <PageComponent />);