import * as React from 'react';
import * as api from '@crochik/schedulerapi.ts';
import * as csv from 'neat-csv';
import { DataGridComponent } from '@crochik/pi-react/ui/material/DataGridComponent';
import { DataView } from '@crochik/pi-react/ui/DataView';

import ActionButton from '@crochik/pi-react/ui/material/ActionButton';
import { Default, TYPE } from '@crochik/pi-react';
import { observer } from 'mobx-react';
import { FileDropTarget } from '../Comps/FileDropTarget';
import { IDataView } from '@crochik/pi-react/context/IDataView';

interface IProps {
    entity: api.Organization;
    title: string;
    className?: string;
    metaDataKey?: string;
}

interface IState {
    view?: DataView;
    added: api.EntityMetadata[];
}

function loadFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        var buffer = '';

        reader.onload = (onload: ProgressEvent<FileReader>) => {
            if (onload.target) {
                const str = onload.target['result'];
                buffer += str;
            }
        };

        reader.onloadend = (ev: ProgressEvent<FileReader>) => {
            resolve(buffer);
        };

        reader.onerror = (ev: ProgressEvent<FileReader>) => {
            console.error(ev);
            reject('error');
        };

        reader.readAsText(file);
    });
}

@observer
export class EntityMetaData extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            added: [],
        };
    }

    componentDidMount() {
        const { entity, title, metaDataKey } = this.props;

        const viewData: IDataView = {
            name: 'OrgMetaData',
            title: title || 'Meta Data',
            keyField: 'value',
            isSelectable: true,
            fields: [
                {
                    name: 'key',
                    label: 'Key',
                    type: metaDataKey ? TYPE.HIDDEN : TYPE.TEXT
                },
                {
                    name: 'value',
                    label: metaDataKey || 'Value',
                    type: TYPE.TEXT
                }
            ]
        };

        var view = DataView.create(viewData);
        view.reload(metaDataKey || '', entity);

        this.setState({
            view,
        });
    }

    mapValues = (args: { header: string; index: number; value: any }): any => {
        return args.value ? args.value.trim() : null;
    }

    parse = async (contents: string) => {
        const { added } = this.state;
        const { entity, metaDataKey } = this.props;

        var parsed = await csv(contents, {
            headers: false,
            skipComments: true,
            mapValues: this.mapValues,
        });

        if (parsed.length < 1) return;

        const cols = Object.keys(parsed[0]).length;
        const keyValue = metaDataKey || parsed[0][0];
        var valueIndex = cols > 1 ? 1 : 0;

        if (metaDataKey) {
            for (var col = 0; col < cols; col++) {
                if (parsed[0][col] === metaDataKey) {
                    valueIndex = col;
                    break;
                }
            }
        }

        for (var c = 0; c < parsed.length; c++) {
            const row = parsed[c];
            switch (cols) {
                case 1:
                    added.push({
                        entityId: entity.id,
                        key: keyValue,
                        value: row[0]
                    });
                    break;

                default:
                    added.push({
                        entityId: entity.id,
                        key: metaDataKey || row[0],
                        value: row[valueIndex]
                    });
                    break;
            }
        }

        const { view } = this.state;
        if (view) view.onloaded(added);
        this.setState({
            added
        });
    }

    loadFile = async (files: File[]) => {
        for (var file of files) {
            var contents = await loadFile(file);
            await this.parse(contents);
        }
    }

    save = async () => {
        const { entity } = this.props;
        const { view, added } = this.state;

        if (view) {
            Default.actions.execute('save', view.context, entity.id, added, this.reload);
        }
    }

    reload = () => {
        const { view } = this.state;
        const { entity } = this.props;

        if (view) {
            view.reset();
            view.reload('', entity);
        }

        this.setState({
            added: []
        });
    }

    remove = async () => {
        const { entity } = this.props;
        const { view, added } = this.state;
        if (!view) return;

        // remove selected from added 
        if (added && added.length > 0) {
            var selected = view.records
                .map(x => view.get(x) as api.EntityMetadata)
                .filter(x => view.isSelected(x));

            console.log('remove', selected);
            // ...
        }

        // if any left, remove from list
        Default.actions.execute('remove', view.context, entity.id, view, this.reload);
    }

    render() {
        const { className } = this.props;
        const { view, added } = this.state;

        if (!view) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <FileDropTarget className={className || 'MetaData'} onFilesDropped={this.loadFile}>
                <DataGridComponent view={view} skipLoad={true} />
                <div className="Actions">
                    {
                        added && added.length > 0 &&
                        <ActionButton title="Cancel" color="primary" variant="contained" action={this.reload} />
                    }
                    {
                        view.selectedCount > 0 &&
                        <ActionButton title="Remove" color="primary" variant="contained" action={this.remove} />
                    }
                    {
                        added && added.length > 0 &&
                        <ActionButton title="Save" color="primary" variant="contained" action={this.save} />
                    }
                </div>
            </FileDropTarget>
        );
    }
}