export enum ObjectType {
    Root,
    Step,
    Event,
    UserEvent,
}

export interface ClickEvent {
    objectType: ObjectType;
    id?: string;
}

export type OnClick = (event: ClickEvent) => any;