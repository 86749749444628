import * as React from 'react';

import { FieldMapperConfig, LeadTypeSettings } from '@crochik/schedulerapi.ts';
import * as API from '../../services/API';
import { Fields, sortFields } from './Fields';
import { Typography, Card, CardContent } from '@material-ui/core';
import ActionButton from '@crochik/pi-react/ui/material/ActionButton';
import { observable, action } from 'mobx';
import { Default } from '@crochik/pi-react/context';
import { observer } from 'mobx-react';
import DataService from '@crochik/pi-react/services/DataService';

interface Props {
    leadTypeId: string;
    context: string;
}

export interface State {
    leadTypeId: string;
    settings?: LeadTypeSettings;
    fields?: FieldMapperConfig[];
}

@observer
export default class Mapping extends React.Component<Props> {
    boundState: State;

    constructor(props: Props) {
        super(props);

        var state: State = {
            leadTypeId: this.props.leadTypeId,
            fields: undefined
        };

        this.boundState = observable(state);
        Default.state.set('Mapping', this.boundState, this.props.context);
    }

    componentWillMount() {
        this.loadMapping();
    }

    @action
    async loadMapping() {
        const { leadTypeId } = this.props;

        // current settings
        this.boundState.settings = await DataService().one('LeadTypeSettings', { args: [null, { id: leadTypeId }] }) as LeadTypeSettings;

        // refresh mapping
        var mapping = await API.getLeadTypeMapping(leadTypeId);
        if (!mapping || !mapping.fields) {
            console.error('missing mapping');
            return;
        }

        this.boundState.fields = mapping.fields.sort(sortFields);
    }

    render() {
        return (
            <Card>
                {
                    this.boundState.fields &&
                    <CardContent>
                        <Typography color="primary" variant="h6">Mapping</Typography>

                        <Fields editable={true} fields={this.boundState.fields} />

                        <div style={{ marginTop: 12 }}>
                            <ActionButton title="Save" action="save" context={this.props.context + '.Mapping'} color="primary" variant="contained" />
                        </div>
                    </CardContent>
                }
            </Card>
        );
    }
}