import CalendarPage from './CalendarPage';
import FullCalendar from './Lead/FullCalendar';
import Availability from './Availability';
// import OpenSlots from './OpenSlots';
import Welcome from './Welcome';
import InitialWizard from './InitialWizard';
import Profile from './Profile';
import LeadType from './LeadType';
import Lead from './Lead';
import Dashboard from './Dashboard';
import AccountDashboard from './Dashboard/Account';
import Flow from './Flow';

import AppointmentType from './AppointmentType';
import Appointment from './Appointment';
import Loading from './Loading';
import * as Integrations from './Integrations';
import Agenda from './Agenda';
import Settings from './Settings';
import Report from './Report';

import User from './User';
import Organization from './Organization';

import Test from './Test';

export default function init() {
    Loading('Loading');
    Welcome('Welcome', 'ᴨ Booking');
    InitialWizard('InitialWizard', 'Initial Setup');

    CalendarPage('Events', 'My Calendar');
    FullCalendar('MyCalendar', 'My Calendar');
    Availability('MyAvailability', 'My Availability');
    // OpenSlots('OpenSlots', 'Open Slots');
    Profile('Profile');
    Dashboard('Dashboard');
    AccountDashboard('AccountDashboard', 'Dashboard');
    Flow('Flow');

    LeadType('LeadType', 'Lead Source');
    Lead('Lead');

    AppointmentType('AppointmentType', 'Appointment Type');
    Appointment('Appointment');

    Integrations.Organization();
    Integrations.Account();

    Agenda('BizCalendar', true, 'Biz Agenda');
    Agenda('Agenda', false, 'Appointments');

    Settings('Settings');

    Report('Report');

    User('User');
    Organization('Organization');

    Test('Test');
}