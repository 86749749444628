import * as React from 'react';
import * as API from '../../services/API';

import './styles.css';
import { observer } from 'mobx-react';
import { OnClick, ObjectType, ClickEvent } from './Event';
import { ANY } from '../../services/API';
import * as api from '@crochik/schedulerapi.ts';
import { Trigger } from '@crochik/schedulerapi.ts';
import PlusIcon from '@material-ui/icons/AddCircleOutline';

interface ILeadStatusProps {
    flow: API.FlowSteps;
    leadStatus: api.LeadStatus;
    onClick: OnClick;
}

interface IState {
    isEditing: boolean;
}

type IName = { name?: string };
function sortByName(l: IName, r: IName) {
    if (!l.name) {
        return !r.name ? 0 : -1;
    }
    if (!r.name) {
        return +1;
    }
    return l.name.localeCompare(r.name);
}

@observer
export default class LeadStatus extends React.Component<ILeadStatusProps, IState> {
    constructor(props: ILeadStatusProps) {
        super(props);

        this.state = {
            isEditing: false,
        };
    }

    onClickEvent = (event: api.EventType) => () => {
        const { onClick } = this.props;

        const objectType = event.trigger && event.trigger.type === api.Trigger.TypeEnum.User ?
            ObjectType.UserEvent : ObjectType.Event;

        var clickEvent: ClickEvent = {
            objectType,
            id: event.id,
        };

        onClick(clickEvent);
    }

    onClickStep = (step: api.FlowStep) => () => {
        const { onClick } = this.props;

        var clickEvent: ClickEvent = {
            objectType: ObjectType.Step,
            id: step.id
        };

        onClick(clickEvent);
    }

    addEvent = () => {
        const { onClick } = this.props;

        var clickEvent: ClickEvent = {
            objectType: ObjectType.Event,
        };

        onClick(clickEvent);
    }

    renderOutput(step: api.FlowStep, visited: { [id: string]: boolean }) {
        if (!step.options || !step.options.output) return null;

        const newVisited: { [id: string]: boolean } = {};
        Object.assign(newVisited, visited);

        if (step.options.output.length === 1) {
            return this.renderEvent(step.options.output[0].eventId as string, 'Flow-event', newVisited);
        }

        return (
            <div key={step.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                {step.options.output.map(e => this.renderEvent(e.eventId as string, 'Flow-event', newVisited))}
            </div>
        );
    }

    renderStep(step: api.FlowStep, visited: { [id: string]: boolean }) {
        if (!step.actionId) return null;

        const { flow } = this.props;
        const action = flow.flowActionDict[step.actionId];

        const name = step.description || action.name;
        const title = action.description || action.name;

        return (
            <div style={{ display: 'flex' }}>
                {step.iconName ? (
                    <div onClick={this.onClickStep(step)} className="Flow-item Flow-action" title={title}>
                        <div><img width="20" height="20" src={`/icons/${step.iconName}.png`} /></div>
                        <div>
                            {name}
                        </div>
                    </div>
                ) : (
                        <div onClick={this.onClickStep(step)} className="Flow-item Flow-action" title={title}>
                            {name}
                        </div>
                    )
                }
                {
                    this.renderOutput(step, visited)
                }
            </div>
        );
    }

    renderEvent(eventId: string, className: string, visited: { [id: string]: boolean }) {
        const { leadStatus, flow } = this.props;
        const leadStatusId = leadStatus.id || ANY;

        var list = flow.flowStepsList.filter(s => s.eventIdTrigger === eventId &&
            (s.currentStatusId || ANY) === leadStatusId);

        const event = flow.eventTypeDict[eventId];
        const name = (event.trigger ? event.trigger.name : null) || event.name;
        // const title = event.description || event.name;

        if (eventId && visited[eventId]) {
            return (
                <div className="Flow-item Flow-circular-event" key={eventId} title={eventId}>
                    Circular Chain<br />
                    {event.name}
                </div>
            );
        }

        visited[eventId] = true;

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }} key={eventId}>
                <div
                    title={eventId}
                    onClick={this.onClickEvent(event)}
                    className={`Flow-item ${className}`}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    {name}
                </div>
                {list.length === 1
                    ?
                    list.map(s => this.renderStep(s, visited))
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }} key={eventId}>
                        {list.map(s => this.renderStep(s, visited))}
                    </div>
                }
            </div>
        );
    }

    renderTrigger(eventId: string, className: string, forceRender?: boolean) {
        const { leadStatus, flow } = this.props;
        const { isEditing } = this.state;
        const leadStatusId = leadStatus.id || ANY;

        var list = flow.flowStepsList.filter(s => s.eventIdTrigger === eventId &&
            (s.currentStatusId || ANY) === leadStatusId);

        return (list.length < 1 && !isEditing && !forceRender) ? null : this.renderEvent(eventId, className, {});
    }

    renderSystemTrigger(event: api.EventType) {
        const { leadStatus } = this.props;
        const leadStatusId = leadStatus.id || ANY;

        if (!event.id) return null;
        if (event.id === API.LeadCreatedEventId && leadStatusId !== API.InitialLeadStatusId) {
            return null;
        }

        return this.renderTrigger(event.id, 'Flow-system-event');
    }

    renderSystemTriggers() {
        const { flow } = this.props;

        var systemEvents = Object.values(flow.eventTypeDict).filter(x => x.trigger && x.trigger.type === Trigger.TypeEnum.System);
        systemEvents = systemEvents.sort(sortByName);

        return systemEvents.map(event => this.renderSystemTrigger(event));
    }

    renderUserTriggers() {
        const { flow } = this.props;

        var systemEvents = Object.values(flow.eventTypeDict).filter(x => x.trigger && x.trigger.type === Trigger.TypeEnum.User);
        systemEvents = systemEvents.sort(sortByName);

        return systemEvents.map(event => this.renderTrigger(event.id as string, 'Flow-user-trigger'));
    }

    renderRoots() {
        const { leadStatus, flow } = this.props;
        const leadStatusId = leadStatus.id || ANY;

        var triggers: { [id: string]: api.EventType } = {};
        var triggeredEvents: { [id: string]: boolean } = {};
        flow.flowStepsList.forEach(step => {
            if (!step.eventIdTrigger || (step.currentStatusId || ANY) !== leadStatusId) return;

            if (step.options && step.options.output) {
                step.options.output.forEach(e => triggeredEvents[e.eventId as string] = true);
            }
        });

        flow.flowStepsList.forEach(step => {
            if (!step.actionId || !step.eventIdTrigger) return;
            var event = flow.eventTypeDict[step.eventIdTrigger];
            if (!event.id || (event.trigger && event.trigger.type !== Trigger.TypeEnum.SideEffect)) return;

            if (((step.currentStatusId || ANY) === leadStatusId || leadStatusId === ANY) && !triggeredEvents[event.id]) {
                if (!triggers[step.eventIdTrigger]) {
                    triggers[step.eventIdTrigger] = event;
                }
            }
        });

        const roots = Object.values(triggers).sort(sortByName);
        return roots.map(event => this.renderTrigger(event.id as string, 'Flow-event'));
    }

    renderTransitions() {
        const { leadStatus, flow } = this.props;
        const leadStatusId = leadStatus.id || ANY;

        var usedEvents: { [id: string]: boolean } = {};
        var triggers: { [id: string]: api.EventType } = {};
        flow.flowStepsList.forEach(step => {
            if (!step.actionId || !step.eventIdTrigger) return;
            var event = flow.eventTypeDict[step.eventIdTrigger];
            if (!event.id) return;

            if (step.currentStatusId === leadStatusId) {
                usedEvents[step.eventIdTrigger] = true;
            }

            // change status action
            if (step.actionId === API.ChangeStatusActionId &&
                step.options && step.options['leadStatusId'] === leadStatusId &&
                step.options.output
            ) {
                if (step.options.output.length !== 1) {
                    throw 'Unexpected number of outputs for change status action';
                }

                const eventId = step.options.output[0].eventId as string;
                if (!triggers[eventId]) triggers[eventId] = flow.eventTypeDict[eventId];
            }
        });

        var roots = Object.values(triggers);
        roots = roots.filter(r => !usedEvents[r.id as string]);
        roots.sort(sortByName);

        return roots.map(event => this.renderTrigger(event.id as string, 'Flow-status-transition', true));
    }

    toggleEditing = () => {
        const { isEditing } = this.state;
        this.setState({
            isEditing: !isEditing
        });
    }

    render() {
        const { leadStatus } = this.props;
        return (
            <div key={leadStatus.id} style={{ overflow: 'auto' }} className="LeadStatus">
                <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
                    <div onClick={this.toggleEditing}>{leadStatus.name}</div>
                    <div onClick={this.addEvent} className="AddEvent"><PlusIcon /></div>
                </div>
                {this.renderSystemTriggers()}
                {this.renderUserTriggers()}
                {this.renderTransitions()}
                {this.renderRoots()}
            </div>
        );
    }
}