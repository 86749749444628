import * as React from 'react';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import { CustomPage } from '@crochik/pi-react/application/Page';
import { DataView } from '@crochik/pi-react/ui/DataView';

import ActionButton from '@crochik/pi-react/ui/material/ActionButton';
import { DataGridComponent } from '@crochik/pi-react/ui/material/DataGridComponent';

import './Profile.css';
import { Session } from '../Session';

interface State {

}

class PageComponent extends React.Component<{}, State> {
    boundState: State;

    constructor(props: any) {
        super(props);

        this.state = {
        };
    }

    render() {
        var session = Session.get();
        var user = session.user;
        if (!user) {
            return <div>Error: No User</div>;
        }

        // how to convert?
        // var authTime = new Date(user.profile['auth_time']);
        // var expiresAt = new Date(user.expires_at);
        // Authenticated at: {authTime.toTimeString()}<br/>
        // Session Expiration: {expiresAt.toTimeString()}

        var view = DataView.get('UserIdentities');

        return (
            <div className="Profile pi-page">
                <Card className="Profile_main">
                    {/* <Typography color="primary" variant="title">{login.userName}</Typography>
                    <Typography color="default" variant="subheading">{login.userRole}</Typography>
                    <Typography color="default" variant="body1">
                        IDP: <b>{login.idp}</b><br />
                        ID: <b>{login.userId}</b><br />
                    </Typography>
                    <Typography color="primary" variant="h6">{user.name}</Typography>
                    <Typography color="default" variant="subtitle1">{user.role}</Typography>
                    {/* <Typography color="default" variant="body2">
                        ID: <b>{user.id}</b>
                    </Typography> */}
                    <Typography color="primary" variant="h6">{user.name}</Typography>
                    <Typography color="default" variant="subtitle1">{user.role}</Typography>
                    {user.organizationId &&
                        <Typography color="default" variant="body2">
                            Organization: <b>{user.organizationId}</b>
                        </Typography>
                    }
                    <br />
                    <div className="BottomActionsBar">
                        <ActionButton variant="contained" color="secondary" title="Delete Account" action="deleteUser" context="app" />
                        <ActionButton variant="contained" color="primary" title="Logout" action="logout" context="app" />
                        {session.isSyncEnabled && <ActionButton variant="contained" color="primary" title="Disable Sync" action="disableO365Sync" context="app" />}
                    </div>
                </Card>

                <div className="Profile_userIdentities">
                    <DataGridComponent view={view} />
                </div>
            </div>
        );
    }
}

export default (name: string) => new CustomPage({ name, label: 'Profile' }, <PageComponent />);