import * as React from 'react';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import { CustomPage } from '@crochik/pi-react/application/Page';
// import { DataView } from '@crochik/pi-react/ui/DataView';
import ActionButton from '@crochik/pi-react/ui/material/ActionButton';
// import { DataGridComponent } from '@crochik/pi-react/ui/material/DataGridComponent';
import App from '@crochik/pi-react/application/App';
import * as api from '@crochik/schedulerapi.ts';

import './styles.css';

interface State {
    user: api.User;
}

class PageComponent extends React.Component<{}, State> {
    boundState: State;

    constructor(props: any) {
        super(props);

        let args = App().args;
        this.state = {
            user: args[1],
        };
    }

    componentWillMount() {
        console.log('user');
    }

    render() {
        const { user } = this.state;

        // var view = DataView.get('UserIdentities');

        return (
            <div className="User pi-page">
                <Card className="User_main">
                    <Typography color="primary" variant="h6">{user.name}</Typography>
                    <Typography color="default" variant="subtitle1">{user.role}</Typography>
                    {/* <Typography color="default" variant="body2">
                        ID: <b>{user.id}</b>
                    </Typography>
                    */}
                    <br />
                    <div className="BottomActionsBar">
                        <ActionButton variant="contained" color="secondary" title="Delete" action="delete" context="User" />
                        <ActionButton variant="contained" color="primary" title="Impersonate" action="impersonate" context="User" />
                    </div>
                </Card>

                {/* <div className="Profile_userIdentities">
                    <DataGridComponent view={view} />
                </div> */}
            </div>
        );
    }
}

export default (name: string) => new CustomPage({ name, label: 'User' }, <PageComponent />);