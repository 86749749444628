import * as React from 'react';
import * as moment from 'moment';
import DataService from '@crochik/pi-react/services/DataService';
import { Panel } from '@crochik/pi-react/ui/material/Panel';
import * as api from '@crochik/schedulerapi.ts';
import App from '@crochik/pi-react/application/App';
import { Default } from '@crochik/pi-react';

import { MoreMenu, MenuOption } from '../Comps/MoreMenu';
import { cancel } from '../../actions/Appointment';

import { Typography } from '@material-ui/core';
import ActionButton from '@crochik/pi-react/ui/material/ActionButton';
import { observer } from 'mobx-react';
import { observable, reaction, IReactionDisposer, action } from 'mobx';
import { CalendarDialog } from './CalendarDialog';

interface IProps {
    lead: api.Lead;
}

interface IState {
    appointments?: api.Appointment[];
    showCalendarDialog: boolean;
}

@observer
export class Appointments extends React.Component<IProps, IState> {
    listener?: IReactionDisposer;

    constructor(props: IProps) {
        super(props);

        this.state = {
            showCalendarDialog: false,
        };
    }

    componentWillMount() {
        this.load();
    }

    componentWillUnmount() {
        if (this.listener) {
            this.listener();
            this.listener = undefined;
        }
    }

    async load() {
        const { lead } = this.props;
        var appointments: api.Appointment[] = await DataService().select('LeadAppointments', {
            query: {
                id: lead.id,
            }
        });

        appointments = appointments.sort((l, r) => {
            const lc = this.isDisabled(l);
            const rc = this.isDisabled(r);
            if (!rc && lc) return 1;
            if (rc && !lc) return -1;
            const lStart = moment(l.start);
            const rStart = moment(r.start);
            return lStart.isBefore(rStart) ? 1 :
                (lStart.isAfter(rStart) ? -1 : 0);
        });

        this.setState({
            appointments
        });
    }

    reload = () => {
        this.setState({
            appointments: undefined
        });

        this.load();
    }

    onAppointment = (record: api.Appointment) => () => {
        App().selectPage(`Appointment=${record.id}`, record.subject, record);
    }

    onAddAppointment = () => {
        this.setState({
            showCalendarDialog: true
        });
    }

    cancelAppointment(appointment: api.Appointment) {
        const cancelApptContext = observable({
            appointment
        });
        const context = 'page.Lead.Appointments';
        Default.state.setValue(context, 'cancel', cancelApptContext);

        if (this.listener) this.listener();

        this.listener = reaction(() => cancelApptContext.appointment.state, () => this.reload());

        cancel(`${context}.cancel.appointment`);
    }

    onMenuItem = (a: api.Appointment) => (option: MenuOption<string>) => {
        switch (option.value || option.name) {
            case 'Open':
                this.onAppointment(a)();
                break;

            case 'Launch':
                window.open(a.webLink, '_blank');
                break;

            case 'Cancel':
                this.cancelAppointment(a);
                break;

            default:
                return;
        }
    }

    isDisabled(a: api.Appointment) {
        const start = moment(a.start);
        return a.state === api.Appointment.StateEnum.Cancelled || a.state === api.Appointment.StateEnum.Expired || start.isBefore(moment());
    }

    renderAppt(a: api.Appointment) {
        const start = moment(a.start);
        const end = moment(a.end);
        const isDisabled = this.isDisabled(a);
        const style = isDisabled ? { opacity: .5 } : {};

        var options: MenuOption<string>[] = [];
        if (!isDisabled) {
            options = [];
            options.push({ name: 'Open' });
            if (a.webLink) {
                options.push({ name: 'Open External Calendar', value: 'Launch' });
            }
            options.push({ name: 'Cancel Appointment', value: 'Cancel' });
        }

        return (
            <div style={style} className="LeadAppointments" key={a.id}>
                <div className="LeadAppointments_horz LeadAppointments_clickable" onClick={this.onAppointment(a)}>
                    <Typography color="primary" variant="subtitle1">{a['userName']}</Typography>
                </div>
                <div className="LeadAppointments_horz" >
                    <div className="LeadAppointments_clickable" onClick={this.onAppointment(a)}>
                        <Typography color="textPrimary" variant="body2">
                            {start.format('dddd, MM/D/YY')}<br />
                            {`${start.format('h:mm a')} - ${end.format('h:mm a')}`}
                        </Typography>
                    </div>
                    {options.length > 0 &&
                        <MoreMenu options={options} onClick={this.onMenuItem(a)} />
                    }
                </div>
                <div className="LeadAppointments_clickable" onClick={this.onAppointment(a)} >
                    <Typography color="textPrimary" variant="subtitle1">{a.subject}</Typography>
                </div>
                {a.notes &&
                    <div className="LeadAppointments_notes">
                        <Typography color="textPrimary" variant="body1">{a.notes}</Typography>
                    </div>
                }
            </div>
        );
    }

    @action
    onClose = () => {
        this.setState({
            showCalendarDialog: false,
            appointments: undefined
        });

        this.load();
    }

    renderDialog() {
        const { lead } = this.props;
        const { showCalendarDialog } = this.state;
        if (showCalendarDialog && lead) {
            return <CalendarDialog onClose={this.onClose} lead={lead} />;
        }

        return undefined;
    }

    render() {
        const { appointments } = this.state;

        return (
            <>
                <Panel title="Appointments">
                    <div className="LeadAppointments_appointments">
                        {appointments && appointments.map(a => this.renderAppt(a))}
                    </div>
                    <div className="LeadAppointments_footer">
                        <ActionButton variant="contained" color="primary" action={this.onAddAppointment} title="Schedule" />
                    </div>
                </Panel>
                {this.renderDialog()}
            </>
        );
    }
}